import moment from "moment"
import { useQuery } from "@apollo/react-hooks"
import postStayAnswerStatsGetAvailableWeeksGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetAvailableWeeks.gql"
import postStayAnswerStatsGetAvailableYearsGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetAvailableYears.gql"
import React from "react"

type StatsFilterWeeklyProps = {
  hotelId: string,
  dimensionDate: Date,
  onChange: (dimensionValue: Date) => void,
}

export const StatsFilterWeekly = ({ hotelId, dimensionDate, onChange }: StatsFilterWeeklyProps) => {

  const year = dimensionDate?.getFullYear()
  const week = moment(dimensionDate)?.week()
  const weekIndexed = week - 1

  console.log('week', week)
  console.log('weekIndexed', weekIndexed)

  const yearsQuery = useQuery<{ postStayAnswerStatsGetAvailableYears: String[] }>(postStayAnswerStatsGetAvailableYearsGql, {
    variables: {
      hotelId,
    }
  })

  const weeksQuery = useQuery<{ postStayAnswerStatsGetAvailableWeeks: String[] }>(postStayAnswerStatsGetAvailableWeeksGql, {
    variables: {
      hotelId,
      year,
    },
  })



  const weekList = weeksQuery?.data?.postStayAnswerStatsGetAvailableWeeks
  const yearList = yearsQuery?.data?.postStayAnswerStatsGetAvailableYears


  const canGoNext = weekList?.includes(`${Number(weekIndexed) + 1}`)
  const canGoPrevious = weekList?.includes(`${Number(weekIndexed) - 1}`)


  return <div className='input-group'>
    {canGoPrevious && <span className="input-group-text">
      {"<-"}
    </span>}
    <select className="form-control" placeholder="Semaine" value={weekIndexed || ""} onChange={(v) => {
      const date = moment().day("Monday").week(Number(v.target.value)).toDate()
      onChange(date)
    }}>
      <option value="" disabled>Semaine</option>
      {weekList?.map(weekNumber => {
        return <option key={`week_${weekNumber}`} value={`${weekNumber}`}>Semaine {Number(weekNumber) + 1} </option>
      })}
    </select>
    <select className="form-control" placeholder="Année" value={year} onChange={(v) => {
      const date = moment().day("Monday").week(weekIndexed).year(Number(v.target.value)).toDate()
      onChange(date)
    }}>
      <option value="" disabled>Année</option>
      {yearList?.map((year, index) => {
        return <option key={`year_${year}_${index}`} value={`${year}`}>{year}</option>
      })}
    </select>
    {canGoNext && <span className="input-group-text" style={{ height: "100%" }}>
      {"->"}
    </span>}
  </div>
}