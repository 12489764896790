// import hotelUpdate from "graphql/PMS/Hotel/mutation/hotelUpdate"
import hotelAdd from "graphql/PMS/Hotel/mutation/hotelAdd"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import React, { useContext, useState } from "react"

// import priceRateCancelConditionListGql from "graphql/PMS/PriceRate/CancelCondition/query/priceRateCancelConditionList.gql"
import { Hotel, HotelInput, PostStayGroup, UserRoleEnum } from "model"
import { HotelForm } from "../Form/HotelForm"
import { userContext } from "context/User"
import { MutationWrapper } from "components/MutationWrapper"
import { hotelContext } from "context/hotelContext"

type HotelAddProps = {
  onDone: () => void,
}

export const HotelAdd = (props: HotelAddProps) => {

  const [hotel, updateHotel] = useState<HotelInput>(null)
  const [errors, updateErrors] = useState(null)
  const [formUpdated, updateFormUpdated] = useState<boolean>(false)

  const user = useContext(userContext)

  const hotelGetInput = (hotel: Hotel): HotelInput => {

    return {
      configGeneral: {
        name: hotel?.name,
        email: hotel?.email,
        emailSender: hotel?.emailSender,
        address: hotel?.address,
        phoneNumber: hotel?.phoneNumber,
        stayTax: hotel.stayTax,
        checkInHour: hotel.checkInHour,
        checkOutHour: hotel.checkOutHour,
        notificationDest: hotel?.notificationDest,
        // demoMode: hotel?.demoMode,
        breakfastStartHour: hotel?.breakfastStartHour,
        breakfastEndHour: hotel?.breakfastEndHour,
        cgvUrlEnFileId: hotel?.cgvUrlEnFileId,
        cgvUrlFrFileId: hotel?.cgvUrlFrFileId,
        initials: hotel?.initials,
        logoUrl: hotel.logoUrl,
        themeColor: hotel.themeColor,
        backgroundUrl: hotel.backgroundUrl,
        notificationListToMail: hotel.notificationListToMail,
        displayRoomPictureDisclosure: hotel?.displayRoomPictureDisclosure,
        displayRoomCategoryInDetails: hotel?.displayRoomCategoryInDetails,
      },
      propositionConfig: {
        templateId: hotel?.PropositionConfig?.templateId,
        demoMode: hotel?.PropositionConfig?.demoMode,
        dest: hotel?.PropositionConfig?.dest
      },
      confirmationConfig: {
        dest: hotel?.ConfirmationConfig?.dest,
        channel: hotel?.ConfirmationConfig?.channel,
        type: hotel?.ConfirmationConfig?.type,
        dataTypeActive: hotel?.ConfirmationConfig?.dataTypeActive,
        demoMode: hotel?.ConfirmationConfig?.demoMode,
        templateId: hotel?.ConfirmationConfig?.templateId,
      },
      configOptions: {
        Option: hotel?.Option?.map((el: any) => {
          const { __typename, File, ...rest } = el
          return {
            ...rest,
          }
        })
      },
      configPriceRate: {
        PriceRateType: hotel.PriceRateType?.map((el) => {
          const { __typename, ...rest } = el
          return {
            // hotelId: props?.id,
            ...rest
          }
        })
      },
      configCancelConditions: {
        PriceRateCancelCondition: hotel.PriceRateCancelCondition?.map((el: any) => {
          const { __typename, hotelId, ...cancelCondition } = el
          return {
            ...cancelCondition,
            conditionList: cancelCondition?.conditionList.map((cd: any) => {
              const { __typename, ...rest } = cd;
              return rest
            })
          }
        }),
      },
      configRoomType: {
        RoomType: hotel.RoomType?.map((el: any) => {
          const { __typename, ...rest } = el
          return rest
        }),
      },
      configBilling: {
        type: hotel?.BillingConfig?.type,
        templateId: hotel?.BillingConfig?.templateId,
        stripeConfig: hotel?.BillingConfig?.StripeConfig
      },
      configStay: {
        postStayConfig: {
          templateId: hotel.PostStayConfig.templateId,
          postStayActive: hotel.PostStayConfig.postStayActive,
          demoMode: hotel.PostStayConfig.demoMode,
          nbDaysAfterDeparture: hotel.PostStayConfig.nbDaysAfterDeparture,
          PostStayGroup: hotel.PostStayConfig.PostStayGroup?.map((postStayGroup: PostStayGroup) => {
            const { __typename, ...psInput } = postStayGroup
            return psInput

          })
        },
      },
      configService: {
        ServiceList: hotel.ServiceList?.map((el: any) => {
          const { __typename, ...rest } = el
          return {
            ...rest
          }
        })
      },
      configSeasonality: hotel.Seasonality?.map(season => {
        const { __typename, ...rest } = season
        return {
          ...rest,
        }
      }),
      phoneConfig: {
        phoneConfigId: hotel.PhoneConfig?.phoneConfigId,
        clic2CallSequenceId: hotel.PhoneConfig?.clic2CallSequenceId,
        token: hotel.PhoneConfig?.token,
      },
      networkConfig: {
        hostingConfigId: hotel.NetworkConfig?.hostingConfigId,
        token: hotel.NetworkConfig?.token,
      },
      googleConfig: {
        placeId: hotel.GoogleConfig?.placeId,
      },
      tripAdvisorConfig: {
        reviewUrl: hotel.TripAdvisorConfig?.reviewUrl
      },
      pmsConfig: hotel.PmsConfig,
      tokenList: hotel?.HotelToken?.map((ht) => ({ id: ht?.id, label: ht?.label, expiration: ht?.expiration, status: ht?.status }))
    }

  }


  const onChange = (hotel: HotelInput) => {
    updateFormUpdated(true)
    updateHotel(hotel)
  }
  const resetForm = () => {
    updateErrors(null)
    updateFormUpdated(false)
    updateHotel({})
  }

  return <div className="hotel-edit-container">
    <MutationWrapper mutation={hotelAdd} onDone={(response, error) => {
      if (response) {
        updateHotel(hotelGetInput(response.data?.hotel))
        updateFormUpdated(false)
        updateErrors(null)
      }
      if (error) {
        updateErrors(formatErrorResponseForJoi(error))
        console.log("ERROR", errors)
      }
    }}>
      {(mutate: any, loading: boolean) => {

        const isAdmin = user?.roles?.includes(UserRoleEnum.ROLE_ADMIN)
        const isConfig = isAdmin || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG)
        const isConfigGeneral = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_GENERAL)
        const isConfigSeasonality = isConfig
        const isPhoneConfig = isConfigGeneral
        const isPmsConfig = isConfigGeneral
        const isSocialConfig = isConfigGeneral
        const isNetworkConfig = isConfigGeneral
        const isConfigConfirmation = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_CONFIRMATION)
        const isConfigProposition = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_PROPOSITION)
        const isConfigServices = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_SERVICE)
        const isConfigOptions = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_OPTIONS)
        const isConfigPriceRate = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_RATE_CODE)
        const isConfigCancelCondition = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_CANCEL_CONDITIONS)
        const isConfigRoomType = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_ROOM_TYPE)
        const isConfigBilling = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_BILLING)
        const isConfigPostStay = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_POST_STAY)
        // const { name, displayRoomPictureDisclosure, displayRoomCategoryInDetails, themeColor, email, address, cgvUrlEnFileId, cgvUrlFrFileId, stayTax, phoneNumber, checkInHour, checkOutHour, breakfastStartHour, breakfastEndHour, demoMode, confirmationDest, confirmationSender, confirmationChannel, confirmationType, confirmationTemplateFr, confirmationTemplateEn, propositionTemplateFr, propositionTemplateEn, initials, RoomType, PriceRateType, Option, PriceRateCancelCondition, BillingConfig, PostStayConfig, ServiceList, logoUrl, backgroundUrl, notificationListToMail, confirmationDataTypeActive, PhoneConfig, NetworkConfig, PmsConfig, TripAdvisorConfig, GoogleConfig, ConfirmationConfig } = hotel || {}

        // const { __typename, id, ...billingConfig } = BillingConfig || {}

        const saveForm = () => {

          // const { __typename, id, hotelId, PostStayGroup, ...postStayConfig } = PostStayConfig || {}

          mutate({
            variables: {
              input: {
                ...(isConfigGeneral ? {
                  configGeneral: hotel.configGeneral
                } : {}),
                ...(isConfigConfirmation ? {
                  confirmationConfig: hotel.confirmationConfig,
                } : {}),
                ...(isConfigProposition ? {
                  propositionConfig: hotel.propositionConfig,
                } : {}),
                ...(isConfigOptions ? {
                  configOptions: hotel?.configOptions,
                } : {}),
                ...(isConfigPriceRate ? {
                  configPriceRate: hotel?.configPriceRate
                } : {}),
                ...(isConfigCancelCondition ? {
                  configCancelConditions: hotel.configCancelConditions
                } : {}),
                ...(isConfigRoomType ? {
                  configRoomType: hotel.configRoomType
                } : {}),
                ...(isConfigPostStay ? {
                  configStay: hotel.configStay,
                } : {}),
                ...(isConfigServices ? {
                  configService: hotel.configService,
                } : {}),
                ...(isConfigBilling ? {
                  configBilling: hotel.configBilling,
                } : {}),
                ...(isConfigSeasonality ? {
                  configSeasonality: hotel.configSeasonality,
                } : {}),
                ...(isPhoneConfig ? {
                  phoneConfig: hotel.phoneConfig,
                } : {}),
                ...(isNetworkConfig ? {
                  networkConfig: hotel.networkConfig,
                } : {}),
                ...(isSocialConfig ? {
                  tripAdvisorConfig: hotel.tripAdvisorConfig,
                  googleConfig: hotel.googleConfig,
                } : {}),
                ...(isPmsConfig ? {
                  pmsConfig: hotel.pmsConfig,
                } : {}),
                tokenList: hotel?.tokenList,
              },
            },
          })

        }

        return <div>

          {/* <hotelContext.Provider value={data?.hotel}> */}

          <HotelForm hotel={hotel} errors={errors} onChange={(hotel: any) => {
            onChange(hotel)
          }}
          />

          {/* </hotelContext.Provider> */}
          {formUpdated && <div className="card" style={{ position: "sticky", bottom: 10, margin: 10 }}>
            <div className="card-body">
              <div className="actions">
                <button className="btn btn-dark" disabled={loading} type="button" onClick={() => saveForm()}>
                  {loading && <span className="icon-loading ml-1"></span>}
                  Enregister
                </button>
                <button className="btn btn-danger" type="button" onClick={() => resetForm()}>Annuler</button>
              </div>
            </div>
          </div>}
        </div>

      }}

    </MutationWrapper>

  </div>

}