import gql from "graphql-tag";
import PostStayGroupFragment from "../../Group/fragment/PostStayGroupFragment";
import PostStayGroupStatsFragment from "../../Group/fragment/PostStayGroupStatsFragment";

export default gql`

  ${PostStayGroupStatsFragment}

  fragment PostStayConfigStatsFragment on PostStayConfig {
    id
    hotelId
    postStayActive
    demoMode
    templateId
    nbDaysAfterDeparture
    PostStayGroup {
      ...PostStayGroupStatsFragment
    }
  }

`