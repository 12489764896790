// import {useMutation} from "@apollo/react-hooks"
import { PriceFormat } from "components/common/Format/PriceFormat"
// import paymentConfirmGql from "graphql/BILLING/Payment/mutation/paymentConfirm.gql"
import React from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

type PaymentConfirmPaytweakProps = {
  payment: any,
  newWindow?: boolean,
}

export const PaymentConfirmPaytweak = (props: PaymentConfirmPaytweakProps) => {

  const payment = props?.payment
  const isPreAuth = payment.captureType === "CAPTURE_TYPE_PRE_AUTH"

  const { t } = useTranslation('translation', { keyPrefix: 'billing' });


  const isDisabled = (payment?.status === "STATUS_DONE" || payment?.isError || payment?.status === "STATUS_DELETED") || false
  const isSuccess = payment?.status === "STATUS_DONE"
  const isError = !!payment?.isError
  const isDeleted = payment?.status === "STATUS_DELETED"

  return <div className="">
    <div className="">
      {isSuccess && <div className="alert alert-success">
        {isPreAuth && <span>{t("preauth_ok")}</span>}
        {!isPreAuth && <span>{t("payment_ok")}</span>}
      </div>}
      {(isError && !isSuccess) && <div className="alert alert-danger">{t("error")}</div>}
      {isDeleted && <Modal display={true}><div className="alert alert-danger">{t("link_expired")}</div></Modal>}
      {/* <h1>Paiement</h1> */}
      {isPreAuth && !isSuccess && <div style={{ paddingTop: 5 }} >
        <div className="alert alert-warning">
          {t("preauth_warning")}.
        </div>
      </div>}
      <div style={{ paddingTop: 15 }}>
        <span className="text-blue-h8">{t("amount")}: <span className="" style={{ fontWeight: "bold" }}><PriceFormat value={payment?.amount} /></span></span>
      </div>
      {(!isDisabled) && <div className="d-flex justify-content-center">
        <a href={payment?.PaymentOptions?.url} rel="noreferrer" target={props?.newWindow ? "_blank" : "_self"}>
          <button className="btn btn-info btn-lg">{t("clic_to_confirm")}</button>
        </a>
      </div>}
    </div>
  </div>

}