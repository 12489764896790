import { useQuery } from "@apollo/react-hooks"
import fileGetGql from "graphql/APP/File/query/fileGet.gql"
import { File } from "model"
import React from "react"
type FileDisplayProps = {
  id: string
}

export const FileDisplay = (props: FileDisplayProps) => {

  const { data } = useQuery<{ file: File }>(fileGetGql, {
    variables: {
      id: props?.id,
    },
    skip: !props?.id,
  })

  const file = data?.file

  return <div className="card">
    <div className="card-header">{file?.name}</div>
    <div className="card-body">
      <table className="table table-bordered table-stripped">
        <tbody>
          <tr>
            <th>Url</th>
            <td>{file?.url}</td>
          </tr>
        </tbody>
      </table>
    </div>


  </div>

}