import gql from "graphql-tag";
import RunnerFragmentGql from "../fragment/RunnerFragment.gql";


export default gql`

  ${RunnerFragmentGql}

  query runnerGet($id: String!) {
    runner: runnerGet(id: $id) {
      ...RunnerFragment
    }
  }

`