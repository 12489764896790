import {DateTimeSelectFullInput} from "components/common/Form/DateTimeSelectFullInput"
import {DateTimeSelectInput} from "components/common/Form/DateTimeSelectInput"
import {DurationInput} from "components/common/Form/DurationInput"
import {Service, ServiceTypeEnum, EventStatusEnum, Event} from "model"
import React from "react"
import serviceListByHotelId from "graphql/PMS/Service/query/serviceListByHotelId"
import {useQuery} from "@apollo/react-hooks"


type EventFormProps = {
  event: Partial<Event>,
  serviceId?: string,
  hotelId: string,
  errors: any,
  onChange: (data: any) => void,
}

export const EventForm = (props: EventFormProps) => {

  const serviceListResponse = useQuery(serviceListByHotelId, {
    variables: {
      hotelId: props?.hotelId
    },
    skip: !props?.hotelId,
  })


  const onChange = (d: any) => {
    props?.onChange(d)
  }

  const event = props?.event;
  const errors = props?.errors;
  const serviceList: Service[] = serviceListResponse?.data?.list
  const hasServiceProps = !!props?.serviceId

  const serviceSelected = hasServiceProps ? serviceList?.find(s => s.id === props?.serviceId) : serviceList?.find(s => s.id === event?.serviceId)

  const renderForm = (event: Partial<Event>) => {


    switch (serviceSelected?.type) {

      case ServiceTypeEnum.TYPE_SPA: {
        return <div>
          <DateTimeSelectFullInput isError={!!errors?.utcDate} label={"Date"} value={event?.utcDate ? new Date(event?.utcDate) : null} onChange={(v) => {
            onChange({
              ...event,
              utcDate: v,
              source: {
                ...(event.source || {}),
                utcDate: v,
              }
            })
          }}
          />
          <DurationInput errors={!!errors} label={"Durée"} valueInMinutes={event?.source?.duration || ""} onChange={(_, v) => {
            onChange({
              ...event,
              source: {
                ...(event.source || {}),
                duration: v,
              }
            })
          }}
          />
        </div>
      }

      case ServiceTypeEnum.TYPE_RESTAURANT: {

        return <div>
          <DateTimeSelectInput isError={!!errors?.utcDate} label={"Date"} value={event?.utcDate ? new Date(event?.utcDate) : null} onChange={(v) => {
            onChange({
              ...event,
              utcDate: v,
              source: {
                ...(event.source || {}),
                utcDate: v,
              }
            })
          }}
          />

          <div className="input-group">
            <span className="input-group-text">Nombre de personnes</span>
            {event?.source?.nbPeople > 1 && <span className="input-group-text" style={{cursor: "pointer"}} onClick={() => {
              onChange({
                ...event,
                source: {
                  ...(event.source || {}),
                  nbPeople: (event?.source?.nbPeople) - 1
                }
              })
            }}>
              -
            </span>}
            <input type="number" className="form-control" value={event?.source?.nbPeople} onChange={e => {
              onChange({
                ...event,
                source: {
                  ...(event.source || {}),
                  nbPeople: e.target.value,
                }
              })
            }} />
            <span className="input-group-text " style={{cursor: "pointer"}} onClick={() => {
              onChange({
                ...event,
                source: {
                  ...(event.source || {}),
                  nbPeople: (event?.source?.nbPeople) + 1
                }
              })
            }}>
              +
            </span>
          </div>


        </div>
      }
    }
  }

  return <div>
    <h2>{serviceSelected?.name}</h2>
    {!hasServiceProps && <div className="input-group">
      <span className="input-group-text">Service</span>
      <select value={event?.serviceId} disabled={hasServiceProps} className="form-control" onChange={(e: any) => {
        onChange({
          ...event,
          serviceId: e.target.value,
        })
      }}>
        <option value={""}>{"Choisissez"}</option>
        {serviceList?.map(s => {
          return <option key={`service_${s.id}`} value={s?.id}>{s?.name}</option>
        })}
      </select>
    </div>}
    {renderForm(event)}
    <div className="input-group">
      <span className="input-group-text ">Statut</span>
      <select className="form-control" value={event?.status} onChange={(e) => {
        onChange({
          ...event,
          status: (e.target.value as EventStatusEnum),
        })
      }}>
        <option value="">Selectionnez un statut</option>
        <option value={EventStatusEnum.EVENT_STATUS_CONFIRMED}>Confirmé</option>
        <option value={EventStatusEnum.EVENT_STATUS_PENDING}>En attente de confirmation</option>
        <option value={EventStatusEnum.EVENT_STATUS_CANCELLED}>Annulé</option>
      </select>
      {!!errors?.status && <span className="input-group-text ">
        <span className="icon-warning-sign"></span>
      </span>}
    </div>
  </div>
}

