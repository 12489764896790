import { RoomTypeInput } from "model"
import React from "react"
import { RoomTypeForm } from "../Form/RoomTypeForm"

type RoomTypeEditProps = {
  hotelId: string,
  roomType: RoomTypeInput,
  errors: any,
  onChange: (roomType: RoomTypeInput) => void,
}

export const RoomTypeEdit = ({ roomType, hotelId, errors, onChange }: RoomTypeEditProps) => {

  return <div>
    <RoomTypeForm open={true} roomType={roomType} errors={errors} onChange={onChange} hotelId={hotelId} />
  </div>

}