import { IconAddressCard, Modal } from "@zipou/front_tools"
import { CustomerAddress } from "model"
import React, { useState } from "react"
import { CustomerAddressDetails } from "../Details/CustomerAddressDetails"

type CustomerAddressDisplayProps = {
  customerAddress: CustomerAddress
}

export const CustomerAddressDisplayInline = ({ customerAddress }: CustomerAddressDisplayProps) => {

  const [displayDetails, updateDisplayDetails] = useState(false)

  const hasAddress1Or2 = customerAddress?.address1 || customerAddress?.address2
  const hasZipCode = customerAddress?.zipCode || customerAddress?.address2
  const hasCity = customerAddress?.city || customerAddress?.address2

  return <>
    <Modal display={displayDetails} onClose={() => updateDisplayDetails(false)}>
      <CustomerAddressDetails id={customerAddress?.id} />
    </Modal>
    <button className="btn btn-sm btn-dark" onClick={() => updateDisplayDetails(true)}> <IconAddressCard /></button> {hasAddress1Or2 ? `${customerAddress?.address1}${customerAddress?.address2 ? ` ${customerAddress?.address2}` : ""}, ` : ``} {hasZipCode ? customerAddress?.zipCode : ""} {hasCity ? `${customerAddress?.city}, ` : ''} {customerAddress?.country}</>
}