import React, {useState} from "react"


import './style.scss'
import {isFieldOnError} from "helpers/Apollo"
import {BooleanInput} from "components/common/Form/BooleanInput"
import {TextInput} from "components/common/Form/TextInput"
import {RoomType} from "model"
import { TextField } from "@zipou/front_tools"

type RoomTypeFormProps = {
  roomType?: Partial<RoomType>,
  open?: any,
  hotelId: string,
  errors: any,
  onChange: (room: any) => void
}

export const RoomTypeForm = (props: RoomTypeFormProps) => {

  const [open, updateOpen] = useState(props?.open)
  const roomType = props?.roomType
  const errors = props?.errors
  const hasErrors = errors && Object.keys(errors).length > 0

  return <div className="card room-type-container">
    <div className="card-header" onClick={() => updateOpen(!open)}>
      {hasErrors && <span className="icon-warning-sign"> </span>}
      {roomType?.type}
    </div>
    <div className={open ? "collapse-container-room collapse-visible-room" : "collapse-container-room"}>
      <div className="card-body">
        <TextInput value={roomType?.type} errors={props?.errors?.type} label="Type" onChange={(e, v) => {
          props?.onChange({
            ...roomType,
            type: v,
          })
        }} />

        <TextInput value={roomType?.labelFr} errors={props?.errors?.labelFr} label="Libellé Français" onChange={(e, v) => {
          props?.onChange({
            ...roomType,
            labelFr: v,
          })
        }} />

        <TextInput value={roomType?.labelEn} errors={props?.errors?.labelEn} label="Libellé Anglais" onChange={(e, v) => {
          props?.onChange({
            ...roomType,
            labelEn: v,
          })
        }} />

        <BooleanInput value={roomType?.canBeSelectedInProposition} label={"Proposition ?"} errors={errors} onChange={(value: boolean) => {
          props?.onChange({
            ...roomType,
            canBeSelectedInProposition: value,
          })
        }} />
        <div className="input-group">
          <span className='input-group-text'>Images</span>
          <div className="form-control" style={{height: "100%"}}>
            {roomType?.descImgUrls?.map((descImgUrl: string, index: number) => {
              return <div className="input-group" key={`descImgUrl_${index}`}>
                <span className="input-group-text">Url</span>
                <input className="form-control" type="text" value={descImgUrl} onChange={(e: any) => {
                  props?.onChange({
                    ...roomType,
                    descImgUrls: roomType?.descImgUrls?.map((el: any, id: number) => (id === index) ? e.target.value : el)
                  })
                }} />
                <span className="input-group-text">
                  <span className="icon-close" onClick={() => {
                    props?.onChange({
                      ...roomType,
                      descImgUrls: roomType?.descImgUrls?.filter((el: any, id: number) => id !== index)
                    })
                  }}></span>
                </span>

              </div>
            })}
            <div className="">
              <button className="btn btn-dark btn-sm" onClick={() => {
                props?.onChange({
                  ...roomType,
                  descImgUrls: [
                    ...(roomType?.descImgUrls || []),
                    ""
                  ],
                })
              }}>Ajouter</button>
            </div>
          </div>
        </div>

        <div className="input-group">
          <span className="input-group-text">Description FR</span>
          <div className="form-control" style={{height: "100%"}}>
            <TextField isError={isFieldOnError(errors, "descTextFr")} value={roomType?.descTextFr || ""} label={"Principale"} onChange={(value) => {
              props?.onChange({
                ...roomType,
                descTextFr: value,
              })
            }} />
            <TextField isError={isFieldOnError(errors, "descCapacityFr")} value={roomType?.descCapacityFr || ""} label={"Secondaire"} onChange={(value) => {
              props?.onChange({
                ...roomType,
                descCapacityFr: value,
              })
            }} />

          </div>
        </div>
        <div className="input-group">
          <span className="input-group-text">Description EN</span>
          <div className="form-control" style={{height: "100%"}}>
            <TextField isError={isFieldOnError(errors, "descTextEn")} value={roomType?.descTextEn || ""} label={"Principale"} onChange={(value) => {
              props?.onChange({
                ...roomType,
                descTextEn: value,
              })
            }} />
            <TextField isError={isFieldOnError(errors, "descCapacityEn")} value={roomType?.descCapacityEn || ""} label={"Secondaire"} onChange={(value) => {
              props?.onChange({
                ...roomType,
                descCapacityEn: value,
              })
            }} />
          </div>
        </div>
      </div>
    </div>
  </div>
}

/*

descImgUrl: string
descCapacityFr: string
descTextFr: string
descCapacityEn: string
descTextEn: string


*/