import { PropositionStatusEnum } from "model"
import React from "react"


type PropositionStatusProps = {
  value: PropositionStatusEnum
}

export const PropositionStatus = (props: PropositionStatusProps) => {

  const value = props?.value

  switch (value) {
    case "STATUS_DRAFT":
      return <span className="badge badge-warning">BROUILLON</span>

    case "STATUS_CONFIRMED":
      return <span className="badge badge-secondary">CONFIRMEE PAR CLIENT</span>

    case "STATUS_SENT":
      return <span className="badge badge-primary">ENVOYEE</span>

    case "STATUS_READ":
      return <span className="badge badge-success">LU</span>

    case "STATUS_DELIVERED":
      return <span className="badge badge-success">DELIVREE</span>

    case "STATUS_VALID":
      return <span className="badge badge-info">VALIDE</span>

    case "STATUS_SELECTED":
      return <span className="badge badge-dark">SELECTIONNEE</span>

    case "STATUS_DELETED":
      return <span className="badge badge-warning">SUPRIMEE</span>

    case "STATUS_WAITING_PAYMENT":
      return <span className="badge badge-dark">EN ATTENTE PAIEMENT</span>

    case PropositionStatusEnum.STATUS_ERROR:
      return <span className="badge badge-danger">ERREUR</span>

    default:
      return <span className="badge badge-info">{value}</span>
  }

}