import { v4 as uuid } from "uuid"
import React from "react"
import { PostStayGroupInput, PostStayGroupItemTypeEnum } from "model"
import { TextInput } from "components/common/Form/TextInput"
import { PostStayQuestionForm } from "../../Question/Form/PostStayQuestionForm"
import { IconArrowDown, IconArrowUp, IconTrash } from "@zipou/front_tools"

type PostStayGroupFormProps = {
  postStayGroup: PostStayGroupInput,
  errors: any,
  onChange: (postStayGroup: PostStayGroupInput) => void,
}


export const PostStayGroupForm = ({ postStayGroup, errors, onChange }: PostStayGroupFormProps) => {

  // const questionList = postStayGroup?.questionList
  const itemList = postStayGroup?.PostStayGroupItem

  const onMoveItem = (index: number, up: boolean) => {
    //Interverti juste les deux elements dans l'array et update le state
    let newArray = [...itemList]
    const elementToMove = itemList[index]
    const backup = itemList[index - (1 * (up ? 1 : -1))]

    newArray[index - (1 * (up ? 1 : -1))] = elementToMove
    newArray[index] = backup

    onChange({
      ...postStayGroup,
      PostStayGroupItem: newArray,
    })

  }


  return <div>
    <div className="input-group">
      <span className="input-group-text">Label</span>
      <div className="form-control" style={{ height: "100%" }}>
        <TextInput id={"labelFr"} value={postStayGroup?.labelFr} errors={errors} label={"labelFr"} onChange={(_, v) => {
          onChange({
            ...postStayGroup,
            labelFr: v,
          })
        }} />
        <TextInput id={"labelEn"} value={postStayGroup?.labelEn} errors={errors} label={"labelEn"} onChange={(_, v) => {
          onChange({
            ...postStayGroup,
            labelEn: v,
          })
        }} />
      </div>
    </div>

    <div className="input-group">
      <span className="input-group-text">Description</span>
      <div className="form-control" style={{ height: "100%" }}>
        <TextInput id={"descFr"} value={postStayGroup?.descFr} errors={errors} label={"descFr"} onChange={(_, v) => {
          onChange({
            ...postStayGroup,
            descFr: v,
          })
        }} />
        <TextInput id={"descEn"} value={postStayGroup?.descEn} errors={errors} label={"descEn"} onChange={(_, v) => {
          onChange({
            ...postStayGroup,
            descEn: v,
          })
        }} />
      </div>
    </div>

    <h3>Liste des questions</h3>

    {itemList?.map((item, itemIndex) => {

      const canMoveUp = itemIndex > 0
      const canMoveDown = itemIndex < itemList?.length - 1

      const renderQuestion = () => {
        const question = item?.Question
        return <div className='input-group'>
          <div className="form-control" style={{ height: "100%" }}>
            <PostStayQuestionForm errors={errors && errors?.PostStayGroupItem && errors?.PostStayGroupItem[itemIndex]?.questionList && errors?.PostStayGroupItem[itemIndex]?.questionList} postStayQuestion={question} onChange={(postStayQuestion) => {
              onChange({
                ...postStayGroup,
                PostStayGroupItem: postStayGroup?.PostStayGroupItem?.map((item, idx) => {
                  return idx === itemIndex ? {
                    ...item,
                    Question: postStayQuestion
                  } : item
                })
              })

            }} />
          </div>
          <span className='input-group-text' onClick={() => {
            onChange({
              ...postStayGroup,
              PostStayGroupItem: postStayGroup?.PostStayGroupItem?.filter((_, idx) => {
                return idx !== itemIndex
              })
            })
          }}>
            <IconTrash />
          </span>
        </div>
      }

      const renderSeparator = () => {
        return <div>
          <h3 className="mt-3">Séparateur</h3>

          <div className='input-group'>
            <div className="form-control" style={{ height: "100%" }}>
              <TextInput id="labelFr" value={item?.Separator?.labelFr} label="Label FR" onChange={(_, v) => {
                onChange({
                  ...postStayGroup,
                  PostStayGroupItem: postStayGroup?.PostStayGroupItem?.map((item, idx) => {
                    return idx === itemIndex ? {
                      ...item,
                      Separator: {
                        ...item.Separator,
                        labelFr: v
                      }
                    } : item
                  })
                })
              }} />
              <TextInput id="labelEn" value={item?.Separator?.labelEn} label="Label EN" onChange={(_, v) => {
                onChange({
                  ...postStayGroup,
                  PostStayGroupItem: postStayGroup?.PostStayGroupItem?.map((item, idx) => {
                    return idx === itemIndex ? {
                      ...item,
                      Separator: {
                        ...item.Separator,
                        labelEn: v
                      }
                    } : item
                  })
                })
              }} />
            </div>
            <span className='input-group-text' onClick={() => {
              onChange({
                ...postStayGroup,
                PostStayGroupItem: postStayGroup?.PostStayGroupItem.filter((_, idx) => {
                  return idx !== itemIndex
                })
              })
            }}>
              <IconTrash />
            </span>
          </div>
        </div>

      }

      const isQuestion = item?.type === PostStayGroupItemTypeEnum.TYPE_QUESTION
      const isSeparator = item?.type === PostStayGroupItemTypeEnum.TYPE_SEPARATOR

      return <div className="card mt-2" key={`item${item?.id}_${itemIndex}`}>
        <div className="card-header bg-secondary text-white">
          {canMoveUp && <button className='btn btn-dark btn-sm mr-4' onClick={() => onMoveItem(itemIndex, true)}>
            <IconArrowUp />
          </button>}
          #{itemIndex + 1} {isQuestion ? item?.Question?.labelFr : item?.Separator?.labelFr}
          {canMoveDown && <button className='btn btn-dark btn-sm ml-4' onClick={() => onMoveItem(itemIndex, false)}>
            <IconArrowDown />
          </button>}
        </div>

        {isQuestion && renderQuestion()}
        {isSeparator && renderSeparator()}

      </div>
    })}

    <div className="mt-3">
      <button className="btn btn-sm btn-dark ml-2" onClick={(v) => {
        onChange({
          ...postStayGroup,
          PostStayGroupItem: [
            ...(postStayGroup?.PostStayGroupItem || []),
            {
              id: uuid(),
              type: PostStayGroupItemTypeEnum.TYPE_QUESTION,
              Question: {
                id: uuid(),
              }
            },
          ]
        })
      }} >Ajouter une question
      </button>
      <button className="btn btn-sm btn-dark ml-2" onClick={(v) => {
        onChange({
          ...postStayGroup,
          PostStayGroupItem: [
            ...(postStayGroup?.PostStayGroupItem || []),
            {
              id: uuid(),
              type: PostStayGroupItemTypeEnum.TYPE_SEPARATOR,
            },
          ]
        })
      }} >Ajouter un séparateur
      </button>
    </div>

  </div >



}