import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { Helmet } from "react-helmet";
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { userContext } from "context/User"
import { Chat, TargetTypeEnum } from "components/common/CHAT/Chat"
import { User, UserRoleEnum } from "model"
import { NotificationHelper } from "components/front/APP/Notification/Helper/NotificationHelper";
import { IconSearch } from "@zipou/front_tools";
import {
  URL_FRONT,
  URL_GRAPHQL,
  URL_BACKOFFICE_USER_LIST,
  URL_CRM_SEGMENTATION_ADD,
  URL_CRM_SEGMENTATION_LIST,
  URL_CONTACT_DEDUP_LIST,
  URL_RESERVATION_SEARCH,
  URL_RESERVATION_GROUP_LIST,
  URL_CONFIRMATION_LIST,
  URL_PRE_STAY_LIST,
  URL_BACKOFFICE_HOTEL_LIST,
  URL_PROPOSITION_ADD,
  URL_PROPOSITION_LIST,
  URL_PLANNING_EVENT_LIST,
  URL_BACKOFFICE_PAYMENT_ADD,
  URL_BACKOFFICE_PAYMENT_LIST,
  URL_PLANNING_ADD,
  URL_PLANNING_LIST,
  URL_USER_CONFIG_LIST,
  URL_POST_STAY_LIST,
  URL_PHONE_CALL_LIST,
  URL_PHONE_DIRECTORY,
  URL_PHONE_CONFIG,
  URL_NETWORK_CONFIG,
  URL_CORP_LIST,
  URL_POST_STAY_DASHBOARD,
  URL_VIDEO_CAMERA_LIST,
  URL_SEARCH,
  URL_NEWS_LIST,
  URL_BACKOFFICE_BILLING_TOKEN_LIST,
  URL_BACKOFFICE_BILLING_TOKEN_ADD,
  URL_CHECKIN_LIST,
} from "constant/url"

import "./style.scss"

export const Menu = (props: any) => {

  const history = useHistory()
  const user = useContext<User>(userContext)

  const onLinkClick = (URL: string) => {
    history?.push(URL)
  }

  const isAdmin = user?.roles?.includes(UserRoleEnum.ROLE_ADMIN)
  const isCRM = user?.roles?.includes(UserRoleEnum.ROLE_CRM)
  const isPmsPostStay = user?.roles?.includes(UserRoleEnum.ROLE_PMS_STAY_POST)
  const isPmsPreStay = user?.roles?.includes(UserRoleEnum.ROLE_PMS_STAY_PRE)
  const isPMS = user?.roles?.includes(UserRoleEnum.ROLE_PMS)
  const isCheckin = user?.roles?.includes(UserRoleEnum.ROLE_PMS_CHECKIN)
  const isBilling = user?.roles?.includes(UserRoleEnum.ROLE_BILLING)
  const isPlanning = user?.roles?.includes(UserRoleEnum.ROLE_PLANNING)
  const isChat = user?.roles?.includes(UserRoleEnum.ROLE_CHAT)
  const isPhone = user?.roles?.includes(UserRoleEnum.ROLE_PHONE)
  const isVideo = user?.roles?.includes(UserRoleEnum.ROLE_VIDEO)
  const isReservation = user?.roles?.includes(UserRoleEnum.ROLE_PMS_RESERVATION)
  const isNetwork = user?.roles?.includes(UserRoleEnum.ROLE_NETWORK_VIEW)

  const hasGeneral = user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_GENERAL)
  const hasConfirmation = user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_CONFIRMATION)
  const hasOptions = user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_OPTIONS)
  const hasPriceRate = user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_RATE_CODE)
  const hasRoomType = user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_ROOM_TYPE)
  // const hasNetworkView = user?.roles?.includes(UserRoleEnum.ROLE_NETWORK_VIEW)

  const hasRoleToken = user?.roles?.includes(UserRoleEnum.ROLE_BILLING_TOKEN)

  const isConfig = isAdmin || hasGeneral || hasConfirmation || hasOptions || hasRoomType || hasPriceRate || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG)


  return <Navbar bg="light" expand="lg">
    <Helmet>
      <title>{user?.UserConfig?.datas?.title || "Title"}</title>
      <meta name="description" content={user?.UserConfig?.datas?.title || "Title"} />
      <link rel="icon" type="image/png" href="favicon.png" />
    </Helmet>
    <Container>
      <Nav.Link onClick={() => onLinkClick(URL_FRONT)}>
        {user?.UserConfig?.datas?.logoUrl && <Navbar.Brand>
          <img width="90" className="d-inline-block align-center" src={user?.UserConfig?.datas?.logoUrl} alt="" />
        </Navbar.Brand>}
      </Nav.Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {isChat && <Chat targetType={TargetTypeEnum.TARGET_AGENT} hotelIds={user?.hotels} />}
        <Nav className="mr-auto">

          {isPMS && <NavDropdown title="PMS" id="nav-dropdown">
            {isAdmin && <div>
              <div className="dropdown-header">Contacts</div>
              <NavDropdown.Item eventKey="8.1" onClick={() => onLinkClick(URL_CONTACT_DEDUP_LIST)}>Gestion des doublons</NavDropdown.Item>
              <div className="dropdown-divider"></div>
            </div>}
            {(isReservation || isCheckin) && <div>
              {isReservation && <><div className="dropdown-header">Reservation</div>
                <NavDropdown.Item eventKey="3.1" onClick={() => onLinkClick(URL_RESERVATION_SEARCH)}>Voir</NavDropdown.Item>
              </>}
              {isCheckin && <NavDropdown.Item eventKey="3.2" onClick={() => onLinkClick(URL_CHECKIN_LIST)}>Checkin</NavDropdown.Item>}
              <div className="dropdown-divider"></div>
            </div>}
            <div className="dropdown-header">Groupes</div>
            <NavDropdown.Item eventKey="9.1" onClick={() => onLinkClick(URL_RESERVATION_GROUP_LIST)}>Voir</NavDropdown.Item>
            <div className="dropdown-divider"></div>
            <div className="dropdown-header">Confirmation</div>
            <NavDropdown.Item eventKey="4.1" onClick={() => onLinkClick(URL_CONFIRMATION_LIST)}>Voir</NavDropdown.Item>
            <div className="dropdown-divider"></div>
            {isPmsPreStay && <div>
              <div className="dropdown-header">Pre Stay</div>
              <NavDropdown.Item eventKey="6.1" onClick={() => onLinkClick(URL_PRE_STAY_LIST)}>Voir</NavDropdown.Item>
              <div className="dropdown-divider"></div>
            </div>}
            {isPmsPostStay && <div>
              <div className="dropdown-header">Post Stay</div>
              <NavDropdown.Item eventKey="7.1" onClick={() => onLinkClick(URL_POST_STAY_LIST)}>Voir</NavDropdown.Item>
              <NavDropdown.Item eventKey="7.2" onClick={() => onLinkClick(URL_POST_STAY_DASHBOARD)}>Dashboard</NavDropdown.Item>
              <div className="dropdown-divider"></div>
            </div>}
            {(isPMS) && <div>
              <div className="dropdown-header">Proposition</div>
              <NavDropdown.Item eventKey="5.1" onClick={() => onLinkClick(URL_PROPOSITION_ADD)}>Ajouter</NavDropdown.Item>
              <NavDropdown.Item eventKey="5.2" onClick={() => onLinkClick(URL_PROPOSITION_LIST)}>Lister</NavDropdown.Item>
            </div>}
          </NavDropdown>}

          {isCRM && <NavDropdown title="CRM" id="nav-dropdown">
            {/* <div className="dropdown-header">Clients</div>
            <NavDropdown.Item eventKey="1.1" onClick={() => onLinkClick(URL_CUSTOMER_LIST)}>Lister</NavDropdown.Item>
            <div className="dropdown-divider"></div> */}
            <div className="dropdown-header">Segmentation</div>
            <NavDropdown.Item eventKey="2.1" onClick={() => onLinkClick(URL_CRM_SEGMENTATION_ADD)}>Ajouter</NavDropdown.Item>
            <NavDropdown.Item eventKey="2.2" onClick={() => onLinkClick(URL_CRM_SEGMENTATION_LIST)}>Lister</NavDropdown.Item>
          </NavDropdown>
          }

          {(isBilling) && <NavDropdown title="PAIEMENT" id="nav-dropdown" className="billing_menu">
            <div className="dropdown-header">Liens</div>
            <NavDropdown.Item eventKey="9.1" className="billing_payment_add" onClick={() => onLinkClick(URL_BACKOFFICE_PAYMENT_ADD)}>Créer</NavDropdown.Item>
            <NavDropdown.Item eventKey="9.2" className="billing_payment_list" onClick={() => onLinkClick(URL_BACKOFFICE_PAYMENT_LIST)}>Lister</NavDropdown.Item>
            {hasRoleToken && <><div className="dropdown-header">Empreintes Bancaires</div>
              <NavDropdown.Item eventKey="9.3" className="billing_token_add" onClick={() => onLinkClick(URL_BACKOFFICE_BILLING_TOKEN_ADD)}>Créer</NavDropdown.Item>
              <NavDropdown.Item eventKey="9.4" className="billing_token_list" onClick={() => onLinkClick(URL_BACKOFFICE_BILLING_TOKEN_LIST)}>Lister</NavDropdown.Item>
            </>}
          </NavDropdown>}

          {(isPlanning) && <NavDropdown title="PLANNING" id="nav-dropdown">
            <div className="dropdown-header">Planning</div>
            <NavDropdown.Item eventKey="10.1" onClick={() => onLinkClick(URL_PLANNING_ADD)}>Ajouter</NavDropdown.Item>
            <NavDropdown.Item eventKey="10.2" onClick={() => onLinkClick(URL_PLANNING_LIST)}>Lister</NavDropdown.Item>
            <div className="dropdown-header">Evenement</div>
            <NavDropdown.Item eventKey="10.3" onClick={() => onLinkClick(URL_PLANNING_EVENT_LIST)}>Lister</NavDropdown.Item>
          </NavDropdown>}

          {(isPhone) && <NavDropdown title={<span>TELEPHONIE</span>} id="nav-dropdown">
            <div className="dropdown-header">Appels</div>
            <NavDropdown.Item eventKey="11.1" onClick={() => onLinkClick(URL_PHONE_CALL_LIST)}>
              <span>Lister</span>
            </NavDropdown.Item>
            <div className="dropdown-header">Répertoire</div>
            <NavDropdown.Item eventKey="11.3" onClick={() => onLinkClick(URL_PHONE_DIRECTORY)}>
              <span>Afficher</span>
            </NavDropdown.Item>
            <div className="dropdown-header">Configuration</div>
            <NavDropdown.Item eventKey="11.2" onClick={() => onLinkClick(URL_PHONE_CONFIG)}>
              <span>Editer</span>
            </NavDropdown.Item>
          </NavDropdown>}

          {(isNetwork) && <NavDropdown title={<span>RESEAU</span>} id="nav-dropdown">
            <div className="dropdown-header">Statut</div>
            <NavDropdown.Item eventKey="12.1" onClick={() => onLinkClick(URL_NETWORK_CONFIG)}>
              <span>Afficher</span>
            </NavDropdown.Item>
          </NavDropdown>}

          {(isVideo) && <NavDropdown title={<span>VIDEO</span>} id="nav-dropdown">
            <div className="dropdown-header">Caméra</div>
            <NavDropdown.Item eventKey="12.1" onClick={() => onLinkClick(URL_VIDEO_CAMERA_LIST)}>
              <span>Lister</span>
            </NavDropdown.Item>
          </NavDropdown>}

          {(isConfig) && <NavDropdown title="CONFIG" id="nav-dropdown">
            <div className="dropdown-header">Hotels</div>
            <NavDropdown.Item eventKey="6.1" onClick={() => onLinkClick(URL_BACKOFFICE_HOTEL_LIST)}>Configuration</NavDropdown.Item>
            <div className="dropdown-divider"></div>
            {isAdmin && <div>
              <div className="dropdown-header">Utilisateurs</div>
              <NavDropdown.Item eventKey="9.1" onClick={() => onLinkClick(URL_CORP_LIST)}>Corp</NavDropdown.Item>
              <NavDropdown.Item eventKey="7.2" onClick={() => onLinkClick(URL_BACKOFFICE_USER_LIST)}>Utilisateurs</NavDropdown.Item>
              <div className="dropdown-divider"></div>
              <div className="dropdown-header">Application</div>
              <NavDropdown.Item eventKey="8.1" onClick={() => onLinkClick(URL_USER_CONFIG_LIST)}>Configurations</NavDropdown.Item>
              <NavDropdown.Item eventKey="8.3" onClick={() => onLinkClick(URL_NEWS_LIST)}>News</NavDropdown.Item>
              <NavDropdown.Item eventKey="8.2" onClick={() => onLinkClick(URL_GRAPHQL)}>GraphQL</NavDropdown.Item>
              {/* <div className="dropdown-divider"></div> */}
            </div>}
          </NavDropdown>}

        </Nav>
        <Nav>
          {isPMS && <Nav.Link onClick={() => onLinkClick(URL_SEARCH)}>
            <IconSearch />
          </Nav.Link>}
          {/* {isPMS && <SearchForm onSearch={(value: string) => history.push(URL_SEARCH_QUERY.replace(":query", value))} />} */}
        </Nav>
        <Nav className="ml-5">
          {user && <NotificationHelper />}
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
}


type SearchFormProps = {
  onSearch: (value: string) => void,
}

export const SearchForm = (props: SearchFormProps) => {

  const [text, updateText] = useState("")

  return <form className="form-inline" onSubmit={(e: any) => {
    e.preventDefault()
    props?.onSearch(text)
  }}>
    <input className="form-control form-control-sm mr-sm-2 " type="search" placeholder="Rechercher" value={text} aria-label="Search" onChange={(e: any) => updateText(e.target.value)} />
    <button className="btn btn-sm  btn-outline-blue-h8 my-2 my-sm-0" type="submit">Rechercher</button>
  </form>
}
