import { Collapse, IconTrash, Modal, TextInput } from "@zipou/front_tools"
import { FileEncryptedAdd } from "components/front/APP/FileEncrypted/Add/FileEncryptedAdd"
import { CheckinInput, Reservation, Token, TokenInput, TokenTypeEnum } from "model"
import React, { useState } from "react"
import { CheckinIdentityPictureForm } from "../Identity/Picture/CheckinIdentityPictureForm"
import { TokenAdd } from "components/front/BILLING/Token/Add/TokenAdd"
import { TokenDisplay } from "components/front/BILLING/Token/Display/TokenDisplay"
import tokenAddGql from "graphql/BILLING/Token/mutation/tokenAdd.gql"
import { useMutation } from "@apollo/react-hooks"
import { TokenDisplayCheckin } from "components/front/BILLING/Token/Display/TokenDisplayCheckin"

export type CheckinFormProps = {
  reservation: Reservation,
  checkin: CheckinInput,
  onChange: (checkin: CheckinInput) => void,
  errors: any,
}

export const CheckinForm = ({ checkin, errors, onChange, reservation }: CheckinFormProps) => {

  const [displayTokenAdd, updateDisplayTokenAdd] = useState<boolean>()
  const [focus, updateFocus] = useState<"DETAILS" | "IDENTITY" | "BILLING">()

  const [mutate, { loading }] = useMutation<{ token: Token }, { input: TokenInput }>(tokenAddGql)

  const onTokenAdd = () => {
    mutate({
      variables: {
        input: {
          hotelId: reservation?.hotelId,
          contactId: reservation?.Contact?.id,
          // terminalId: null,
          type: TokenTypeEnum.TYPE_TERMINAL,
          // notes: "",
        }
      }
    })
      .then((response) => {
        onChange({
          ...checkin,
          billing: {
            tokenId: response?.data?.token?.id
          }
        })
      })
  }


  return <div>

    <div className="card">
      <div className="card-header text-white bg-primary" onClick={() => updateFocus("DETAILS")}>Informations</div>
      <Collapse collapsed={focus !== "DETAILS"}>
        <div className="card-body">

          <TextInput id="name" value={checkin?.details?.name} label="Nom" errors={errors} onChange={v => {
            onChange({
              ...checkin,
              details: {
                ...checkin?.details,
                name: v
              }
            })
          }} />

          <TextInput id="phone" value={checkin?.details?.phone} label="Téléphone" errors={errors} onChange={v => {
            onChange({
              ...checkin,
              details: {
                ...checkin?.details,
                phone: v
              }
            })
          }} />

          <TextInput id="phone" value={checkin?.details?.mail} label="Mail" errors={errors} onChange={v => {
            onChange({
              ...checkin,
              details: {
                ...checkin?.details,
                mail: v
              }
            })
          }} />

          <TextInput id="address" value={checkin?.details?.address} label="Adresse" errors={errors} onChange={v => {
            onChange({
              ...checkin,
              details: {
                ...checkin?.details,
                address: v
              }
            })
          }} />


        </div>
      </Collapse>
    </div>

    <div className="card ml-1">
      <div className="card-header text-white bg-primary" onClick={() => updateFocus("IDENTITY")}>Photos Identité</div>
      <Collapse collapsed={focus !== "IDENTITY"}>

        <div className="card-body">
          <div className="row">
            {checkin?.identity?.checkinIdentityPicture?.map((checkinIdentityPicture, index) => {
              return <div className="col-12" key={`checkinIP_${checkinIdentityPicture?.encryptedFileId || index}_${index}`}>
                <div className="input-group">
                  <div className="form-control" style={{ height: "100%" }}>
                    <CheckinIdentityPictureForm errors={errors?.identity && errors?.identity?.checkinIdentityPicture && errors?.identity?.checkinIdentityPicture[index]} checkinIdentityPictureInput={checkinIdentityPicture} onChange={(cip) => {
                      onChange({
                        ...checkin,
                        identity: {
                          ...checkin?.identity,
                          checkinIdentityPicture: checkin?.identity?.checkinIdentityPicture?.map((c, i) => i === index ? cip : c)
                        }
                      })
                    }} />
                  </div>
                  <span className="input-group-text">
                    <button className="btn btn-secondary" onClick={() => onChange({
                      ...checkin,
                      identity: {
                        ...checkin?.identity,
                        checkinIdentityPicture: checkin?.identity?.checkinIdentityPicture?.filter((c, i) => i !== index)
                      }
                    })}>
                      <IconTrash />
                    </button>
                  </span>
                </div>
              </div>
            })}
          </div>

          <button className="btn btn-dark btn-sm" onClick={() => {
            onChange({
              ...checkin,
              identity: {
                ...checkin?.identity,
                checkinIdentityPicture: [...(checkin?.identity?.checkinIdentityPicture || []), {}]
              }
            })
          }}>Ajouter une photo</button>

        </div>
      </Collapse>
    </div>

    <div className="card ml-1">
      {/* {displayTokenAdd && <Modal display title="Ajouter une empreinte bancaire">
        <TokenAdd onDone={(token) => {
          onChange({
            ...checkin,
            billing: {
              tokenId: token?.id,
            }
          })
          updateDisplayTokenAdd(false)
        }} />
      </Modal>} */}
      <div className="card-header text-white bg-primary" onClick={() => updateFocus("BILLING")}>Empreintes Bancaires</div>
      <Collapse collapsed={focus !== "BILLING"}>

        <div className="card-body">
          {checkin?.billing?.tokenId && <TokenDisplayCheckin tokenId={checkin?.billing?.tokenId} />}
          {!checkin?.billing?.tokenId && <button className="btn btn-dark btn-sm" onClick={() => {
            onTokenAdd()
          }}>Ajouter une Empreinte Bancaire</button>}

          {checkin?.billing?.tokenId && <button className="btn btn-warning btn-sm" onClick={() => {
            onTokenAdd()
          }}>Remplacer l'Empreinte Bancaire</button>}

        </div>
      </Collapse>
    </div>
  </div >


}