import gql from "graphql-tag";
import RunnerFragmentGql from "../fragment/RunnerFragment.gql";

export default gql`


${RunnerFragmentGql}

  subscription runnerUpdate($id: String!) {
    runner: runnerUpdate(id: $id) {
      ...RunnerFragment
    }
  }

`