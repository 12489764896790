import gql from "graphql-tag";
import HotelPublicFragment from "graphql/PMS/Hotel/fragment/HotelPublicFragment";
import ReservationPublicFragment from "graphql/PMS/Reservation/fragment/ReservationPublicFragment";
import PostStayConfigFragment from "../Config/fragment/PostStayConfigFragment";
import ContactPublicFragment from "graphql/PMS/Contact/fragment/ContactPublicFragment";

export default gql`

  ${ReservationPublicFragment}
  ${HotelPublicFragment}
  ${PostStayConfigFragment}
  ${ContactPublicFragment}

  fragment PostStayFragment on PostStay {
    id
    publicId
    status
    sendStatus
    responseStatus
    reason
    AnswerList
    Contact {
      ...ContactPublicFragment
    }
    PostStayConfig {
      ...PostStayConfigFragment
    }
    Reservation {
      ...ReservationPublicFragment
    }
    Hotel {
      ...HotelPublicFragment
    }
  }

`