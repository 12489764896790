import { useQuery } from "@apollo/react-hooks"
import fileListGql from "graphql/APP/File/query/fileList.gql"
import React, { useState } from "react"
import { File } from "model"
import { FileAdd } from "../Add/FileAdd"
import { Modal } from "components/common/Navigation/Modal/Modal"
import { FileDisplay } from "../Display/FileDisplay"
import { FileEdit } from "../Edit/FileEdit"
import { IconEdit, IconSearch } from "@zipou/front_tools"

type FileListProps = {
  hotelId: string,
}

export const FileList = (props: FileListProps) => {

  const [focusEdit, updateFocusEdit] = useState<string | null>(null)
  const [focusAdd, updateFocusAdd] = useState(false)
  const [focusDisplay, updateFocusDisplay] = useState<string | null>(null)

  const { data, refetch } = useQuery<{ list: File[] }>(fileListGql, {
    variables: {
      hotelId: props?.hotelId,
    }
  })

  const hotelId = props?.hotelId
  const fileList = data?.list

  return <div>
    <Modal display={!!focusDisplay} onClose={() => updateFocusDisplay(null)}>
      <FileDisplay id={focusDisplay} />
    </Modal>
    <Modal display={!!focusEdit} onClose={() => updateFocusEdit(null)}>
      <FileEdit id={focusEdit} onDone={() => updateFocusEdit(null)} />
    </Modal>
    <Modal display={focusAdd} onClose={() => updateFocusAdd(false)}>
      <FileAdd hotelId={hotelId} onDone={() => {
        refetch({
          hotelId: props?.hotelId,
        })
          .then(() => {
            updateFocusAdd(false)
          })
      }} />
    </Modal>
    <table className="table table-stripped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">Nom</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {fileList?.map(file => {
          return <tr key={`file_${file.id}`}>
            <td>
              {file.name}
            </td>
            <td>
              <button className="btn btn-sm  btn-dark mr-1" onClick={() => updateFocusDisplay(file.id)}>
                <IconSearch />
              </button>
              <button className="btn btn-sm btn-dark mr-1" onClick={() => updateFocusEdit(file.id)}>
                <IconEdit />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>

    <button className="btn btn-sm btn-dark" onClick={() => updateFocusAdd(true)}>Ajouter</button>
  </div>

}