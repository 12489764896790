import {Cookies} from 'react-cookie';
// import {COOKIE_KEY} from "app/static/constant/user";

const cookie = new Cookies()

const COOKIE_LOGIN_KEY = "H8_CRM"
const COOKIE_CTI_KEY = "H8_CTI"

export const cookieSave = (key: string, value: string) => {
  return cookie.set(key, value);
}

export const cookieGet = (key: string) => {
  return cookie.get(key);
}

export const cookieClear = (key: string) => {
  cookieSave(key, "")
  return cookie.remove(key);
}

export const saveCTICookie = (value: any) => {
  return cookieSave(COOKIE_CTI_KEY, JSON.stringify(value));
}
export const getCTICookie = () => {
  const value = cookieGet(COOKIE_CTI_KEY);
  return value
}
export const clearCTICookie = (value: any) => {
  return cookieClear(COOKIE_CTI_KEY);
}

export const saveLoginCookie = (value : any) => {
  cookie.remove(COOKIE_LOGIN_KEY);
  return cookieSave(COOKIE_LOGIN_KEY, JSON.stringify(value));
}

export const getLoginCookie =  () => {
  return cookieGet(COOKIE_LOGIN_KEY);
}

export const clearLoginCookie = () => {
  return cookieClear(COOKIE_LOGIN_KEY)
}

// export const Cookie = {
//   save : (token:any, expires_in:any, refresh_token:any) => {
//     let authInfo = {
//       token,
//       refresh_token,
//       expiration: Math.floor(Date.now() / 1000) + expires_in
//     }
//     cookie.set(COOKIE_KEY, JSON.stringify(authInfo));
//   },
//   flush: () => {
//     cookie.set(COOKIE_KEY, JSON.stringify({}));
//   },
//   isValid : () => {
//     const now = Math.floor(Date.now() / 1000);
//     let data = this.get();
//     let {token, expiration} = (data) ? data: {};
//     return (token && (expiration > now));
//   },
//   get : () => {
//     let data = cookie.get(COOKIE_KEY);
//     return (data) ? data : {};
//   }
// }
