import React from "react"
import {useTranslation} from "react-i18next";

type PriceRateTypePublicDisplayProps = {
  priceRateType: any,
  children?: (hasExtra: boolean, component: any) => any,
}

export const PriceRateTypePublicDisplay = (props: PriceRateTypePublicDisplayProps) => {

  const priceRateType = props?.priceRateType;

  const {t} = useTranslation('translation', {keyPrefix: 'confirmation'});

  const hasBreakfast = priceRateType?.isBreakfastIncluded
  const hasSpa = priceRateType?.hasSpa
  const hasFood = priceRateType?.hasFood
  const hasDrink = priceRateType?.hasDrink

  const hasExtra = hasBreakfast || hasSpa || hasFood || hasDrink

  const text = `${(hasBreakfast ? `${t("hasBreakfast")}, ` : ``) || ``}${((hasFood && !hasDrink) ? `${t("hasFood")}, ` : ``) || ``}${((hasDrink && !hasFood) ? `${t("hasDrink")}, ` : ``) || ``}${((hasDrink && hasFood) ? `${t("hasFoodAndDrinks")}, ` : ``) || ``}${hasSpa ? `${t("hasSpa")}, ` : ''}`.replace(/, $/, "")
  return props?.children(hasExtra, <span>{text}</span>) || <></>

}

