import gql from "graphql-tag";

export default gql`

fragment ReservationConfirmationFragment on Reservation {
  id
  pmsId
  arrivalDate
  ReservationRoom {
    id
    Room {
      id
      RoomType {
        id
      }
    }
  }
}

`