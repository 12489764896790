import { DateFormat } from "components/common/Format/DateFormat"
import { PriceFormat } from "components/common/Format/PriceFormat"
import { userContext } from "context/User"
import { ReservationRate, UserRoleEnum } from "model"
import React, { useContext } from "react"

type ReservationRateListProps = {
  reservationRateList: ReservationRate[]
}

export const ReservationRateList = (props: ReservationRateListProps) => {

  const user = useContext(userContext)
  const hasDebug = user?.roles?.includes(UserRoleEnum.ROLE_DEBUG)

  return <div>
    <table className="table table-stripped table-bordered">
      <thead>
        <tr className="bg-blue-h8 text-white">
          {hasDebug && <th className="text-white">Id</th>}
          <th className="text-white">Date</th>
          <th className="text-white">Label</th>
          <th className="text-white">Code</th>
          <th className="text-white">Montant</th>
        </tr>
      </thead>
      <tbody>
        {props?.reservationRateList?.map((rate) => {
          return <tr>
            {hasDebug && <td className="">
              {rate?.id}
            </td>}
            <td className="">
              <DateFormat value={new Date(rate?.startDate)} />
            </td>
            <td className="">{rate.PriceRateType?.labelFr}</td>
            <td className="">{rate.PriceRateType?.code}</td>
            <td className=""><PriceFormat value={rate.amountIncVat} /></td>
          </tr>
        })}
      </tbody>
    </table>
  </div>
}