import { useQuery } from "@apollo/react-hooks"
import cameraGetGql from "graphql/VIDEO/Camera/query/cameraGet.gql"
import { Camera, CameraStreamingTypeEnum } from "model"
//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from '!video.js';
//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojsContribDash from "!videojs-contrib-dash"
import 'video.js/dist/video-js.css';


import React, { useEffect, useState } from "react"
import { getRootUrl } from "helpers/Utils";
import { findToken } from "helpers/User";

type CameraViewProps = {
  cameraId: string,
}

export const CameraView = ({ cameraId }: CameraViewProps) => {

  const [urlType, updateUrlType] = useState<"hlsURL" | "liveURL">("liveURL")
  const [serial, updateSerial] = useState(new Date().getTime())

  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  // const { options, onReady } = props;

  const { data } = useQuery<{ camera: Camera }>(cameraGetGql, {
    variables: {
      id: cameraId,
    }
  })
  const camera = data?.camera

  useEffect(() => {
    updateSerial(new Date().getTime())
  }, [])


  const dashUrl = `${getRootUrl()}video/stream/${camera?.Runner?.id}/manifest.mpd?access_token=${findToken()}&serial=${serial}`
  const m3url = `${getRootUrl()}video/stream/${camera?.Runner?.id}/live.m3u8?access_token=${findToken()}&serial=${serial}`
  const hlsUrl = `${getRootUrl()}video/stream/${camera?.Runner?.id}/master.m3u8?access_token=${findToken()}&serial=${serial}`
  // const m3url = `${getRootUrl()}video/stream/${data?.camera?.Runner?.id}/live`

  const enterFullScreen = (player: any) => {
    if (player.requestFullscreen) {
      player.requestFullscreen();
    } else if (player.webkitRequestFullscreen) { // Pour Safari
      player.webkitRequestFullscreen();
    } else if (player.mozRequestFullScreen) { // Pour Firefox
      player.mozRequestFullScreen();
    } else if (player.msRequestFullscreen) { // Pour IE/Edge
      player.msRequestFullscreen();
    }
  };

  useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);


      // //@ts-ignore
      // videojs.Vhs.xhr.beforeRequest = (options) => {

      //   return {
      //     ...options,
      //     headers: {
      //       ...(options.headers || {}),
      //       Authorization: `Bearer ${findToken() || "token"}`,
      //       // Authorization: `${findToken()}`,
      //       // Hello: "world",
      //     }
      //   }
      //   // if (!options.headers) {
      //   //   console.log("SETTING HEADERS")
      //   //   options.headers = {
      //   //     Authorization: `Bearer ${findToken()}`
      //   //   }
      //   // }
      //   // return options
      //   // // options.headers["Authorization"] = `Bearer ${findToken()}`
      // }

      videojs.registerPlugin('videojsContribDash', videojsContribDash);
      const player = playerRef.current = videojs(videoElement, {
        fluid: true,
        responsive: true,
        controls: true,
      }, () => {
        videojs.log('player is ready');
        // onReady && onReady(player);
      });

      // console.log("SETTING URL", m3url)

      // player.autoplay(true);
      // player.src(m3url);
      // You could update an existing player in the `else ` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      player.autoplay(true);
      // enterFullScreen(player)


      switch (camera?.streamingType) {
        case CameraStreamingTypeEnum.HLS: {
          player.src([{
            src: urlType === "hlsURL" ? hlsUrl : m3url,
            type: "application/x-mpegurl"
          }]);
          break;
        }
        case CameraStreamingTypeEnum.DASH: {
          player.src([{
            src: dashUrl,
            type: "application/dash+xml"
          }]);
          break;
        }

      }



      // player.src([{
      //   src: m3url,
      //   type: "application/x-mpegurl"
      // }]);


    }
  }, [videoRef, m3url, dashUrl, urlType]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return <>
    <div className="input-group">
      <select className="form-control" value={urlType} onChange={(e) => updateUrlType(e.target.value as "hlsURL" | "liveURL")}>
        <option value="hlsURL">HLS URL</option>
        <option value="liveURL">LIVE URL</option>
      </select>
    </div>
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  </>


}


