import { IconTrash, TextInput } from "@zipou/front_tools"
import { CameraInput, VideoConfigInput } from "model"
import React from "react"
import { CameraForm } from "../../Camera/Form/CameraForm"

type VideoConfigFormProps = {
  videoConfig: VideoConfigInput,
  errors: any,
  onChange: (videoConfig: VideoConfigInput) => void,
}

export const VideoConfigForm = ({ videoConfig, errors, onChange }: VideoConfigFormProps) => {


  const cameraList: CameraInput[] = videoConfig?.CameraList

  return <div>
    <TextInput id="storageDir" value={videoConfig?.storageDir || ""} errors={errors} label="Dossier Stockage" onChange={(v) => {
      onChange({
        ...videoConfig,
        storageDir: v,
      })
    }} />
    <div className="" style={{ height: "100%" }}>
      {cameraList?.map((camera, index) => {
        return <div className="input-group">
          <span className="input-group-text">Camera {index+1}</span>
          <div className="form-control" style={{ height: "100%" }}>
            <CameraForm
              errors={errors}
              camera={camera}
              onChange={(c) => {
                onChange({
                  ...videoConfig,
                  CameraList: videoConfig?.CameraList?.map((cam, idx) => idx === index ? c : cam)
                })
              }} />
          </div>
          <span className="input-group-text">
            <IconTrash />
          </span>
        </div>
      })}

      <button className="btn btn-sm btn-dark" onClick={() => {
        onChange({
          ...videoConfig,
          CameraList: [...(videoConfig?.CameraList || []), {}]
        })
      }}>Ajouter</button>
    </div>
  </div>
}