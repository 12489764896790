import {TextInput} from 'components/common/Form/TextInput'
import { PhoneAgentConfigInput } from 'model'
import React from 'react'

type PhoneAgentConfigFormProps = {
  phoneAgentConfig: PhoneAgentConfigInput,
  errors?: any,
  onChange: (data: PhoneAgentConfigInput) => void,
}

export const PhoneAgentConfigForm = (props: PhoneAgentConfigFormProps) => {


  const phoneAgentConfig: PhoneAgentConfigInput = props?.phoneAgentConfig

  const onChange = (d: any) => {
    props?.onChange(d)
  }

  return <div>
    {/* <SelectInput id="phoneConfigId" label="PhoneConfig" errors={props?.errors} choiceList={data?.list?.map((config: any) => ({id: config?.id, label: `${config.host} ${config.id}`}))} value={phoneAgentConfig?.phoneConfigId} onChange={(_, d) => {
      onChange({
        ...phoneAgentConfig,
        phoneConfigId: d,
    })}} /> */}
    {/* <div className="input-group">
      <span className="input-group-text">incomingContext</span>
      <div className="form-control" style={{height: "100%"}}>
        {phoneAgentConfig?.incomingContext?.map((context: any, index: number) => {
          return <div className="input-group" key={`context_${index}`}>
            <div className="form-control" style={{height: "100%"}}>
              <TextInput key={`context_${index}`} label="incomingContext" value={context || ""} id={"context"} onChange={(_, v) => onChange({
                ...phoneAgentConfig,
                incomingContext: phoneAgentConfig?.incomingContext?.map((context: any, idx: number) => {
                  return (idx === index) ? v : context
                })
              })}
              />
            </div>
            <span className="input-group-text">
              <span className="icon-trash" onClick={() => {
                onChange({
                  ...phoneAgentConfig,
                  incomingContext: phoneAgentConfig?.incomingContext?.filter((context: any, idx: number) => {
                    return (idx !== index)
                  })
                })
              }}></span>
            </span>
          </div>
        })}
        <button className="btn btn-dark btn-sm" onClick={() => {
          onChange({
            ...phoneAgentConfig,
            incomingContext: [
              ...(phoneAgentConfig?.incomingContext || []),
              "",
            ]
          })
        }}>Ajouter</button>
      </div>
    </div> */}
    {/* <div className="input-group">
      <span className="input-group-text">incomingExtension</span>
      <div className="form-control" style={{height: "100%"}}>
        {phoneAgentConfig?.incomingExtension?.map((extension: any, index: number) => {
          return <div className="input-group" key={`extension_${index}`}>
            <div className="form-control" style={{height: "100%"}}>
              <TextInput key={`extension_${index}`} label="incomingExtension" value={extension || ""} id={"extension"} onChange={(_, v) => onChange({
                ...phoneAgentConfig,
                incomingExtension: phoneAgentConfig?.incomingExtension?.map((context: any, idx: number) => {
                  return (idx === index) ? v : context
                })
              })}
              />
            </div>
            <span className="input-group-text">
              <span className="icon-trash" onClick={() => {
                onChange({
                  ...phoneAgentConfig,
                  incomingExtension: phoneAgentConfig?.incomingExtension?.filter((_: any, idx: number) => {
                    return (idx !== index)
                  })
                })
              }}></span>
            </span>
          </div>
        })}
        <button className="btn btn-dark btn-sm" onClick={() => {
          onChange({
            ...phoneAgentConfig,
            incomingExtension: [
              ...(phoneAgentConfig?.incomingExtension || []),
              "",
            ]
          })
        }}>Ajouter</button>
      </div>
    </div> */}
    {/* <TextInput label="outgoingContext" id={"outgoingContext"} errors={props?.errors} value={phoneAgentConfig?.outgoingContext} onChange={(e, v) => {
      onChange({
        ...phoneAgentConfig,
        outgoingContext: v,
      })
    }} /> */}
    <TextInput label="phoneConfigId" id={"phoneConfigId"} errors={props?.errors} value={phoneAgentConfig?.phoneConfigId} onChange={(e, v) => {
      onChange({
        ...phoneAgentConfig,
        phoneConfigId: v,
      })
    }} />
    <TextInput label="token" id={"token"} errors={props?.errors} value={phoneAgentConfig?.token} onChange={(e, v) => {
      onChange({
        ...phoneAgentConfig,
        token: v,
      })
    }} />
    <TextInput label="accountName" id={"accountName"} errors={props?.errors} value={phoneAgentConfig?.accountName} onChange={(e, v) => {
      onChange({
        ...phoneAgentConfig,
        accountName: v,
      })
    }} />
  </div>
}
