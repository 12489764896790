import React from "react"
import { PostStaySendStatusEnum } from "model"

export type PostStaySendStatusProps = {
  status: PostStaySendStatusEnum
}

export const PostStaySendStatus = ({ status }: PostStaySendStatusProps) => {


  switch (status) {
    case PostStaySendStatusEnum.POST_STAY_SEND_STATUS_HOLD: {
      return <span className="badge badge-sm badge-warning">NON VALIDE</span>
    }

    case PostStaySendStatusEnum.POST_STAY_SEND_STATUS_TODO: {
      return <span className="badge badge-sm badge-secondary">EN ATTENTE</span>
    }

    default: {
      return <span className="badge badge-sm badge-dard">{status}</span>
    }

  }


}