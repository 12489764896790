import React, { } from "react"


import { ContactSearchField } from "components/front/PMS/Contact/Field/ContactSearchField"
import { HotelListSelect } from "components/front/PMS/Hotel/List/HotelListSelect"


import { isFieldOnError } from "helpers/Apollo";
import { Terminal, TerminalTypeEnum, TokenInput, TokenTypeEnum } from "model";
import { TextInput } from "components/common/Form/TextInput";
import { SelectInput } from "@zipou/front_tools";
import { useQuery } from "@apollo/react-hooks";
import terminalListGql from "graphql/BILLING/Terminal/query/terminalList.gql";

type TokenFormProps = {
  token?: TokenInput,
  errors?: any,
  onChange: (input?: TokenInput) => void,
}

export const TokenForm = ({ token, errors, onChange }: TokenFormProps) => {

  const { data } = useQuery<{ list: Terminal[] }>(terminalListGql, {
    variables: {
      hotelId: token?.hotelId,
    },
    skip: !token?.hotelId,
  })
  const terminalList = data?.list

  const hasErrors = Object.keys(errors).length > 0
  const isTypeTerminal = token?.type === TokenTypeEnum.TYPE_TERMINAL


  return <div className="token-container">
    {hasErrors && <div className="alert alert-danger"><h1>Oups :(</h1>Il y'a une erreur...</div>}

    <HotelListSelect
      hotelFilter={(hotel) => !!hotel?.BillingConfig?.id}
      isError={isFieldOnError(errors, "hotelId")}
      label='Hotel'
      hotelId={token?.hotelId}
      onChange={(hotelId: string) => {
        onChange({
          ...token,
          hotelId
        })
      }}

    />

    <ContactSearchField isError={isFieldOnError(errors, "contactId")} label='Contact PMS' hotelId={token?.hotelId} value={token?.contactId || ""} onChange={(contactId: string, contact: any) => {
      onChange({
        ...token,
        contactId,
      })
    }}
    />

    <SelectInput id="type" disabled={!token?.hotelId} errors={errors?.type} label="Type d'Empreinte" value={token?.type} choiceList={[{ id: TokenTypeEnum.TYPE_LINK, label: "Par Lien Internet" }, { id: TokenTypeEnum.TYPE_TERMINAL, label: "Par TPE" }]} onChange={(type) => onChange({
      ...token,
      type: type as TokenTypeEnum,
    })} />


    {isTypeTerminal && <SelectInput label="Terminal" id="terminalId" value={token?.terminalId} errors={errors} choiceList={terminalList?.map(terminal => ({ id: terminal?.id, label: terminal?.name }))} onChange={terminalId => {
      onChange({
        ...token,
        terminalId,
      })
    }} />}


    <div className="input-group">
      <span className="input-group-text">Infos</span>
      <div className="form-control" style={{ height: "100%" }}>
        <TextInput id="notes" errors={errors} value={token?.notes} label="Notes" onChange={(e, v) => {
          onChange({
            ...token,
            notes: v,
          })
        }} />
      </div>
    </div>
  </div>


}

