import { useMutation, useQuery } from "@apollo/react-hooks"
import { DateTimeFormat } from "components/common/Format/DateTimeFormat";
import { PriceFormat } from "components/common/Format/PriceFormat";
import { TemporisationButton } from "components/common/Navigation/Button/TemporisationButton";
import { Modal } from "components/common/Navigation/Modal/Modal";
import { Pagination } from "components/common/Navigation/Pagination/Pagination";
import { URL_BACKOFFICE_PAYMENT_ADD, URL_BACKOFFICE_PAYMENT_EDIT } from "constant/url";
import paymentSetDeletedGql from "graphql/BILLING/Payment/mutation/paymentSetDeleted.gql";
import paymentListGql from "graphql/BILLING/Payment/query/paymentList.gql"
import { CursorInput, Payment, PaymentCaptureTypeEnum, PaymentListInput, PaymentStatusEnum } from "model";
import React, { useContext, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import { PaymentCaptureType } from "../Capture/Type/PaymentCaptureType";
import { PaymentCaptureTypeFilter } from "../CaptureType/PaymentCaptureTypeFilter";
import { PaymentStatus } from "../Status/PaymentStatus";
import { PaymentStatusFilter } from "../Status/PaymentStatusFilter";
import { IconSearch, IconTrash, TextInput } from "@zipou/front_tools";
import { PaymentSendStatus } from "../Send/Status/PaymentSendStatus";
import { ContactEditButton } from "components/front/PMS/Contact/Widget/ContactEditButton";
import { userContext } from "context/User";
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge";

export const PaymentList = () => {

  const history = useHistory()


  const [focusCaptureDate, updateFocusCaptureDate] = useState<boolean>(null)
  const [focusCreationDate, updateFocusCreationDate] = useState<boolean>(null)
  const [focusNotes, updateFocusNotes] = useState<boolean>(false)
  const [focusEmail, updateFocusEmail] = useState<boolean>(false)
  const [focusStatus, updateFocusStatus] = useState<boolean>(false)
  const [focusHotel, updateFocusHotel] = useState<boolean>(false)
  const [focusContact, updateFocusContact] = useState<boolean>(false)
  const [focusCaptureType, updateFocusCaptureType] = useState<boolean>(false)

  const user = useContext(userContext)

  const [filters, updateFilters] = useState<PaymentListInput>({
    orderField: "payment.creationDate",
    orderDirection: "DESC",
    status: [PaymentStatusEnum.STATUS_INITIAL, PaymentStatusEnum.STATUS_PENDING_CUSTOMER, PaymentStatusEnum.STATUS_DONE],
    captureType: [PaymentCaptureTypeEnum.CAPTURE_TYPE_PRE_AUTH, PaymentCaptureTypeEnum.CAPTURE_TYPE_FULL],
    hotelIds: user?.hotels,
    contact: null,
    email: null,
    notes: null,
    creationDate: null,
    captureDate: null,
  })

  const { data, refetch, loading } = useQuery<{ list: { nodes: Payment[], cursor: CursorInput, input: PaymentListInput } }>(paymentListGql, {
    variables: {
      cursor: {
        page: 1,
        limit: 20,
      },
      input: filters
    }
  })

  const [deleteMutate] = useMutation(paymentSetDeletedGql)

  const onDelete = async (paymentId: string) => {
    return deleteMutate({
      variables: {
        id: paymentId
      },
      refetchQueries: [{
        query: paymentListGql,
        variables: {
          cursor: {
            page: data?.list?.cursor?.page,
            limit: data?.list?.cursor?.limit,
          },
          input: data?.list.input,
        }
      }]

    })
      .then(() => console.log('OK'))
      .catch((e: any) => console.log('ERROR', e.message))

  }

  const paymentList = data?.list

  const { nodes, cursor } = paymentList || {}
  const hasResults = nodes?.length > 0

  const displayTotal = true
  const totalValue = displayTotal && nodes?.reduce((acc: number, p) => p.amount + acc, 0)

  return <div className="payment-list">


    {focusCreationDate && <Modal display={true} title={"Date de Création"} onClose={() => updateFocusCreationDate(false)}>

      <div className="input-group">
        <span className="input-group-text">Filtrer par date de création</span>
        <input type="date" className="form-control" value={filters.creationDate || ""} onChange={(e) => updateFilters({
          ...filters,
          creationDate: e.target.value
        })} />
        <span className="input-group-text" onClick={() => updateFilters({
          ...filters,
          creationDate: null,
        })}>
          <IconTrash />
        </span>
      </div>
      <div className="input-group">
        <span className="input-group-text">Tri</span>
        <div className="form-control p-1" style={{ height: "100%" }}>
          <button type="button" className="btn btn-sm btn-secondary" style={{ whiteSpace: "nowrap" }} onClick={() => {
            updateFilters({
              ...filters,
              orderDirection: "ASC",
              orderField: "payment.creationDate",
            })
          }}>
            Croissant
          </button>
          <button type="button" className="btn btn-sm btn-secondary ml-1" style={{ whiteSpace: "nowrap" }} onClick={() => {
            updateFilters({
              ...filters,
              orderDirection: "DESC",
              orderField: "payment.creationDate",
            })
          }}>
            Décroissant
          </button>
        </div>
      </div>

    </Modal>}


    {focusCaptureDate && <Modal display={true} title={"Date de Capture"} onClose={() => updateFocusCaptureDate(false)}>


      <div className="input-group">
        <span className="input-group-text">Filtrer par date de capture</span>
        <input type="date" className="form-control" value={filters.captureDate || ""} onChange={(e) => updateFilters({
          ...filters,
          captureDate: e.target.value
        })} />
        <span className="input-group-text" onClick={() => updateFilters({
          ...filters,
          captureDate: null,
        })}>
          <IconTrash />
        </span>
      </div>
      <div className="input-group">
        <span className="input-group-text">Tri</span>
        <div className="form-control p-1" style={{ height: "100%" }}>
          <button type="button" className="btn btn-sm btn-secondary" style={{ whiteSpace: "nowrap" }} onClick={() => {
            updateFilters({
              ...filters,
              orderDirection: "ASC",
              orderField: "payment.captureDate",
            })
          }}>
            Croissant
          </button>
          <button type="button" className="btn btn-sm btn-secondary ml-1" style={{ whiteSpace: "nowrap" }} onClick={() => {
            updateFilters({
              ...filters,
              orderDirection: "DESC",
              orderField: "payment.captureDate",
            })
          }}>
            Décroissant
          </button>
        </div>
      </div>

    </Modal>}

    {focusStatus && <Modal display={true} onClose={() => updateFocusStatus(false)}>
      <PaymentStatusFilter value={filters.status} onChange={(value: any) => {
        updateFilters({
          ...filters,
          status: value,
        })
      }} />
    </Modal>}

    {focusHotel && <Modal display={true} onClose={() => updateFocusHotel(false)}>
      <button className="btn btn-sm btn-warning" onClick={() => updateFilters({
        ...filters,
        hotelIds: [],
      })}>Clear All</button>
      <button className="btn btn-sm btn-primary" onClick={() => updateFilters({
        ...filters,
        hotelIds: user.hotels,
      })} style={{ marginLeft: 5 }}>Select All</button>
      <ul className="list-group">
        {user?.Hotel?.map((hotel: any) => {
          const isChecked = filters.hotelIds?.includes(hotel?.id);
          return <li key={`hotel_filter_${hotel?.id}`} className="list-group-item" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <input type="checkbox" checked={isChecked} id={`cb_${hotel?.id}`} onChange={(e: any) => updateFilters({ ...filters, hotelIds: e.target.checked ? [...(filters.hotelIds || []), hotel?.id] : filters.hotelIds?.filter((el: any) => el !== hotel?.id) })}></input>
            <label htmlFor={`cb_${hotel?.id}`}><HotelBadge hotel={hotel} /></label>
          </li>
        })}
      </ul>
    </Modal>}


    {focusCaptureType && <Modal display={true} onClose={() => updateFocusCaptureType(false)}>
      <PaymentCaptureTypeFilter value={filters.captureType} onChange={(value: any) => {
        updateFilters({ ...filters, captureType: value, })
      }} />
    </Modal>}

    {focusContact && <Modal display={true} onClose={() => updateFocusContact(false)}>
      <div className="pt-2">
        <TextInput onChange={(v) => updateFilters({ ...filters, contact: v, })} label="Filter par nom" errors={{}} value={filters.contact} rightContent={<span onClick={() => {
          updateFilters({ ...filters, contact: "", })
          updateFocusContact(false)
        }}>
          <IconTrash />
        </span>} />

      </div>
    </Modal>}

    {focusEmail && <Modal display={true} onClose={() => updateFocusEmail(false)}>
      <div className="pt-2">
        <TextInput onChange={(v) => updateFilters({ ...filters, email: v, })} label="Filter par email" errors={{}} value={filters.email} rightContent={<span onClick={() => {
          updateFilters({ ...filters, email: "", })
          updateFocusEmail(false)
        }}>
          <IconTrash />
        </span>} />
      </div>
    </Modal>}

    {focusNotes && <Modal display={true} onClose={() => updateFocusNotes(false)}>
      <div className="pt-2">
        <TextInput onChange={(v) => updateFilters({ ...filters, notes: v, })} label="Filter par Notes" errors={{}} value={filters.notes} rightContent={<span onClick={() => {
          updateFilters({ ...filters, notes: null, })
          updateFocusNotes(false)
        }}>
          <IconTrash />
        </span>} />
      </div>
    </Modal>}


    {(loading) && <div className="position-fixed" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div className="alert alert-warning">Chargement...</div>
    </div>}

    <div className="content">
      <table className="table table-stripped table-bordered">
        <thead>
          <tr className="bg-dark text-white">
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" onClick={() => updateFocusHotel(true)}>
                Hotel
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" onClick={() => updateFocusEmail(true)}>
                Email
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" onClick={() => updateFocusContact(true)}>
                Contact
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" onClick={() => updateFocusNotes(true)}>
                Notes
              </button>
            </th>

            <th className="text-white">
              <button className="btn btn-sm btn-secondary" onClick={() => updateFocusCreationDate(true)} >
                {/* <button type="button" className="btn btn-sm btn-secondary" style={{ whiteSpace: "nowrap" }} onClick={() => {
                refetch({
                  ...data?.list?.cursor,
                  input: {
                    ...data?.list.input,
                    orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                    orderField: "payment.creationDate",
                  },
                })
              }}> */}
                Date de Création {data?.list.input?.orderField === "payment.creationDate" && <span className={(data?.list.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
                {/* Date de Création {data?.list.input?.orderField === "payment.creationDate" && <span className={(data?.list.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />} */}
              </button>
            </th>

            <th className="text-white">
              <button className="btn btn-sm btn-secondary" onClick={() => updateFocusCaptureDate(true)} >
                {/* <button type="button" className="btn btn-sm btn-secondary" style={{ whiteSpace: "nowrap" }} onClick={() => {
                refetch({
                  ...data?.list?.cursor,
                  input: {
                    ...data?.list.input,
                    orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                    orderField: "payment.captureDate",
                  },
                })
              }}> */}
                Date de Capture {data?.list.input?.orderField === "payment.captureDate" && <span className={(data?.list.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
                {/* Date de Capture {data?.list.input?.orderField === "payment.captureDate" && <span className={(data?.list.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />} */}
              </button>
            </th>
            <th className="text-white">
              <button type="button" className="btn btn-sm btn-secondary" onClick={() => updateFocusStatus(true)}>
                Statut <span className="badge badge-sm badge-dark">{filters.status?.length}</span>
              </button>
            </th>
            <th className="text-white">
              <button type="button" className="btn btn-sm btn-secondary" onClick={() => updateFocusCaptureType(true)}>
                Type <span className="badge badge-sm badge-dark">{filters.captureType?.length}</span>
              </button>
            </th>
            <th className="text-white">
              Envoi Mail
            </th>
            <th className="text-white">
              Montant
            </th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        {(!hasResults && !loading) && <tbody>
          <tr>
            <td colSpan={11}>
              <div className="alert alert-primary">Rien à afficher</div>
            </td>
          </tr>
        </tbody>}
        {hasResults && <tbody>
          {nodes?.map((payment: Payment) => {
            const { email, notes, creationDate, captureDate, Contact } = payment

            const isStatusDeleted = payment.status === PaymentStatusEnum.STATUS_DELETED

            return <tr key={`payment${payment?.id}`}>
              <td><span className="badge" style={{ backgroundColor: `#${payment?.Hotel?.themeColor}`, color: "white" }}>{payment?.Hotel?.name}</span></td>
              <td>{email || ""}</td>
              <td>
                {Contact && <ContactEditButton contact={Contact} />}
              </td>
              <td>{notes || ""}</td>
              <td>{creationDate && <DateTimeFormat value={new Date(creationDate)} showUTC={false} />}</td>
              <td>{captureDate && <DateTimeFormat value={new Date(captureDate)} showUTC={false} />}</td>
              <td><PaymentStatus status={payment?.status} /></td>
              <td><PaymentCaptureType type={payment?.captureType} /></td>
              <td><PaymentSendStatus status={payment?.sendStatus} /></td>
              <td style={{ "whiteSpace": "nowrap" }}><PriceFormat value={payment?.amount} /></td>
              <td className="actions" style={{ display: "flex", justifyContent: "space-between" }}>
                <button className="btn btn-sm btn-dark mr-1" onClick={() => history.push(URL_BACKOFFICE_PAYMENT_EDIT.replace(':paymentId', payment?.id))}>
                  <IconSearch />
                </button>
                {!isStatusDeleted && <TemporisationButton seconds={3} onClick={() => onDelete(payment?.id)}>
                  {(onClick, spinnerComponent) => <button className="btn btn-sm btn-danger" onClick={onClick}>
                    {spinnerComponent}
                    <span>
                      <IconTrash />
                    </span>
                  </button>}
                </TemporisationButton>}
              </td>
            </tr>
          })}
          {displayTotal && <tr>
            <td colSpan={9} align="right">
              Total
            </td>
            <td>
              <PriceFormat value={totalValue} />
              </td>
            <td></td>
          </tr>}
        </tbody>}
      </table>
    </div>
    <div className="pagination-container">
      <Pagination cursor={cursor} onChange={(page: number) => {
        refetch({
          cursor: {
            page,
            limit: data?.list?.cursor?.limit,
          },
          input: data?.list.input,
        })
      }} />
    </div>
    <div className="">
      <Link to={URL_BACKOFFICE_PAYMENT_ADD}><button className="btn btn-sm btn-dark">Ajouter</button></Link>
    </div>
  </div>
}