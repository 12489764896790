import { useQuery } from "@apollo/react-hooks"
import postStayAnswerStatsGetByQuestionIdWithFiltersGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetByQuestionIdWithFilters.gql"
import { PostStayAnswerStats, PostStayAnswerStatsFilterInput } from "model"
import React from "react"

type PostStayAnswerStatsDisplayProps = {
  questionId: string,
  filters: PostStayAnswerStatsFilterInput
}

export const PostStayAnswerStatsDisplay = ({ questionId, filters }: PostStayAnswerStatsDisplayProps) => {

  const { data } = useQuery<{ postStayAnswerStats: PostStayAnswerStats }>(postStayAnswerStatsGetByQuestionIdWithFiltersGql, {
    variables: {
      questionId,
      input: filters,
    }
  })
  const stats = data?.postStayAnswerStats
  const hasData = !!stats

  return <>
    {hasData && <div>
      <span style={{ fontSize: 22 }}>
        {stats?.avgValue}
      </span>
      <table className="table table-striped table-bordered" style={{ margin: 0 }}>
        <tbody>
          {stats?.choiceValue && Object.keys(stats?.choiceValue)?.map((statsKey: any) => {
            const value = stats?.choiceValue[statsKey]
            return <tr>
              <td style={{ fontSize: 20, textTransform: "capitalize" }}>{statsKey}</td>
              <td style={{ fontSize: 20 }}>{value.percent}%</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>}
    {!hasData && <span className="">Aucune donnée</span>}
  </>
}