import { useMutation, useQuery } from "@apollo/react-hooks"
import { IconPause, IconPlay, IconSearch, Modal } from "@zipou/front_tools"
import cameraListByUserGql from "graphql/VIDEO/Camera/query/cameraListByUser.gql"
import runnerStartGql from "graphql/VIDEO/Runner/mutation/runnerStart.gql"
import runnerStopGql from "graphql/VIDEO/Runner/mutation/runnerStop.gql"
import { Camera, RunnerStatusEnum } from "model"
import React, { useContext, useState } from "react"
import { CameraView } from "../View/CameraView"
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge"
import { userContext } from "context/User"
import { RunnerActions } from "../../Runner/Actions/RunnerActions"

type CameraListProps = {

}

export const CameraList = ({ }: CameraListProps) => {


  const user = useContext(userContext)


  const [displayCamera, updateDisplayCamera] = useState<Camera | null>()
  const [displayEditCamera, updateDisplayEditCamera] = useState<Camera | null>()



  const { data } = useQuery<{ list: Camera[] }>(cameraListByUserGql)
  const cameraList = data?.list
  const isAdmin = user?.isAdmin

  return <div>
    {displayCamera && <Modal display title="Camera" onClose={() => updateDisplayCamera(null)}>
      <CameraView cameraId={displayCamera?.id} />
    </Modal>}

    <h1>Camera</h1>
    <table className="table table-striped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">Hotel</th>
          <th className="bg-dark text-white">Nom</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {cameraList?.map(camera => {

          const isRunning = camera?.Runner?.status === RunnerStatusEnum.STATUS_RUNNING

          return <tr key={`camera_list_${camera?.id}`}>
            <td>
              <HotelBadge hotel={camera?.VideoConfig?.Hotel} />
            </td>
            <td>{camera?.name}</td>
            <td>
              <button className="btn btn-warning btn-sm ml-1" onClick={() => updateDisplayCamera(camera)}>
                <IconSearch />
              </button>
              <RunnerActions runnerId={camera?.Runner?.id} />
            </td>
          </tr>
        })}
      </tbody>
    </table>
    { }
  </div>


}