import gql from "graphql-tag";
import ContactListFragment from "graphql/PMS/Contact/fragment/ContactListFragment";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";
import ReservationConfirmationListFragmentGql from "graphql/PMS/Reservation/fragment/ReservationConfirmationListFragment.gql";
import ReservationGroupConfirmationListFragmentGql from "graphql/PMS/Reservation/Group/fragment/ReservationGroupConfirmationListFragment.gql";

export default gql`

${HotelDisplayFragment}
${ContactListFragment}
${ReservationConfirmationListFragmentGql}
${ReservationGroupConfirmationListFragmentGql}

fragment ConfirmationListFragment on Confirmation{
  id
  creationDate
  status
  type
  hotelId
  Hotel {
    ...HotelDisplayFragment
  }
  Reservation {
    ...ReservationConfirmationListFragment
  }
  Contact {
    ...ContactListFragment
  }
  ReservationGroup {
    ...ReservationGroupConfirmationListFragment
  }

}

`