import { useQuery } from "@apollo/react-hooks"
import moment from "moment"
import { DateInput, SelectInput } from "@zipou/front_tools"
import postStayConfigGetGql from "graphql/PMS/Stay/Post/Config/query/postStayConfigGet.gql"
import { PostStayAnswerStatsFilterInput, PostStayConfig, StatsDimensionType } from "model"
import React, { useState } from "react"
import { PostStayAnswerStatsDisplay } from "../Answer/Stats/Display/PostStayAnswerStats"
import { StatsFilterWeekly } from "./Filters/StatsFiltersWeekly"
import { StatsFilterMonthly } from "./Filters/StatsFiltersMonthly"

type PostStayDashboardProps = {
  hotelId: string,
}

export const PostStayDashboard = ({ hotelId }: PostStayDashboardProps) => {

  const [filters, updateFilters] = useState<PostStayAnswerStatsFilterInput>({
    dimensionDate: new Date(new Date().getTime() - (24 * 3600 * 1000)),
    dimensionType: StatsDimensionType.WEEKLY,
  })

  const { data } = useQuery<{ postStayConfig: PostStayConfig }>(postStayConfigGetGql, {
    variables: {
      hotelId,
    }
  })

  const postStayConfig = data?.postStayConfig

  const isDaily = filters.dimensionType === StatsDimensionType.DAILY
  const isWeekly = filters.dimensionType === StatsDimensionType.WEEKLY
  const isMonthly = filters.dimensionType === StatsDimensionType.MONTHLY

  return <>
    <h1>Dashboard</h1>
    <div>
      <SelectInput label="Type" errors={{}} id="dimensionType" value={filters?.dimensionType} onChange={(v) => updateFilters({
        ...filters,
        dimensionType: v as StatsDimensionType,
      })} choiceList={[{ id: StatsDimensionType.DAILY, label: "JOUR" }, { id: StatsDimensionType.WEEKLY, label: "SEMAINE" }, { id: StatsDimensionType.MONTHLY, label: "MOIS" }, { id: StatsDimensionType.YEARLY, label: "ANNEE" }]} />
      {isDaily && <DateInput label="Date" value={filters?.dimensionDate} onChange={(_, d) => updateFilters({
        ...filters,
        dimensionDate: d
      })} />}
      {isWeekly && <div className="">
        <StatsFilterWeekly hotelId={hotelId} dimensionDate={filters.dimensionDate} onChange={(v) => updateFilters({
          ...filters,
          dimensionDate: v
        })} />
      </div>}
      {isMonthly && <div className="">
        <StatsFilterMonthly hotelId={hotelId} dimensionDate={filters.dimensionDate} onChange={(v) => updateFilters({
          ...filters,
          dimensionDate: v
        })} />
      </div>}
    </div>
    {postStayConfig?.PostStayGroup?.map((group, index) => {
      return <div className="card mt-2" key={`group_id_${group?.id}_${index}`}>
        <div className="card-header">
          <h2 style={{margin: 0}}>{group?.labelFr}</h2>
        </div>
        <div className="card-body">
          <table className="table table-striped table-bordered">
            <tbody>
              {group?.PostStayGroupItem?.map((item, idxItem) => {
                if (item?.Question) {
                  const question = item?.Question
                  return <tr key={`item_${item?.id}`}>
                    <td style={{ verticalAlign: "middle" }}>{question?.labelFr}</td>
                    <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                      <PostStayAnswerStatsDisplay questionId={question?.id} filters={filters} />
                    </td>
                  </tr>
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    })}
  </>

}