import { useMutation } from "@apollo/react-hooks";
import { URL_BACKOFFICE_BILLING_TOKEN_EDIT } from "constant/url";
import tokenAddGql from "graphql/BILLING/Token/mutation/tokenAdd.gql";
import { formatErrorResponseForJoi } from "helpers/Apollo";
import { Token, TokenInput } from "model";
import { useState } from "react";
import { TokenForm } from "../Form/TokenForm";
import React from "react";
import { IconLoading } from "@zipou/front_tools";


export type TokenAddProps = {
  onDone: (token: Token) => void,
}

export const TokenAdd = ({ onDone }: TokenAddProps) => {

  const [mutate] = useMutation<{ token: Token }>(tokenAddGql)
  const [errors, updateErrors] = useState<any>([])
  const [token, updateToken] = useState<TokenInput>()
  const [loading, updateLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    updateLoading(true);
    return mutate({
      variables: {
        input: token
      }
    })
      .then((response) => {
        onDone && onDone(response.data.token)
        // updateLoading(false)
        // const tokenId = response?.data?.token?.id
        // history.push(URL_BACKOFFICE_BILLING_TOKEN_EDIT?.replace(":id", tokenId))
      })
      .catch((e: any) => {
        updateErrors(formatErrorResponseForJoi(e))
        updateLoading(false)
      })
  }

  return <div>
    <TokenForm token={token} onChange={token => updateToken(token)} errors={errors} />
    <div>
      <button disabled={loading} className="btn btn-sm btn-dark" onClick={() => onSubmit()}>
        {loading && <IconLoading />}
        Ajouter
      </button>
    </div>
  </div>


}