import React from "react"

import { PaymentConfirm } from "components/front/BILLING/Payment/Confirm/PaymentConfirm";
import { CgvDisplay } from "components/front/PMS/Hotel/CGV/CgvDisplay";
import { Hotel, PropositionRow, PropositionRowPaymentConditionEnum } from "model";
import { Trans, useTranslation } from "react-i18next";

type PropositionPublicValidateProps = {
  proposition: any,
  onCancelSelection: () => void,
  onSelect: () => void,
}

export const PropositionPublicValidate = (props: PropositionPublicValidateProps) => {


  const { t, i18n } = useTranslation('translation', { keyPrefix: 'proposition' });

  const isFrench = i18n.language === "fr"
  const proposition = props?.proposition;
  const hotel: Hotel = proposition?.Hotel

  const rowSelected = proposition?.propositionRow?.find((el: any) => el?.id === proposition?.rowSelectedId)

  const hasCGV = !!(hotel?.CGVEnFile && hotel.CGVFrFile)
  const CGVUrl = hasCGV && (isFrench ? hotel?.CGVFrFile?.url : hotel?.CGVEnFile?.url)


  const translatePaymentCondition = (paymentCondition?: PropositionRowPaymentConditionEnum | string) => {


    switch (paymentCondition) {
      default: {
        const value = `${paymentCondition.split("_")[1]}%`
        const lib = (condition: string) => t("amountToValidate", { condition })
        return lib(value)
      }

      case PropositionRowPaymentConditionEnum.CONDITION_PRE_AUTH: {
        return t("amountToValidatePreAuth")
      }

    }

  }

  const renderCondition = (rowSelected: PropositionRow) => {

    const paymentCondition = rowSelected?.paymentCondition

    switch (paymentCondition) {
      case PropositionRowPaymentConditionEnum.CONDITION_PRE_AUTH:
      case PropositionRowPaymentConditionEnum.CONDITION_100:
      case PropositionRowPaymentConditionEnum.CONDITION_50:
      case PropositionRowPaymentConditionEnum.CONDITION_30:
      case PropositionRowPaymentConditionEnum.CONDITION_20:
      case PropositionRowPaymentConditionEnum.CONDITION_15:
      case PropositionRowPaymentConditionEnum.CONDITION_10: {
        return <div>
          <span className="text-blue-h8">{translatePaymentCondition(paymentCondition)}</span>
          <div style={{ paddingBottom: 15 }}>
            <PaymentConfirm payment={proposition?.Payment} onCancel={props?.onCancelSelection} newWindow={true} />
          </div>
          {hasCGV && <Trans i18nKey={"proposition.proposalCGU"} components={{ a: <CgvDisplay hotel={hotel} /> }} />}
        </div>
      }

      case PropositionRowPaymentConditionEnum.CONDITION_0:
      default: {
        return <div>
          <span>Afin de valider votre réservation, merci de cliquer sur le bouton ci-dessous.</span>
          {hasCGV && <p>En validant, vous acceptez les <a target="_blank" href={CGVUrl} rel="noreferrer"><span style={{ fontStyle: "italic" }}>conditions générales de ventes</span></a></p>}
          <div style={{ display: "flex", justifyContent: 'center', paddingTop: 10 }}>
            <button className="btn btn-secondary" onClick={() => props?.onCancelSelection()} style={{ marginLeft: 10 }}>Annuler</button>
            <button className="btn btn-blue-h8" onClick={() => props?.onSelect()} style={{ marginLeft: 10 }}>Confirmer</button>
          </div>
        </div>
      }
    }
  }


  return <div className="">
    <div className="">
      {renderCondition(rowSelected)}
    </div>
  </div>
}