import React, {useState} from "react"
import {Event} from "model"
import {EventBadge} from "../Badge/EventBadge"
import {Modal} from "components/common/Navigation/Modal/Modal"
import {EventEdit} from "../Edit/EventEdit"
import {useQuery} from "@apollo/react-hooks"
import reservationListEventGql from "graphql/PMS/Reservation/query/reservationListEvent.gql"
import {EventAddByReservation} from "../Add/EventAddByReservation"

type EventListForReservationProps = {
  reservationId: string,
  hotelId: string,
}


export const EventListForReservation = (props: EventListForReservationProps) => {

  const [focusEdit, updateFocusEdit] = useState<Event>()
  const [focusAdd, updateFocusAdd] = useState<boolean>()

  const {data, refetch} = useQuery(reservationListEventGql, {
    variables: {
      reservationId: props?.reservationId,
    },
    skip: !props?.reservationId,
    fetchPolicy: "cache-and-network",
  })

  const eventList: Event[] = data?.list
  const hasEvent = eventList?.length > 0

  return <div>
    <Modal display={!!focusEdit} onClose={() => updateFocusEdit(null)}>
      <EventEdit eventId={focusEdit?.id} onDone={() => {
        updateFocusEdit(null)
        refetch() // IN case of a deletion
      }} />
    </Modal>
    <Modal display={!!focusAdd} onClose={() => updateFocusAdd(null)}>
      <EventAddByReservation reservationId={props?.reservationId} hotelId={props?.hotelId} onDone={() => {
          refetch()
          updateFocusAdd(null)
      }} />
    </Modal>
    {!hasEvent && <div className="alert alert-secondary">Aucun service demandé</div>}
    {hasEvent && <table className="table">
      <thead>
        {eventList?.map(event => {
          return <tr className="">
            <td>{event?.Service?.name}</td>
            <td><EventBadge event={event} /></td>
            <td>
              <button className="btn btn-sm btn-dark" onClick={() => updateFocusEdit(event)}>
                <span className="icon-edit"></span>
              </button>
            </td>
          </tr>
        })}
      </thead>
    </table>}
    <div>
      <button className="btn btn-sm btn-info" onClick={() => updateFocusAdd(true)}>Ajouter un service</button>
    </div>
  </div>
}