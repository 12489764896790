import gql from "graphql-tag";

export default gql`

fragment CameraEditFragment on Camera {
  id
  name
  description
  username
  password
  host
  path
  port
  protocol
  retentionDays
  streamingType
  codec
}
`