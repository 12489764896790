import { DateFormat } from "components/common/Format/DateFormat"
import React, { useEffect } from "react"
// import {RoomDetails} from "components/Pms/Add/PropositionAdd"
import { PriceRate, PropositionRowRoomNightInput, Room } from "model"
import { useQuery } from "@apollo/react-hooks"
import priceRateListByCategoryGql from "graphql/PMS/PriceRate/query/priceRateListByCategory.gql"
import { RoomDetails } from "../../Room/Details/RoomDetails"
import roomGetGql from "graphql/PMS/Room/query/roomGet.gql"
import priceRateGetGql from "graphql/PMS/PriceRate/query/priceRateGet.gql"

type ItemPriceSelectProps = {
  // priceRatelist: any[],
  hotelId: string,
  item: PropositionRowRoomNightInput,
  isError: boolean,
  date: Date,
  isWarning?: boolean,
  nbAdults: number,
  nbChildren: number,
  nbSpecial: number,
  onChange: (data: string, priceRate: PriceRate) => void,
  onDelete?: (item: PropositionRowRoomNightInput) => void,
  canDelete?: boolean,
  targetTypeId?: string,
}

export const ItemPriceSelect = (props: ItemPriceSelectProps) => {

  const onChange = (id: string) => {
    const priceRate = priceRateList?.find(el => el.id === id)
    props?.onChange(id, priceRate)
    // console.log("PRICERAT", priceRate)
  }


  const { data: roomData } = useQuery<{ room: Room }>(roomGetGql, {
    variables: {
      id: props?.item?.roomId,
    },
    skip: !props?.item?.roomId,
  })

  const room = roomData?.room

  const { data } = useQuery<{ list: PriceRate[] }>(priceRateListByCategoryGql, {
    variables: {
      hotelId: props?.hotelId,
      date: props?.date,
      roomTypeId: room?.roomTypeId,
      nbAdults: props?.nbAdults,
      nbChildren: props?.nbChildren,
      nbSpecial: props?.nbSpecial,
    },
    skip: !room?.roomTypeId,
  })

  const item = props?.item
  const priceRateList = data?.list || []
  // const priceRateList = props?.priceRatelist || []

  const { data: priceRateData } = useQuery<{ priceRate: PriceRate }>(priceRateGetGql, {
    variables: {
      id: item?.rateId,
    },
    skip: !item?.rateId,
  })


  const priceRateMapFilteredByLatest = priceRateList?.reduce<{ [K in string]: PriceRate }>((acc, priceRate, index) => {
    // const type = priceRate.
    if (acc[priceRate.PriceRateType?.id]) {
      const accCreationDate = acc[priceRate.PriceRateType?.id].creationDate && new Date(acc[priceRate.PriceRateType?.id].creationDate)
      const priceRateDate = priceRate.creationDate && new Date(priceRate.creationDate)
      if (accCreationDate.getTime() < priceRateDate.getTime()) {
        return {
          ...acc,
          [priceRate.PriceRateType?.id]: priceRate,
        }
      } else {
        return acc
      }
    } else {
      return {
        ...acc,
        [priceRate.PriceRateType?.id]: priceRate,
      }
    }
  }, {})

  const priceRateListFilteredByLatest = Object.values(priceRateMapFilteredByLatest)
  const valueInList = priceRateListFilteredByLatest?.find((el => el.id === item.rateId))

  // if (!valueInList && priceRateData?.priceRate) {
  //   console.log("Old Price rate not in value, pushing it...")
  //   priceRateListFilteredByLatest.push(priceRateData?.priceRate)
  // }

  const oldPriceRateNotInList = !valueInList && priceRateData?.priceRate

  const priceRateListFilteredByConfig = priceRateListFilteredByLatest?.filter((el) => el?.PriceRateType?.canBeSelectedInProposition)
  const giftBoxList = priceRateListFilteredByConfig?.filter((el) => el.PriceRateType?.isGiftBox)
  const otherList = priceRateListFilteredByConfig?.filter((el) => !el.PriceRateType?.isGiftBox)

  const hasGiftBox = giftBoxList?.length > 0


  useEffect(() => {
    if (!item?.rateId && props?.targetTypeId && priceRateList?.length > 0) {
      const target = priceRateListFilteredByLatest?.find((el) => el.PriceRateType?.id === props?.targetTypeId)
      if (target) {
        // console.log("target found for date", new Date(item.dateStamp))
        onChange(target.id)
      } else {
        // console.log("target not found for date", new Date(item.dateStamp))
      }
    }
  }, [props?.targetTypeId, priceRateList])



  return <div className="input-group">
    <div className="input-group-prepend">
      <span className="input-group-text"><RoomDetails roomId={item?.roomId} /> - <DateFormat value={new Date(item.dateStamp)} /></span>
    </div>
    <select className="form-control" style={{ minWidth: 100 }} value={item?.rateId || ""} onChange={(e: any) => onChange(e.target.value)}>
      <option value="" disabled>Choisissez un code tarif</option>
      <optgroup label="Standard">
        {otherList?.map((priceRate) => {
          return <option key={`priceRate_${priceRate?.id}`} value={priceRate?.id}>{priceRate?.code} - {priceRate?.valueIncTax} - {new Date(priceRate?.creationDate)?.toLocaleDateString('fr-FR')}</option>
        })}
      </optgroup>
      {hasGiftBox && <optgroup label="Coffrets">
        {giftBoxList?.map((priceRate) => {
          return <option key={`priceRate_${priceRate?.id}`} value={priceRate?.id}>{priceRate?.code} - {priceRate?.valueIncTax}</option>
        })}
      </optgroup>}
      {oldPriceRateNotInList && <optgroup label="Ancien Code Tarif">
      <option value={priceRateData?.priceRate?.id}>{priceRateData?.priceRate?.code} - {priceRateData?.priceRate?.valueIncTax} - {new Date(priceRateData?.priceRate?.creationDate)?.toLocaleDateString('fr-FR')}</option>
      </optgroup>}
      {/* {priceRateList?.map((priceRate: any) => {
        return <option key={`priceRate_${priceRate?.id}`} value={priceRate?.id}>{priceRate?.code} - {priceRate?.valueIncTax}</option>
      })} */}
    </select>
    {/* <input type="text" className="form-control d-none d-sm-flex" disabled={true} value={priceRateSelected?.valueIncTax || ""} placeholder="Prix"></input> */}
    {props?.isError && <span className="input-group-text"><span className="icon-warning-sign"></span></span>}
    {props?.isWarning && <span className="input-group-text"><span className="icon-warning"></span></span>}
    {(props?.onDelete && props?.canDelete) && <span className="input-group-text" onClick={() => props?.onDelete(item)}><span className="icon-trash"></span></span>}
  </div>


}