import React, {useState} from "react"
// import {ringingContext} from "components/common/CTI/CTIAgent"
import {useQuery} from "@apollo/client"
import customerGetByPhoneNumber from "graphql/CRM/Customer/query/customerGetByPhoneNumber"
import {Modal} from "components/common/Navigation/Modal/Modal"
import {CustomerDetails} from "../Details/CustomerDetails"

type CustomerCTIWidgetProps = {
  phoneNumber: string,
  isRinging: boolean,
}

export const CustomerCTIWidget = (props: CustomerCTIWidgetProps) => {

  const [showDetails, updateShowDetails] = useState<boolean>(false)
  
  const {data} = useQuery(customerGetByPhoneNumber, {
    variables: {
      phoneNumber: props?.phoneNumber
    },
    skip: !props?.phoneNumber || props?.phoneNumber?.length <= 4,
  })


  const customer = data?.customer
  const isCustomer = !!customer
  const isRinging = props?.isRinging

  return <div>
    <Modal display={showDetails} onClose={() => updateShowDetails(false)}>
      <CustomerDetails customerId={customer?.id} showProposition={true} showReservation={true} showContacts={false} />
    </Modal>
    {isRinging && <div className="card border-danger">
      <div className="card-header bg-danger text-white">
        <span className="icon-phone" /> Appel en cours
      </div>
      <div className="card-body" style={{display: "flex", flexDirection: "column"}}>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th>{props?.phoneNumber}</th>
            </tr>
            <tr>
              <th>{customer?.firstName} {customer?.lastName?.toUpperCase()}</th>
            </tr>
            <tr>
              {isCustomer && <td><button className="btn btn-warning" disabled={showDetails} onClick={() => updateShowDetails(true)}>Plus de détails</button></td>}
            </tr>
          </tbody>
        </table>
      </div>
    </div >}
  </div>
}