import { useQuery } from "@apollo/react-hooks"
import { DateFormat } from "@zipou/front_tools"
import newsListGql from "graphql/APP/News/query/newsList.gql"
import { NewsListResult } from "model"
import React from "react"
// import { useState } from "react"

export const NewsWidget = () => {


  // const [cursor, updateCursor] = useState<CursorInput>({
  //   limit: 3,
  //   page: 1,
  // })

  const { data } = useQuery<{ list: NewsListResult }>(newsListGql, {
    variables: {
      cursor: {
        limit: 3,
        page: 1,

      }
    }
  })

  const newsList = data?.list?.nodes
  const hasNews = newsList?.length > 0

  return hasNews ? <div className="card border-blue-h8">
    <div className="card-header bg-blue-h8 text-white border-blue-h8">
      News
    </div>
    <div className="card-body border-blue-h8">
      {newsList?.map((news) => {
        return <div key={`news_${news.id}`} className="mt-2">
          <div className="card border-info">
            <div className="card-header bg-info text-white">
              {news?.title} <DateFormat value={new Date(news?.date)} />

            </div>
            <div className="card-body border-blue-h8">
              <div dangerouslySetInnerHTML={{ __html: news.contentHTML }} />
            </div>
          </div>
        </div>
      })}
    </div>
  </div> : <div />

}