import gql from "graphql-tag";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

${HotelDisplayFragment}

fragment CameraListFragment on Camera {
  id
  name
  codec
  Runner {
    id
    status
    statusExpected
  }
  VideoConfig {
    id
    Hotel {
      ...HotelDisplayFragment
    }  
  }
}
`