import { Modal } from 'components/common/Navigation/Modal/Modal'
import { HotelListSelect } from 'components/front/PMS/Hotel/List/HotelListSelect'
import { ReservationGroup, ReservationStatusEnum } from 'model'
import React, { useState } from 'react'
import { ReservationButton } from '../../Button/ReservationButton'
import { ReservationSearchInput } from '../../Input/ReservationSearchInput'
import { ReservationStatus } from '../../Status/ReservationStatus'
import { ContactSearchField } from 'components/front/PMS/Contact/Field/ContactSearchField'

type ReservationGroupFormProps = {
  reservationGroup: ReservationGroup,
  errors: any,
  onChange: (v: any) => void,
}

export const ReservationGroupForm = (props: ReservationGroupFormProps) => {

  const [focusAdd, updateFocusAdd] = useState<any>()

  const onChange = (v: any) => {
    props?.onChange(v)
  }


  const reservationGroup = props?.reservationGroup
  const hotelId = reservationGroup?.hotelId

  const errors = props?.errors

  return <div>
    <Modal display={focusAdd} onClose={() => updateFocusAdd(false)}>
      <ReservationSearchInput label={'Ajouter une reservation au groupe'} hotelId={hotelId} onChange={(id, value) => {
        updateFocusAdd(false)
        onChange({
          ...reservationGroup,
          ReservationGroupLink: [
            ...(reservationGroup?.ReservationGroupLink || []),
            { id: `toto_${new Date().getTime()}`, reservationId: id, Reservation: value },
          ]
        })
      }} />
    </Modal>

    <HotelListSelect isError={errors?.hotelId} disabled={!!reservationGroup?.hotelId} label={"Hotel"} hotelId={hotelId} onChange={hotelId => {
      onChange({
        ...reservationGroup,
        hotelId,
      })
    }} />


    <ContactSearchField isError={errors?.contactId} value={reservationGroup?.contactId} hotelId={hotelId} label={"Contact"} onChange={contactId => {
      onChange({
        ...reservationGroup,
        contactId,
      })
    }} />

    {/* <DateInput value={reservationGroup?.arrivalDate} label='Date Arrivée' isError={errors?.arrivalDate} onChange={(_, v) => {
      onChange({
        ...reservationGroup,
        arrivalDate: v,
      })
    }}
    />

    <DateInput value={reservationGroup?.departureDate} label='Date Départ' isError={errors?.departureDate} onChange={(_, v) => {
      onChange({
        ...reservationGroup,
        departureDate: v,
      })
    }}
    /> */}

    <div className='input-group'>
      <span className="input-group-text">Numéros de Résa</span>
      <div className="form-control" style={{ height: "100%" }}>
        {errors?.ReservationGroupLink && <div className="alert alert-danger">Vous devez saisir au moins deux reservations pour valider le groupe</div>}
        <table className='table table-bordered table-stripped'>
          <tbody>
            {reservationGroup?.ReservationGroupLink?.map((resaGroupLink, index) => {

              const { Reservation } = resaGroupLink

              return <tr className='ml-2' key={`resa_button_${resaGroupLink?.id}_${index}`}>
                <td><ReservationButton reservationId={Reservation?.id} pmsId={Reservation?.pmsId} /></td>
                <td><ReservationStatus value={Reservation?.status as ReservationStatusEnum} /></td>
                <td>
                  <button className='btn btn-danger btn-sm' onClick={() => onChange({
                    ...reservationGroup,
                    ReservationGroupLink: reservationGroup?.ReservationGroupLink?.filter((rgl) => rgl?.id !== resaGroupLink?.id)
                  })}>
                    <span className="icon-trash"></span>
                  </button>
                </td>
              </tr>
            })}
          </tbody>
        </table>
        <button className="btn btn-info btn-sm" onClick={() => updateFocusAdd(true)}>Ajouter une reservation au groupe</button>
      </div>
      {errors?.ReservationGroupLink && <span className="input-group-text">
        <span className='icon-warning-sign' />
      </span>}
    </div>

  </div>

}