import gql from "graphql-tag";
import ContactFragment from "graphql/PMS/Contact/fragment/ContactFragment";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";
import ReservationGroupConfirmationListFragmentGql from "graphql/PMS/Reservation/Group/fragment/ReservationGroupConfirmationListFragment.gql";

export default gql`

${HotelDisplayFragment}
${ContactFragment}
${ReservationGroupConfirmationListFragmentGql}

fragment PostStayListFragment on PostStay {
  id
  sendStatus
  status
  creationDate
  publicId
  sendDateTarget
  responseStatus
  Reservation {
    id
    pmsId
  }
  ReservationGroup {
    ...ReservationGroupConfirmationListFragment
  }
  Contact {
    ...ContactFragment
  }
  Hotel {
    ...HotelDisplayFragment
  }
}

`