import gql from "graphql-tag";
import CameraListFragmentGql from "../fragment/CameraListFragment.gql";

export default gql`

${CameraListFragmentGql}

query cameraListByUser {
  list: cameraListByUser {
    ...CameraListFragment
  }
}

`