import { DateFormat, IconSearch, IconTrash, Modal, PriceFormat } from "@zipou/front_tools"
import { Charge, ChargeStatusEnum } from "model"
import React, { useState } from "react"
import { ChargeStatus } from "../Status/ChargeStatus"
import { ChargeRefund } from "../Refund/ChargeRefund"

type ChargeListByTokenProps = {
  chargeList: Charge[]
}

export const ChargeListByToken = ({ chargeList }: ChargeListByTokenProps) => {

  const [displayRefund, updateDisplayRefund] = useState<Charge>()
  const hasCharges = chargeList?.length > 0

  return <div>
    {displayRefund && <Modal display>
      <ChargeRefund charge={displayRefund} onClose={() => updateDisplayRefund(null)} />
    </Modal>}
    <table className="table table-striped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <th className="bg-dark text-white">Date</th>
          <th className="bg-dark text-white">Montant</th>
          <th className="bg-dark text-white">Statut</th>
          <th className="bg-dark text-white">Actions</th>
        </tr>
      </thead>
      <tbody>
        {!hasCharges && <tr>
          <td colSpan={4}>
            <div className="alert alert-info">Aucun Paiement Enregistré</div>
          </td>
        </tr>}
        {chargeList?.map((charge) => {

          const canBeRefund = charge.status === ChargeStatusEnum.CHARGE_STATUS_COMPLETE

          return <tr className={`charge_${charge?.id}`}>
            <td>{charge?.creationDate && <DateFormat value={new Date(charge?.creationDate)} />}</td>
            <td><PriceFormat value={charge?.amount} /></td>
            <td><ChargeStatus charge={charge} /></td>
            <td>
              {false && <button className="btn btn-sm btn-warning ml-1">
                <IconSearch />
              </button>
              }
              {canBeRefund && <button className="btn btn-sm btn-secondary ml-1" onClick={() => updateDisplayRefund(charge)}>
                Rembourser
              </button>}
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </div>
}