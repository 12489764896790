import { useQuery } from "@apollo/client";
import React, { useContext, useState } from "react"

import reservationGet from "graphql/PMS/Reservation/query/reservationGet";
// import {ReservationAction} from "../Actions/ReservationActions";
import { DateFormat } from "components/common/Format/DateFormat";
import { ReservationStatus } from "../Status/ReservationStatus";

import "./style.scss"
import { Modal } from "components/common/Navigation/Modal/Modal";
import { ReservationFullDetails } from "./ReservationFullDetails";
import { userContext } from "context/User";
import { UserRoleEnum } from "model";

type ReservationDetailsProps = {
  reservationId: string,
  name?: string,
  status?: string,
  themeColor?: string,
  arrivalDate?: string,
  open?: boolean,
}

export const ReservationDetails = (props: ReservationDetailsProps) => {
  const { reservationId } = props;

  const [focusReservationId, updateFocusReservationId] = useState<any>()


  const [open, updateOpen] = useState(props?.open === null ? true : props?.open)

  const { data } = useQuery(reservationGet, {
    variables: {
      id: reservationId,
    },
    skip: !reservationId,
  })

  const reservation = data?.reservation
  const defaultRate = reservation?.ReservationRate && reservation?.ReservationRate[0]

  const user = useContext(userContext)
  const hasDebugRole = user.roles?.includes(UserRoleEnum.ROLE_DEBUG)

  return <div className="reservation-details-container">
    {focusReservationId && <Modal display={true} onClose={() => updateFocusReservationId(null)}>
      <ReservationFullDetails reservationId={focusReservationId} />
    </Modal>}
    <div className="card">
      <div className="card-header text-white" style={{ backgroundColor: `#${props?.themeColor}` }} onClick={() => updateOpen(!open)}>
        {props?.name && <span>{props?.name}</span>}
        <span>{props?.arrivalDate && <DateFormat value={new Date(props?.arrivalDate)} />}</span>
        <ReservationStatus value={props?.status} />
      </div>
      <div className={open ? "collapse-visible-reservation-details collapse-container-reservation-details" : "collapse-container-reservation-details"}>
        <div className="card-body">
          <table className="table">
            <tbody>
              {hasDebugRole && <tr>
                <th>Id</th>
                <td>
                  {reservation?.id}
                </td>
              </tr>}
              <tr>
                <th>Résa</th>
                <td>
                  <button className="btn btn-sm btn-dark mr-2" onClick={() => updateFocusReservationId(reservation?.id)}>#{reservation?.pmsId}</button>
                  <ReservationStatus value={reservation?.status} />
                </td>
              </tr>
              <tr>
                <th>Chambre & Package</th>
                <td>{reservation?.ReservationRoom?.Room?.RoomType?.labelFr} / {defaultRate?.PriceRateType?.labelFr}</td>
              </tr>
              <tr>
                <th>Date</th>
                <td>
                  <DateFormat value={new Date(reservation?.arrivalDate)} /> - <DateFormat value={new Date(reservation?.departureDate)} /> - {reservation?.nbNight} Nuit(s)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <ReservationAction reservationId={reservation?.id} /> */}
    </div>
  </div>
}
