import { PostStayConfigInput, PostStayGroupInput, Template } from 'model'
import React, { useState } from 'react'
import { PostStayGroupForm } from '../../Group/Form/PostStayGroupForm'
import { BooleanInput, Collapse, IconArrowDown, IconArrowUp, IconTrash, Modal, NumberInput, SelectInput } from '@zipou/front_tools'
import templateListQuery from "graphql/APP/Template/query/templateList"
import { useQuery } from '@apollo/react-hooks'
import { JSONImportForm } from 'components/front/APP/Template/Form/JSONImportForm'
import { ClickAndCopy } from 'components/common/Form/ClickAndCopy'

type PostStayConfigFormProps = {
  errors: any,
  hotelId: string,
  postStayConfig: PostStayConfigInput,
  onChange: (postStayConfig: PostStayConfigInput) => void,
}

export const PostStayConfigForm = ({ hotelId, postStayConfig, onChange, errors }: PostStayConfigFormProps) => {


  const { data } = useQuery<{ list: Template[] }>(templateListQuery, {
    variables: {
      hotelId,
    }
  })


  const [focusExport, updateFocusExport] = useState<boolean>()
  const [focusImport, updateFocusImport] = useState<boolean>()
  const [collapsed, updateCollapsed] = useState<{ [k: number]: boolean }>({})

  const postStayGroupList = postStayConfig?.PostStayGroup

  const onMove = (index: number, up: boolean) => {

    //Interverti juste les deux elements dans l'array et update le state
    const elementToMove = postStayGroupList[index]
    const backup = postStayGroupList[index - (1 * (up ? 1 : -1))]
    postStayGroupList[index - (1 * (up ? 1 : -1))] = elementToMove
    postStayGroupList[index] = backup

    onChange({
      ...postStayConfig,
      PostStayGroup: postStayGroupList
    })

  }

  const onCollapseAll = (collapse: boolean) => {
    return postStayGroupList.map((_, index) => {
      return updateCollapsed((oldState) => ({
        ...oldState,
        [index]: collapse,
      }))
    })

  }

  const templateList = data?.list

  const filterPostGroup = (postGroupList: PostStayGroupInput[]): PostStayGroupInput[] => {
    return postGroupList?.map(postGroup => {
      const { id, ...rest } = postGroup
      return {
        ...rest,
        PostStayGroupItem: rest?.PostStayGroupItem?.map(item => {
          const { id: itemId, ...restItem } = item
          const { id: questionId, ...restQuestion } = item?.Question || {}
          const { id: separatorId, ...restSeparator } = item?.Separator || {}
          return {
            ...restItem,
            Question: restQuestion,
            Separator: restSeparator,
          }
        })
      }

    })
  }

  return <div>
    {focusImport && <Modal title='Importer' display onClose={() => updateFocusImport(false)}>
      <JSONImportForm onChange={(value) => {
        onChange({
          ...postStayConfig,
          PostStayGroup: value,
        })
      }} />
    </Modal>}
    {focusExport && <Modal title='Exporter' display onClose={() => updateFocusExport(false)}>
      <div className="input-group">
        <span className="input-group-text">Exporter</span>
        <textarea className="form-control" disabled>
          {postStayConfig?.PostStayGroup && JSON.stringify(filterPostGroup(postStayConfig?.PostStayGroup))}
        </textarea>
        <ClickAndCopy value={postStayConfig?.PostStayGroup && JSON.stringify(filterPostGroup(postStayConfig?.PostStayGroup))} />
      </div>
    </Modal>}
    <BooleanInput id={"postStayActive"} value={postStayConfig?.postStayActive} label='Post Stay Actif ?' errors={errors} onChange={(v) => {
      onChange({
        ...postStayConfig,
        postStayActive: v,
      })
    }} />


    <NumberInput id={"sendHour"} value={`${postStayConfig?.sendHour}` || ""} label="Heure d'envoi" errors={errors} onChange={(e, v) => {
      onChange({
        ...postStayConfig,
        sendHour: v,
      })
    }} />


    <NumberInput id={"alertThreshold"} value={`${postStayConfig?.alertThreshold}` || ""} label="Seuil d'Alerte" errors={errors} onChange={(e, v) => {
      onChange({
        ...postStayConfig,
        alertThreshold: v,
      })
    }} />

    <BooleanInput id={"demoMode"} value={postStayConfig?.demoMode} label='demoMode ?' errors={errors} onChange={(v) => {
      onChange({
        ...postStayConfig,
        demoMode: v,
      })
    }} />

    <div className="input-group">
      <span className='input-group-text'>Copies des envois</span>
      <div className="form-control" style={{ height: "100%" }}>
        {postStayConfig?.dest?.map((dest: string, index: number) => {
          return <div className="input-group" key={`postStayConfig_dest_${index}`}>
            <span className="input-group-text">Email</span>
            <input className="form-control" type="text" value={dest} onChange={(e: any) => {
              onChange({
                ...postStayConfig,
                dest: postStayConfig.dest?.map((el: any, id: number) => (id === index) ? e.target.value : el)
              })
            }} />
            <span className="input-group-text">
              <span className="icon-close" onClick={() => {
                onChange({
                  ...postStayConfig,
                  dest: postStayConfig?.dest?.filter((el: any, id: number) => id !== index)
                })
              }}></span>
            </span>

          </div>
        })}
        <div className="">
          <button className="btn btn-dark btn-sm" onClick={() => {
            onChange({
              ...postStayConfig,
              dest: [
                ...(postStayConfig?.dest ? postStayConfig?.dest : []),
                ""
              ],
            })
          }}>Ajouter</button>
        </div>
      </div>
    </div>

    <NumberInput id={"nbDaysAfterDeparture"} value={`${postStayConfig?.nbDaysAfterDeparture}` || ""} label='Nombre de Jours après Départ' errors={errors} onChange={(e, v) => {
      onChange({
        ...postStayConfig,
        nbDaysAfterDeparture: Number(v),
      })
    }} />


    <SelectInput id={"templateId"} value={postStayConfig?.templateId} choiceList={templateList?.map(template => ({ id: template.id, label: template.name }))} label='Template' errors={errors} onChange={v => {
      onChange({
        ...postStayConfig,
        templateId: v,
      })
    }} />

    <div className='card'>
      <div className='card-header'>Questions</div>
      <div className='card-body'>
        <div className='pb-3'>
          <button className='btn btn-sm btn-warning m-1' onClick={() => onCollapseAll(true)}>Plier Tout</button>
          <button className='btn btn-sm btn-info m-1' onClick={() => onCollapseAll(false)}>Déplier Tout</button>
        </div>


        {postStayGroupList?.map((postStayGroup, index) => {

          const canMoveUp = index > 0
          const canMoveDown = index < postStayGroupList?.length - 1

          return <div key={`pstay_group_${postStayGroup?.id}_${index}`}>
            <div className="card">
              <div className="card-header bg-dark text-white">
                {canMoveUp && <button className='btn btn-secondary btn-sm mr-4' onClick={() => onMove(index, true)}>
                  <IconArrowUp />
                </button>}
                <button className='btn btn-sm btn-secondary' onClick={() => updateCollapsed({ ...collapsed, [index]: !collapsed[index] })}>#{index + 1} {postStayGroup.labelFr}</button>
                {canMoveDown && <button className='btn btn-secondary btn-sm ml-4' onClick={() => onMove(index, false)}>
                  <IconArrowDown />
                </button>}
              </div>
              <Collapse collapsed={collapsed[index]}>
                <div className="card-body">
                  <div className='input-group'>
                    <div className="form-control" style={{ height: "100%" }}>

                      <PostStayGroupForm postStayGroup={postStayGroup} errors={errors && errors?.PostStayGroup && errors?.PostStayGroup[index]} onChange={(postStayGroup) => {
                        onChange({
                          ...postStayConfig,
                          PostStayGroup: postStayGroupList?.map((psg, idx) => {
                            return (idx === index) ? postStayGroup : psg
                          })
                        })
                      }} />
                    </div>
                    <span className='input-group-text' onClick={() => {
                      onChange({
                        ...postStayConfig,
                        PostStayGroup: postStayGroupList?.filter((psg, idx) => {
                          return idx !== index
                        })
                      })
                    }}>
                      <IconTrash />
                    </span>
                  </div>
                </div>
              </Collapse>
            </div>

          </div>
        })}


      </div>
      <div className='card-footer'>
        <button className='btn btn-sm btn-dark' onClick={() => {
          onChange({
            ...postStayConfig,
            PostStayGroup: [
              ...(postStayGroupList || []),
              {},
            ]
          })
        }}>Ajouter un groupe de question</button>

        <button className='btn btn-warning btn-sm ml-1' onClick={() => updateFocusExport(true)}>Exporter les questions</button>
        <button className='btn btn-primary btn-sm ml-1' onClick={() => updateFocusImport(true)}>Importer les questions</button>

      </div>
    </div>
  </div>


}