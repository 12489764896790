import {ApolloClient, InMemoryCache, ApolloLink, HttpLink, Context} from '@apollo/client';
// import {onError} from "@apollo/client/link/error";

import {split} from 'apollo-link';
// import {ApolloLink} from 'apollo-link';
// import {HttpLink} from 'apollo-link-http';
// import {InMemoryCache} from 'apollo-cache-inmemory'
import {WebSocketLink} from 'apollo-link-ws';
import {createBrowserHistory} from 'history'
// import {findToken} from './User';
import {getMainDefinition} from 'apollo-utilities';


export const history = createBrowserHistory();

export const getClient = (token? : string) => {

  const wssURi = process.env.NODE_ENV === "development" ? `ws://pms.localhost:4500/subscriptions` : `${((window.location.protocol === "https:") ? "wss://" : "ws://")}${window.location.host}${process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_PATH}`
  console.log("wssURi", wssURi)
  const wsLink = new WebSocketLink({
    uri: wssURi,
    options: {
      reconnect: true,
      connectionParams: {
        bearer: token
      },
    }
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    credentials: "same-origin",
  });

  const AuthLink = new ApolloLink((operation, next) => {
    operation.setContext((context: Context) => {
      const {loginToken} = context
      // const token = loginToken || findToken()
      return {
        ...context,
        headers: {
          ...context.headers,
          Authorization: `Bearer ${loginToken || token}`,
        }
      }
    });

    return next(operation);
  });

  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: any, value: any) => (key === '__typename' ? undefined : value);
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
      return data;
    });
  });


  const HttpLinkWithBearer = ApolloLink.from([
    // errorLink,
    cleanTypeName,
    AuthLink,
    httpLink,
  ]);



  const link = split(
    // split based on operation type
    ({query}) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    //@ts-ignore
    HttpLinkWithBearer,
  );

  const cache = new InMemoryCache({
    dataIdFromObject: (object: any) => {
      return object.id || null
    },
    // @ts-ignore
  }).restore(window.__APOLLO_STATE__);

  const apolloClient = new ApolloClient({
    //@ts-ignore
    link,
    cache,
  });
  return apolloClient
}

export const formatErrorResponseForJoi = (error: any) => {
  return error?.graphQLErrors && parseGraphqlServerError(error?.graphQLErrors[0]?.extensions?.exception?.details)
  // return error?.graphQLErrors[0]?.extensions?.exception?.details.map((detail: any) => ({...detail, field: detail.context.key})) || [error];
}


export const isFieldOnError = (errors: any, name: string) => {
  return errors && !!errors[name]
}

// export const isFieldOnError = (error: any[], name: string) => {
//   return error?.find((el: any) => el.field === name)
// }


export const parseGraphqlServerError = (details: any) => {

  /**
   * Flatten an array to deep object ([toto, tutu, tata] => { toto: {tutu: {tata: true}}})
   * @param Array path
   * @param Object global
   */
  const parsingFunction = (path: any[], global: any, message: string): any => {
    //End of the array, return field: true
    if (path.length === 1) {
      return {
        ...global,
        [path[0]]: message,
      }
    } else {
      const [key, ...rest] = path
      return {
        [key]: {
          ...(global && global[key]),
          ...parsingFunction(rest, global && global[key], message)
        }
      }
    }

  }

  /**
   * Looping on each error, details is an array of Joi Errors
   * Using reduce in order to keep a global acc and populate it with errors
   */
  const parseDetail = details && details.reduce((acc: any, element: any) => {
    const {path, message} = element
    return {...acc, ...parsingFunction(path, acc, message)}
  }, {})

  return parseDetail
}
