import { TextInput } from "@zipou/front_tools"
import { PmsConfigHotsoftInput } from "model"
import React from "react"



type PmsConfigHotsoftFormProps = {
  hotsoftConfig: PmsConfigHotsoftInput,
  errors: any,
  onChange: (hotsoftConfig: PmsConfigHotsoftInput) => void,
}

export const PmsConfigHotsoftForm = ({ hotsoftConfig, errors, onChange }: PmsConfigHotsoftFormProps) => {

  return <div>
    <TextInput id="providerKey" label="Provider Key" errors={errors} value={hotsoftConfig?.providerKey} onChange={(v) => {
      onChange({
        ...hotsoftConfig,
        providerKey: v,
      })
    }} />

    <TextInput id="hotelCode" label="Hotel Code" errors={errors} value={hotsoftConfig?.hotelCode} onChange={(v) => {
      onChange({
        ...hotsoftConfig,
        hotelCode: v,
      })
    }} />

    <TextInput id="endpoint" label="Endpoint" errors={errors} value={hotsoftConfig?.endpoint} onChange={(v) => {
      onChange({
        ...hotsoftConfig,
        endpoint: v,
      })
    }} />

    <TextInput id="user" label="User" errors={errors} value={hotsoftConfig?.user} onChange={(v) => {
      onChange({
        ...hotsoftConfig,
        user: v,
      })
    }} />

    <TextInput id="password" label="Password" errors={errors} value={hotsoftConfig?.password} onChange={(v) => {
      onChange({
        ...hotsoftConfig,
        password: v,
      })
    }} />

  </div>

}