import gql from "graphql-tag";
import UserConfigFragmentGql from "graphql/APP/User/Config/fragment/UserConfigFragment.gql";
import PhoneAgentConfigFragmentGql from "graphql/PHONE/Agent/Config/fragment/PhoneAgentConfigFragment.gql";
import PhoneSipConfigFragmentGql from "graphql/PHONE/Sip/fragment/PhoneSipConfigFragment.gql";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";

export default gql`

  ${HotelDisplayFragment}
  ${PhoneAgentConfigFragmentGql}
  ${PhoneSipConfigFragmentGql}
  ${UserConfigFragmentGql}

  fragment UserFragment on User {
    id
    email
    name
    corpId
    Corp {
      id
      name
    }
    isAdmin
    roles
    hotels
    configId
    impersonator
    Corp {
      id
      name
    }
    UserConfig {
      ...UserConfigFragment
    }
    Hotel {
      ...HotelDisplayFragment
    }
    PhoneAgentConfig {
      ...PhoneAgentConfigFragment
    }
    PhoneSipConfig {
      ...PhoneSipConfigFragment
    }
  }

`