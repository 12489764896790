import gql from "graphql-tag";

export default gql`

fragment CameraFragment on Camera {
  id
  name
  description
  username
  password
  host
  path
  port
  protocol
  streamingType
  codec
  Runner {
    id
    status
    statusExpected
  }
}
`