import React from 'react'

type CollapseProps = {
  collapsed: boolean,
  children: any,
}

export const Collapse = (props: CollapseProps) => {

  const isCollapsed = props?.collapsed

  return <div className="" style={{maxHeight: isCollapsed ? 0 : 30000, transition: "max-height 400ms ease", "overflow": "hidden"}}>
    {props?.children}
  </div>


}