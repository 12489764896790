import { gql, useQuery } from "@apollo/react-hooks"
import React from "react"
import { TokenStatus } from "./TokenStatus"
import { TokenStatusEnum } from "model"

export type TokenStatusFilterProps = {
  value: string[],
  onChange: (datas: string[]) => void
}


export const TokenStatusFilter = (props: TokenStatusFilterProps) => {


  const tokenList = Object.values(TokenStatusEnum)
  const { value, onChange } = props;

  return <div className="card">
    <div className="card-body">
      <ul className="list-group" >
        {tokenList?.map((item) => {
          return <li className="list-group-item d-flex justify-content-start align-items-center" >
            <input type="checkbox" id={item} checked={!!value?.includes(item)} onChange={(e: any) => {
              const newValue = e.target.checked ? [...(value || []), item] : value?.filter((el: string) => el !== item)
              onChange(newValue)
            }} />
            <label htmlFor={item} style={{ padding: "0 10px", margin: 0 }}>
              <TokenStatus status={item as TokenStatusEnum} />
            </label>
          </li>

        })}
      </ul>
    </div>
  </div>
}