import {useQuery} from "@apollo/react-hooks"
import {BooleanInput} from "components/common/Form/BooleanInput"
import {SelectInput} from "components/common/Form/SelectInput"
import {TextField} from "components/common/Form/TextField"
import {TextInput} from "components/common/Form/TextInput"
import {HotelListSelect} from "components/front/PMS/Hotel/List/HotelListSelect"
import priceRateCancelConditionListGql from "graphql/PMS/PriceRate/CancelCondition/query/priceRateCancelConditionList.gql"
import {isFieldOnError} from "helpers/Apollo"
import React, {useState} from "react"

import "./style.scss"

type PriceRateTypeFormProps = {
  open: boolean,
  hotelId: string,
  priceRateType: any,
  errors?: any,
  onChange: (data: any) => void,
}

export const PriceRateTypeForm = (props: PriceRateTypeFormProps) => {

  const {errors, hotelId, priceRateType} = props


  const isNew = !priceRateType?.code
  const [open, updateOpen] = useState(isNew || props?.open)


  const {data} = useQuery(priceRateCancelConditionListGql, {
    variables: {
      hotelId,
    }
  })

  const onChange = (data: any) => {
    return props?.onChange(data)
  }
  const hasErrors = errors && Object.keys(errors).length > 0

  return <div className="card price-rate-type-container">
    <div className={isNew ? "card-header bg-warning text-white" : "card-header"} onClick={() => updateOpen(!open)}>
      {hasErrors && <span className="icon-warning-sign"> </span>}
      {priceRateType?.code || "NOUVEAU CODE"}
    </div>
    <div className={open ? "collapse-container-price-rate-type collapse-visible-price-rate-type" : "collapse-container-price-rate-type"}>
      <div className="card-body">
        {/* {!hotelId && <HotelListSelect hotelId={priceRateType?.hotelId || ""} label={"Hotel"} onChange={(value: string) => {
          onChange({
            ...priceRateType,
            hotelId: value,
          })
        }} />} */}
        <TextInput value={priceRateType?.code} errors={props?.errors?.code} label="Code" onChange={(e: any) => {
          onChange({
            ...priceRateType,
            code: e.target.value,
          })
        }} />
        {/* <div className="input-group">
          <span className="input-group-text">Code</span>
          <input type="text" className="form-control" value={priceRateType?.code || ""} onChange={(e: any) => {
            onChange({
              ...priceRateType,
              code: e.target.value,
            })
          }} />
          {isFieldOnError(errors, "code") && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
        </div> */}
        <TextInput value={priceRateType?.labelFr} errors={props?.errors?.labelFr} label="Libelle Français" onChange={(e: any) => {
          onChange({
            ...priceRateType,
            labelFr: e.target.value,
          })
        }} rightLabel={<span className='badge badge-warning'>Visible par les clients</span>} />
        {/* <div className="input-group">
          <span className="input-group-text">labelFr</span>
          <input type="text" className="form-control" value={priceRateType?.labelFr || ""} onChange={(e: any) => {
            onChange({
              ...priceRateType,
              labelFr: e.target.value,
            })
          }} />
          {isFieldOnError(props?.errors, "labelFr") && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
        </div> */}
        <TextInput value={priceRateType?.labelEn} errors={props?.errors?.labelEn} label="Libelle Anglais" onChange={(e: any) => {
          onChange({
            ...priceRateType,
            labelEn: e.target.value,
          })
        }}  rightLabel={<span className='badge badge-warning'>Visible par les clients</span>} />
        {/* <div className="input-group">
          <span className="input-group-text">labelEn</span>
          <input type="text" className="form-control" value={priceRateType?.labelEn || ""} onChange={(e: any) => {
            onChange({
              ...priceRateType,
              labelEn: e.target.value,
            })
          }} />
          {isFieldOnError(props?.errors, "labelEn") && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
        </div> */}
        <BooleanInput value={priceRateType?.isBreakfastIncluded} errors={props?.errors?.isBreakfastIncluded} label="Petit Dejeuner compris ?" onChange={value => {
          onChange({
            ...priceRateType,
            isBreakfastIncluded: value,
          })
        }} />
        {/* <div className="input-group">
          <span className="input-group-text">isBreakfastIncluded</span>
          <select className="form-control" value={priceRateType?.isBreakfastIncluded ? "1" : "0"} onChange={(e: any) => {
            onChange({
              ...priceRateType,
              isBreakfastIncluded: (e.target.value === "1") ? true : false,
            })
          }}>
            <option value="" disabled>Choisissez</option>
            <option value="1">Oui</option>
            <option value="0">Non</option>
          </select>
          {isFieldOnError(props?.errors, "isBreakfastIncluded") && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
        </div> */}
        <BooleanInput value={priceRateType?.isRefundable} errors={props?.errors?.isRefundable} label="Remboursable ?" onChange={value => {
          onChange({
            ...priceRateType,
            isRefundable: value,
          })
        }} />
        {/* <div className="input-group">
          <span className="input-group-text">isRefundable</span>
          <select className="form-control" value={priceRateType?.isRefundable ? "1" : "0"} onChange={(e: any) => {
            onChange({
              ...priceRateType,
              isRefundable: (e.target.value === "1") ? true : false,
            })
          }}>
            <option value="" disabled>Choisissez</option>
            <option value="1">Oui</option>
            <option value="0">Non</option>
          </select>
          {isFieldOnError(props?.errors, "isRefundable") && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
        </div> */}
        <BooleanInput value={priceRateType?.isGiftBox} errors={props?.errors?.isGiftBox} label="Coffret Cadeau ?" onChange={value => {
          onChange({
            ...priceRateType,
            isGiftBox: value,
          })
        }} />
        {/* <div className="input-group">
          <span className="input-group-text">isGiftBox</span>
          <select className="form-control" value={priceRateType?.isGiftBox ? "1" : "0"} onChange={(e: any) => {
            onChange({
              ...priceRateType,
              isGiftBox: (e.target.value === "1") ? true : false,
            })
          }}>
            <option value="" disabled>Choisissez</option>
            <option value="1">Oui</option>
            <option value="0">Non</option>
          </select>
          {isFieldOnError(props?.errors, "isGiftBox") && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
        </div> */}
        <BooleanInput value={priceRateType?.isGroup} errors={props?.errors?.isGroup} label="Code Groupe ?" onChange={value => {
          onChange({
            ...priceRateType,
            isGroup: value,
          })
        }} />
        {/* <div className="input-group">
          <span className="input-group-text">isGroup</span>
          <select className="form-control" value={priceRateType?.isGroup === true ? "1" : priceRateType?.isGroup === false ? "0" : ""} onChange={(e: any) => {
            onChange({
              ...priceRateType,
              isGroup: (e.target.value === "1") ? true : false,
            })
          }}>
            <option value="" disabled>Choisissez</option>
            <option value="1">Oui</option>
            <option value="0">Non</option>
          </select>
          {isFieldOnError(props?.errors, "isGroup") && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
        </div> */}
        <BooleanInput label="Code Offert ?" value={priceRateType?.isFree} errors={props?.errors} onChange={v => {
          onChange({
            ...priceRateType,
            isFree: v,
          })
        }} />
        <BooleanInput label="Inclus dans le total des confirmations ?" value={priceRateType?.isIncludedInConfirmationTotal} errors={props?.errors} onChange={v => {
          onChange({
            ...priceRateType,
            isIncludedInConfirmationTotal: v,
          })
        }} />
        {/* <div className="input-group">
          <span className="input-group-text">isIncludedInConfirmationTotal</span>
          <select className="form-control" value={priceRateType?.isIncludedInConfirmationTotal ? "1" : "0"} onChange={(e: any) => {
            onChange({
              ...priceRateType,
              isIncludedInConfirmationTotal: (e.target.value === "1") ? true : false,
            })
          }}>
            <option value="" disabled>Choisissez</option>
            <option value="1">Oui</option>
            <option value="0">Non</option>
          </select>
          {isFieldOnError(props?.errors, "isIncludedInConfirmationTotal") && <span className='input-group-text'><span className="icon-warning-sign" /></span>}
        </div> */}

        <BooleanInput id={"hasSpa"} label="Spa Inclus ?" errors={props?.errors} value={priceRateType?.hasSpa} onChange={(value: boolean) => {
          onChange({
            ...priceRateType,
            hasSpa: value,
          })
        }} />

        <BooleanInput id={"hasFood"} label="Restauration Incluse ?" errors={props?.errors} value={priceRateType?.hasFood} onChange={(value: boolean) => {
          onChange({
            ...priceRateType,
            hasFood: value,
          })
        }} />

        <BooleanInput id={"hasDrink"} label="Boisson incluses ?" errors={props?.errors} value={priceRateType?.hasDrink} onChange={(value: boolean) => {
          onChange({
            ...priceRateType,
            hasDrink: value,
          })
        }} />

        {/* <BooleanInput id={"hasService"} label="hasService" errors={props?.errors} value={priceRateType?.hasService} onChange={(value: boolean) => {
          onChange({
            ...priceRateType,
            hasService: value,
          })
        }} /> */}

        <BooleanInput id={"canBeSelectedInProposition"} label="Disponible dans les propositions ?" errors={props?.errors} value={priceRateType?.canBeSelectedInProposition} onChange={(value: boolean) => {
          onChange({
            ...priceRateType,
            canBeSelectedInProposition: value,
          })
        }} />

        <SelectInput id='priceRateCancelConditionId' label={"Conditions d'annulation"} disabled={false} choiceList={data?.list?.map((el: any) => ({id: el.id, label: el.label}))} value={priceRateType?.priceRateCancelConditionId || ""} errors={props?.errors} onChange={(e: any) => {
          onChange({
            ...priceRateType,
            priceRateCancelConditionId: e.target.value,
          })
        }} />

        {/* <TextField isError={isFieldOnError(props?.errors, "conditionsFR")} value={priceRateType?.conditionsFR || ""} label={"conditionsFR"} onChange={(value: string) => {
          onChange({
            ...priceRateType,
            conditionsFR: value,
          })
        }} />
        <TextField isError={isFieldOnError(props?.errors, "conditionsEN")} value={priceRateType?.conditionsEN || ""} label={"conditionsEN"} onChange={(value: string) => {
          onChange({
            ...priceRateType,
            conditionsEN: value,
          })
        }} /> */}
        {/* <TextField isError={isFieldOnError(props?.errors, "conditionsCancelFR")} value={priceRateType?.conditionsCancelFR} label={"conditionsCancelFR"} onChange={(value: string) => {
          onChange({
            ...priceRateType,
            conditionsCancelFR: value,
          })
        }} />
        <TextField isError={isFieldOnError(props?.errors, "conditionsCancelEN")} value={priceRateType?.conditionsCancelEN} label={"conditionsCancelEN"} onChange={(value: string) => {
          onChange({
            ...priceRateType,
            conditionsCancelEN: value,
          })
        }} /> */}
      </div>
    </div>
  </div>

}
