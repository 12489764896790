import React, {useRef, useState} from "react"
import {useMutation, useQuery} from "@apollo/react-hooks"

import confirmationQuestionGetByConfirmationIdQuery from "graphql/PMS/Confirmation/Question/query/confirmationQuestionGetByConfirmationId"
import confirmationAnswerSaveMutation from "graphql/PMS/Confirmation/Answer/mutation/confirmationAnswerSave"
import confirmationCheckStatusMutation from "graphql/PMS/Confirmation/mutation/confirmationCheckStatus"

import './style.scss'
import confirmationGetQuery from "graphql/PMS/Confirmation/query/confirmationGet"
import {ReservationDetails} from "../../Reservation/Details/ReservationDetails"
import {DateTimeSelectInput} from "components/common/Form/DateTimeSelectInput"
import {DateTimeSelectFullInput} from "components/common/Form/DateTimeSelectFullInput"
import {TextInput} from "components/common/Form/TextInput"
import {NbPeopleInput} from "components/common/Form/NbPeopleInput"
import {Confirmation, ConfirmationQuestion, ConfirmationQuestionAnswerTypeEnum, Reservation} from "model"
import {ConfirmationAnswerService} from "./Service/ConfirmationAnswerService"
import { PriceInput } from "components/common/Form/PriceInput"

type ConfirmationAnswerProps = {
  confirmationId: string,
  onDone: () => void,
}

export const ConfirmationAnswer = (props: ConfirmationAnswerProps) => {

  const numberRef: any = useRef()

  const confirmationResponse = useQuery(confirmationGetQuery, {
    variables: {
      id: props?.confirmationId,
    },
    skip: !props?.confirmationId,
  })


  const confirmation: Confirmation = confirmationResponse?.data?.confirmation
  const reservation: Reservation = confirmation?.Reservation
  const reservationId = reservation?.id
  const reservationArrivalDate = reservation?.arrivalDate

  const {data, refetch} = useQuery(confirmationQuestionGetByConfirmationIdQuery, {
    variables: {
      confirmationId: props?.confirmationId,
    },
    skip: !props?.confirmationId
  })

  const [mutate] = useMutation(confirmationAnswerSaveMutation)
  const [updateMutation] = useMutation(confirmationCheckStatusMutation)

  const question: ConfirmationQuestion = data?.question
  // const hasQuestion = !!question

  const answerSave = (datas: any) => {
    return mutate({
      variables: {
        questionId: question?.id,
        confirmationId: props?.confirmationId,
        datas,
      },
      refetchQueries: [{
        query: confirmationQuestionGetByConfirmationIdQuery,
        variables: {
          confirmationId: props?.confirmationId,
        }
      }]
    })
      .then(() => {
        return updateMutation({
          variables: {
            id: props?.confirmationId,
          }
        })
      })
      .then((response: any) => {
        const confirmation = response?.data?.confirmation
        switch (confirmation?.status) {
          case "CONFIRMATION_STATUS_TO_VALID":
          case "CONFIRMATION_STATUS_TO_SEND": {
            props?.onDone()
            break;
          }
          case "CONFIRMATION_STATUS_TO_ANSWER": {
            refetch()
            break;
          }
        }
      })
  }

  const _renderForm = () => {

    const [dateTime, updateDateTime] = useState((reservationArrivalDate && new Date(reservationArrivalDate)) || new Date())
    const [hasBeenUpdated, updateHasBeenUpdated] = useState(false)
    const [amount, updateAmount] = useState(null)
    const [comment, updateComment] = useState(null)

    switch (question?.answerType) {
      case ConfirmationQuestionAnswerTypeEnum.TYPE_SERVICE: {
        return <div>
          <ConfirmationAnswerService confirmationId={props?.confirmationId} hotelId={confirmation?.hotelId} />
          <button className="btn btn-info" type="submit" onClick={() => answerSave({value: true})}>Terminer</button>
        </div>
      }
      case ConfirmationQuestionAnswerTypeEnum.TYPE_BOOLEAN: {
        return <div className="confirmation-answer-actions">
          <button className="btn btn-success" onClick={() => answerSave({value: true})}>OUI</button>
          <button className="btn btn-danger" onClick={() => answerSave({value: false})}>NON</button>
        </div>
      }
      case ConfirmationQuestionAnswerTypeEnum.TYPE_DATETIME_FULL: {
        return <div className="confirmation-answer-actions">
          <DateTimeSelectFullInput value={reservationArrivalDate || dateTime} onChange={(value: Date) => {
            updateHasBeenUpdated(true);
            updateDateTime(value)
          }} />
          <button className="btn btn-info" disabled={!hasBeenUpdated} type="submit" onClick={() => {
            answerSave({value: dateTime})
            updateHasBeenUpdated(false);
          }}>Valider</button>
        </div>
      }
      case ConfirmationQuestionAnswerTypeEnum.TYPE_TEXT: {
        return <div>
          <div className="alert alert-warning">Attention, le texte apparaitra tel quel dans la page "Plus de Détails"</div>
          <div className="confirmation-answer-actions">
            <TextInput maxLength={350} label="Commentaire" value={comment || ""} onChange={(_, value) => {
              updateComment(value)
            }} />
            <button className="btn btn-info" type="submit" onClick={() => answerSave({value: comment})}>Valider</button>
          </div>
        </div>
      }
      case ConfirmationQuestionAnswerTypeEnum.TYPE_DATETIME: {
        return <div className="confirmation-answer-actions">
          <DateTimeSelectInput value={reservationArrivalDate || dateTime} onChange={(value: Date) => {
            updateHasBeenUpdated(true);
            updateDateTime(value);
          }} />
          <button className="btn btn-info" disabled={!hasBeenUpdated} type="submit" onClick={() => {
            answerSave({value: dateTime})
            updateHasBeenUpdated(false);
          }}>Valider</button>
        </div>
      }
      case ConfirmationQuestionAnswerTypeEnum.TYPE_NUMBER: {
        // return <NbPeopleInput onSave={(data) => answerSave(data) } />
        return <div className="confirmation-answer-actions">
          <button className="btn btn-dark" onClick={() => answerSave({value: 1})}>1</button>
          <button className="btn btn-dark" onClick={() => answerSave({value: 2})}>2</button>
          <button className="btn btn-dark" onClick={() => answerSave({value: 3})}>3</button>
          <button className="btn btn-dark" onClick={() => answerSave({value: 4})}>4</button>
          <button className="btn btn-dark" onClick={() => answerSave({value: 5})}>5</button>
          <button className="btn btn-dark" onClick={() => answerSave({value: 6})}>6</button>
          <button className="btn btn-dark" onClick={() => answerSave({value: 7})}>7</button>
          <button className="btn btn-dark" onClick={() => answerSave({value: 8})}>8</button>
          <input type="number" ref={numberRef} placeholder="Autre"></input>
          <button className="btn btn-info" type="submit" onClick={() => answerSave({value: numberRef?.current?.value})}>Valider</button>
        </div>
      }

      case ConfirmationQuestionAnswerTypeEnum.TYPE_AMOUNT: {
        return <div className="confirmation-answer-actions">
          <PriceInput value={amount} onChange={(a) => updateAmount(a)} />
          <button className="btn btn-info" type="submit" onClick={() => answerSave({value: amount})}>Valider</button>
        </div>
      }

    }
  }

  return <div className="confirmation-answer-container">
    {reservationId && <ReservationDetails name="Informations" themeColor="000000" reservationId={reservationId} open={true} />}
    <h1>{question?.labelFR}</h1>
    <div className="confirmation-answer-form-container">
      {_renderForm()}
    </div>
  </div>


}