import {Modal} from "components/common/Navigation/Modal/Modal"
import React from "react"
import {useState} from "react"
import {ContactDisplay, ContactDisplayTypeEnum} from "../Display/ContactDisplay"
import {ContactEdit} from "../Edit/ContactEdit"
import {Contact} from "model"

type ContactEditButtonProps = {
  contact: Contact,
}

export const ContactEditButton = (props: ContactEditButtonProps) => {
  const [showModal, updateShowModal] = useState(false)


  return <div>
    {showModal && <Modal display={true} title="Editer un contact" onClose={() => updateShowModal(false)}>
      <ContactEdit contactId={props?.contact?.id} onDone={() => updateShowModal(false)} />
    </Modal>}
    <button className="btn btn-sm btn-dark" onClick={() => updateShowModal(true)}>
      <ContactDisplay contact={props?.contact} type={ContactDisplayTypeEnum.ADMIN} />
    </button>
  </div>

}