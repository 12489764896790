import { useSubscription } from "@apollo/react-hooks"
import { PriceFormat } from "@zipou/front_tools"
import chargeUpdate from "graphql/BILLING/Charge/subscription/chargeUpdate"
import { Charge, ChargeStatusEnum, TokenStatusEnum } from "model"
import React from "react"

type ChargeStatusProps = {
  charge: Charge,
}

export const ChargeStatus = ({ charge }: ChargeStatusProps) => {

  useSubscription(chargeUpdate, {
    variables: {
      id: charge?.id,
    },
    skip: !charge.id,
  })

  switch (charge.status) {

    case ChargeStatusEnum.CHARGE_STATUS_COMPLETE: {
      return <span className="badge badge-success">CONFIRME</span>
    }

    case ChargeStatusEnum.CHARGE_STATUS_CREATED: {
      return <span className="badge badge-info">EN COURS</span>
    }

    case ChargeStatusEnum.CHARGE_STATUS_ERROR: {
      return <span className="badge badge-danger">ERREUR</span>
    }

    case ChargeStatusEnum.CHARGE_STATUS_REFUND: {
      return <>
        <span className="badge badge-warning ml-1">REMBOURSE</span>
        <span className="badge badge-dark ml-1">
          <PriceFormat value={charge.refundAmount} />
        </span>
      </>
    }

    default: {
      return <span className="badge badge-info">{charge.status}</span>
    }
  }
}

/*

  STATUS_INITIAL = "STATUS_INITIAL",
  STATUS_PENDING_CUSTOMER = "STATUS_PENDING_CUSTOMER",
  STATUS_DONE = "STATUS_DONE",
*/