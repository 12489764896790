import moment from "moment"
import { useQuery } from "@apollo/react-hooks"
import postStayAnswerStatsGetAvailableWeeksGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetAvailableWeeks.gql"
import postStayAnswerStatsGetAvailableYearsGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetAvailableYears.gql"
import React from "react"
import postStayAnswerStatsGetAvailableMonthsGql from "graphql/PMS/Stay/Post/Answer/Stats/query/postStayAnswerStatsGetAvailableMonths.gql"
import { IconArrowLeft, IconArrowRIght } from "@zipou/front_tools"

type StatsFilterMonthlyProps = {
  hotelId: string,
  dimensionDate: Date,
  onChange: (dimensionValue: Date) => void,
}

export const StatsFilterMonthly = ({ hotelId, dimensionDate, onChange }: StatsFilterMonthlyProps) => {

  const year = dimensionDate?.getFullYear()
  const month = moment(dimensionDate)?.month()
  const monthIndexed = month - 1

  console.log('month', month)
  console.log('monthIndexed', monthIndexed)

  const yearsQuery = useQuery<{ postStayAnswerStatsGetAvailableYears: String[] }>(postStayAnswerStatsGetAvailableYearsGql, {
    variables: {
      hotelId,
    }
  })

  const monthsQuery = useQuery<{ postStayAnswerStatsGetAvailableMonths: String[] }>(postStayAnswerStatsGetAvailableMonthsGql, {
    variables: {
      hotelId,
      year,
    },
  })



  const monthList = monthsQuery?.data?.postStayAnswerStatsGetAvailableMonths
  const yearList = yearsQuery?.data?.postStayAnswerStatsGetAvailableYears

  const canGoNext = monthList?.includes(`${Number(monthIndexed) + 1}`)
  const canGoPrevious = monthList?.includes(`${Number(monthIndexed) - 1}`)


  return <div className='input-group'>
    {canGoPrevious && <span className="input-group-text">
      <IconArrowLeft />
    </span>}
    <select className="form-control" placeholder="Semaine" value={monthIndexed || ""} onChange={(v) => {
      const date = moment().day("Monday").week(Number(v.target.value)).toDate()
      onChange(date)
    }}>
      <option value="" disabled>Semaine</option>
      {monthList?.map(monthNumber => {
        const date = new Date(`2024-${(month) > 9 ? (month) : `0${month + 1}`}-01`)
        return <option key={`month_${monthNumber}`} value={`${monthNumber}`}>{date?.toLocaleDateString("FR-fr", { month: "long" }).toLocaleUpperCase()}</option>
      })}
    </select>
    <select className="form-control" placeholder="Année" value={year} onChange={(v) => {
      const date = moment().day("Monday").week(monthIndexed).year(Number(v.target.value)).toDate()
      onChange(date)
    }}>
      <option value="" disabled>Année</option>
      {yearList?.map((year, index) => {
        return <option key={`year_${year}_${index}`} value={`${year}`}>{year}</option>
      })}
    </select>
    {canGoNext && <span className="input-group-text" style={{ height: "100%" }}>
      <IconArrowRIght />
    </span>}
  </div>
}