import { useMutation, useQuery } from "@apollo/react-hooks"
import { DateTimeFormat } from "components/common/Format/DateTimeFormat";
import { TemporisationButton } from "components/common/Navigation/Button/TemporisationButton";
import { Modal } from "components/common/Navigation/Modal/Modal";
import { Pagination } from "components/common/Navigation/Pagination/Pagination";
import { URL_BACKOFFICE_BILLING_TOKEN_ADD, URL_BACKOFFICE_BILLING_TOKEN_DISPLAY, URL_BACKOFFICE_BILLING_TOKEN_EDIT } from "constant/url";
import { CursorInput, Token, TokenListInput, TokenStatusEnum } from "model";
import React, { useContext, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import { IconSearch, IconTrash, TextInput } from "@zipou/front_tools";
import { ContactEditButton } from "components/front/PMS/Contact/Widget/ContactEditButton";
import { userContext } from "context/User";
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge";
import tokenListGql from "graphql/BILLING/Token/query/tokenList.gql";
import tokenSetDeletedGql from "graphql/BILLING/Token/mutation/tokenSetDeleted.gql";
import { TokenStatus } from "../Status/TokenStatus";
import { TokenSendStatus } from "../Send/Status/TokenSendStatus";
import { TokenStatusFilter } from "../Status/TokenStatusFilter";
import { TokenAdd } from "../Add/TokenAdd";

export const TokenList = () => {

  const history = useHistory()


  const [focusCaptureDate, updateFocusCaptureDate] = useState<boolean>(null)
  const [focusCreationDate, updateFocusCreationDate] = useState<boolean>(null)
  const [focusNotes, updateFocusNotes] = useState<boolean>(false)
  const [focusEmail, updateFocusEmail] = useState<boolean>(false)
  const [focusStatus, updateFocusStatus] = useState<boolean>(false)
  const [focusHotel, updateFocusHotel] = useState<boolean>(false)
  const [focusContact, updateFocusContact] = useState<boolean>(false)
  const [focusCaptureType, updateFocusCaptureType] = useState<boolean>(false)
  const [focusAdd, updateFocusAdd] = useState<boolean>(false)

  const user = useContext(userContext)

  const [filters, updateFilters] = useState<TokenListInput>({
    orderField: "token.creationDate",
    orderDirection: "DESC",
    status: [TokenStatusEnum.STATUS_INITIAL, TokenStatusEnum.STATUS_PENDING_CUSTOMER, TokenStatusEnum.STATUS_DONE],
    hotelIds: user?.hotels,
    contact: null,
    creationDate: null,
    captureDate: null,
  })

  const { data, refetch, loading } = useQuery<{ list: { nodes: Token[], cursor: CursorInput, input: TokenListInput } }>(tokenListGql, {
    variables: {
      cursor: {
        page: 1,
        limit: 20,
      },
      input: filters
    }
  })

  const [deleteMutate] = useMutation(tokenSetDeletedGql)

  const onDelete = async (tokenId: string) => {
    return deleteMutate({
      variables: {
        id: tokenId
      },
      fetchPolicy: "no-cache",
      refetchQueries: [{
        query: tokenListGql,
        variables: {
          cursor: {
            page: data?.list?.cursor?.page,
            limit: data?.list?.cursor?.limit,
          },
          input: data?.list.input,
        }
      }]

    })
      .then(() => console.log('OK'))
      .catch((e: any) => console.log('ERROR', e.message))

  }

  const tokenList = data?.list

  const { nodes, cursor } = tokenList || {}
  const hasResults = nodes?.length > 0

  return <div className="payment-list">

    {focusAdd && <Modal display title={"Ajouter une empreinte"} onClose={() => updateFocusAdd(false)}>
      <TokenAdd onDone={token => {
        history.push(URL_BACKOFFICE_BILLING_TOKEN_DISPLAY?.replace(":id", token.id))
      }} />
    </Modal>}


    {focusCreationDate && <Modal display={true} title={"Date de Création"} onClose={() => updateFocusCreationDate(false)}>
      <div className="input-group">
        <span className="input-group-text">Filtrer par date de création</span>
        <input type="date" className="form-control" value={filters.creationDate || ""} onChange={(e) => updateFilters({
          ...filters,
          creationDate: e.target.value
        })} />
        <span className="input-group-text" onClick={() => updateFilters({
          ...filters,
          creationDate: null,
        })}>
          <IconTrash />
        </span>
      </div>
      <div className="input-group">
        <span className="input-group-text">Tri</span>
        <div className="form-control p-1" style={{ height: "100%" }}>
          <button type="button" className="btn btn-sm btn-secondary" style={{ whiteSpace: "nowrap" }} onClick={() => {
            updateFilters({
              ...filters,
              orderDirection: "ASC",
              orderField: "token.creationDate",
            })
          }}>
            Croissant
          </button>
          <button type="button" className="btn btn-sm btn-secondary ml-1" style={{ whiteSpace: "nowrap" }} onClick={() => {
            updateFilters({
              ...filters,
              orderDirection: "DESC",
              orderField: "token.creationDate",
            })
          }}>
            Décroissant
          </button>
        </div>
      </div>

    </Modal>
    }


    {
      focusCaptureDate && <Modal display={true} title={"Date de Capture"} onClose={() => updateFocusCaptureDate(false)}>


        <div className="input-group">
          <span className="input-group-text">Filtrer par date de capture</span>
          <input type="date" className="form-control" value={filters.captureDate || ""} onChange={(e) => updateFilters({
            ...filters,
            captureDate: e.target.value
          })} />
          <span className="input-group-text" onClick={() => updateFilters({
            ...filters,
            captureDate: null,
          })}>
            <IconTrash />
          </span>
        </div>
        <div className="input-group">
          <span className="input-group-text">Tri</span>
          <div className="form-control p-1" style={{ height: "100%" }}>
            <button type="button" className="btn btn-sm btn-secondary" style={{ whiteSpace: "nowrap" }} onClick={() => {
              updateFilters({
                ...filters,
                orderDirection: "ASC",
                orderField: "token.captureDate",
              })
            }}>
              Croissant
            </button>
            <button type="button" className="btn btn-sm btn-secondary ml-1" style={{ whiteSpace: "nowrap" }} onClick={() => {
              updateFilters({
                ...filters,
                orderDirection: "DESC",
                orderField: "token.captureDate",
              })
            }}>
              Décroissant
            </button>
          </div>
        </div>

      </Modal>
    }

    {
      focusStatus && <Modal display={true} onClose={() => updateFocusStatus(false)}>
        <TokenStatusFilter value={filters.status} onChange={(value: any) => {
          updateFilters({
            ...filters,
            status: value,
          })
        }} />
      </Modal>
    }

    {
      focusHotel && <Modal display={true} onClose={() => updateFocusHotel(false)}>
        <button className="btn btn-sm btn-warning" onClick={() => updateFilters({
          ...filters,
          hotelIds: [],
        })}>Clear All</button>
        <button className="btn btn-sm btn-primary" onClick={() => updateFilters({
          ...filters,
          hotelIds: user.hotels,
        })} style={{ marginLeft: 5 }}>Select All</button>
        <ul className="list-group">
          {user?.Hotel?.map((hotel: any) => {
            const isChecked = filters.hotelIds?.includes(hotel?.id);
            return <li key={`hotel_filter_${hotel?.id}`} className="list-group-item" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <input type="checkbox" checked={isChecked} id={`cb_${hotel?.id}`} onChange={(e: any) => updateFilters({ ...filters, hotelIds: e.target.checked ? [...(filters.hotelIds || []), hotel?.id] : filters.hotelIds?.filter((el: any) => el !== hotel?.id) })}></input>
              <label htmlFor={`cb_${hotel?.id}`}><HotelBadge hotel={hotel} /></label>
            </li>
          })}
        </ul>
      </Modal>
    }


    {/* {focusCaptureType && <Modal display={true} onClose={() => updateFocusCaptureType(false)}>
      <TokenCaptureTypeFilter value={filters.captureType} onChange={(value: any) => {
        updateFilters({ ...filters, captureType: value, })
      }} />
    </Modal>} */}

    {
      focusContact && <Modal display={true} onClose={() => updateFocusContact(false)}>
        <div className="pt-2">
          <TextInput onChange={(v) => updateFilters({ ...filters, contact: v, })} label="Filter par nom" errors={{}} value={filters.contact} rightContent={<span onClick={() => {
            updateFilters({ ...filters, contact: "", })
            updateFocusContact(false)
          }}>
            <IconTrash />
          </span>} />

        </div>
      </Modal>
    }


    {
      (loading) && <div className="position-fixed" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div className="alert alert-warning">Chargement...</div>
      </div>
    }

    <div className="content">
      <table className="table table-stripped table-bordered">
        <thead>
          <tr className="bg-dark text-white">
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" onClick={() => updateFocusHotel(true)}>
                Hotel
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" onClick={() => updateFocusContact(true)}>
                Contact
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" onClick={() => updateFocusNotes(true)}>
                Notes
              </button>
            </th>

            <th className="text-white">
              <button className="btn btn-sm btn-secondary" onClick={() => updateFocusCreationDate(true)} >
                Date de Création {data?.list.input?.orderField === "token.creationDate" && <span className={(data?.list.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
              </button>
            </th>

            <th className="text-white">
              <button className="btn btn-sm btn-secondary" onClick={() => updateFocusCaptureDate(true)} >
                Date de Capture {data?.list.input?.orderField === "token.captureDate" && <span className={(data?.list.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
              </button>
            </th>
            <th className="text-white">
              <button type="button" className="btn btn-sm btn-secondary" onClick={() => updateFocusStatus(true)}>
                Statut <span className="badge badge-sm badge-dark">{filters.status?.length}</span>
              </button>
            </th>
            <th className="text-white">
              Envoi Mail
            </th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        {(!hasResults && !loading) && <tbody>
          <tr>
            <td colSpan={11}>
              <div className="alert alert-primary">Rien à afficher</div>
            </td>
          </tr>
        </tbody>}
        {hasResults && <tbody>
          {nodes?.map((token: Token) => {
            const { notes, creationDate, captureDate, Contact } = token

            const isStatusDeleted = token.status === TokenStatusEnum.STATUS_DELETED

            return <tr key={`token_${token?.id}`}>
              <td>
                <span className="badge" style={{ backgroundColor: `#${token?.Hotel?.themeColor}`, color: "white" }}>{token?.Hotel?.name}</span>
              </td>
              <td>
                <ContactEditButton contact={Contact} />
              </td>
              <td>{notes || ""}</td>
              <td>{creationDate && <DateTimeFormat value={new Date(creationDate)} showUTC={false} />}</td>
              <td>{captureDate && <DateTimeFormat value={new Date(captureDate)} showUTC={false} />}</td>
              <td><TokenStatus status={token?.status as TokenStatusEnum} /></td>
              <td><TokenSendStatus status={token?.sendStatus} /></td>
              <td className="actions" style={{ display: "flex", justifyContent: "space-between" }}>
                <button className="btn btn-sm btn-dark mr-1" onClick={() => history.push(URL_BACKOFFICE_BILLING_TOKEN_DISPLAY.replace(':id', token?.id))}>
                  <IconSearch />
                </button>
                {!isStatusDeleted && <TemporisationButton seconds={3} onClick={() => onDelete(token?.id)}>
                  {(onClick, spinnerComponent) => <button className="btn btn-sm btn-danger" onClick={onClick}>
                    {spinnerComponent}
                    <span>
                      <IconTrash />
                    </span>
                  </button>}
                </TemporisationButton>}
              </td>
            </tr>
          })}
        </tbody>}
      </table>
    </div>
    <div className="pagination-container">
      <Pagination cursor={cursor} onChange={(page: number) => {
        refetch({
          cursor: {
            page,
            limit: data?.list?.cursor?.limit,
          },
          input: data?.list.input,
        })
      }} />
    </div>
    <div className="">
      <button className="btn btn-sm btn-dark" onClick={() => updateFocusAdd(true)}>Ajouter</button>
    </div>
  </div >
}