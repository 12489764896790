import gql from "graphql-tag";
import ContactFragment from "graphql/PMS/Contact/fragment/ContactFragment";
import HotelDisplayFragment from "graphql/PMS/Hotel/fragment/HotelDisplayFragment";
import ReservationRoomFragment from "graphql/PMS/Reservation/Room/fragment/ReservationRoomFragment";
import ReservationPricePackageFragmentGql from "../PricePackage/fragment/ReservationPricePackageFragment.gql";
import ReservationRateFragment from "../Rate/fragment/ReservationRateFragment";

export default gql`

  ${ContactFragment}
  ${ReservationRoomFragment}
  ${HotelDisplayFragment}
  ${ReservationRateFragment}
  ${ReservationPricePackageFragmentGql}

  fragment ReservationFragment on Reservation {
    id
    status
    pmsId
    hotelId
    departureDate
    arrivalDate
    updateDate
    nbNight
    creationDate
    totalIncTax
    nbChildren
    nbAdults
    nbSpecial
    DefaultRate {
      ...ReservationRateFragment
    }
    ReservationRate {
      ...ReservationRateFragment
    }
    ReservationPricePackage {
      ...ReservationPricePackageFragment
    }
    Hotel {
      ...HotelDisplayFragment
    }
    Contact {
      ...ContactFragment
    }
    ReservationRoom {
      ...ReservationRoomFragment
    }
    ReservationGroup {
      id
    }
    ReservationGuest {
      id
      Contact {
        ...ContactFragment
      }
    }
  }

`