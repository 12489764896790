import {useMutation, useQuery, useSubscription} from '@apollo/react-hooks'
import {Collapse} from 'components/common/Navigation/Collapse/Collapse'
import propositionPushGql from 'graphql/PMS/Proposition/mutation/propositionPush.gql'
import propositionGetGql from 'graphql/PMS/Proposition/query/propositionGet.gql'
import propositionPushUpdateGql from 'graphql/PMS/Proposition/subscription/propositionPushUpdate.gql'
import {Proposition, PropositionPushPmsEnum, PropositionPushStatusEnum, PropositionRowRoomOption} from 'model'
import React, {useEffect, useState} from 'react'

type PropositionPushProps = {
  propositionId: string,
  onClose?: () => void,
}

export const PropositionPush = (props: PropositionPushProps) => {

  const [error, updateError] = useState<string>(null)

  const {data} = useQuery<{proposition: Proposition}>(propositionGetGql, {
    variables: {
      id: props?.propositionId,
    },
    skip: !props?.propositionId
  })

  const [mutate, {loading}] = useMutation<{proposition: Proposition}>(propositionPushGql)

  useSubscription(propositionPushUpdateGql)

  const proposition: Proposition = data?.proposition

  const propositionRow = proposition?.propositionRow?.find(el => el.id === proposition?.rowSelectedId)
  const pushPms = proposition?.pushPms
  const pushStatus = proposition?.pushStatus

  const [focus, updateFocus] = useState<"RESA" | "OPTIONS" | "STATUS" | "DISCOUNT">(
    pushStatus === PropositionPushStatusEnum.PUSH_NONE ? "RESA" :
      pushStatus === PropositionPushStatusEnum.PUSH_RESERVATION_DONE ? "DISCOUNT" :
        pushStatus === PropositionPushStatusEnum.PUSH_DISCOUNT_DONE ? "OPTIONS" :
          pushStatus === PropositionPushStatusEnum.PUSH_OPTIONS_DONE ? "STATUS" :
            null
  )


  const onPush = (id: string) => {
    updateError(null);
    mutate({
      variables: {
        id,
      }
    })
      .then(() => {
        props?.onClose()
      })
      .catch((e) => {
        updateError(e.message)
      })
  }



  useEffect(() => {
    // if (!focus) {
    updateFocus(
      pushStatus === PropositionPushStatusEnum.PUSH_NONE ? "RESA" :
        pushStatus === PropositionPushStatusEnum.PUSH_RESERVATION_DONE ? "DISCOUNT" :
          pushStatus === PropositionPushStatusEnum.PUSH_DISCOUNT_DONE ? "OPTIONS" :
            null
    )
    // }

  }, [pushStatus])

  const resaPending = pushStatus === PropositionPushStatusEnum.PUSH_NONE || pushStatus === null
  const discountPending = resaPending || pushStatus === PropositionPushStatusEnum.PUSH_RESERVATION_DONE
  const optionsPending = discountPending || pushStatus === PropositionPushStatusEnum.PUSH_DISCOUNT_DONE
  const statusPending = optionsPending || pushStatus === PropositionPushStatusEnum.PUSH_OPTIONS_DONE

  const statusDone = pushStatus === PropositionPushStatusEnum.PUSH_CONFIRMED_DONE
  const optionsDone = statusDone || pushStatus === PropositionPushStatusEnum.PUSH_OPTIONS_DONE
  const discountDone = optionsDone || pushStatus === PropositionPushStatusEnum.PUSH_DISCOUNT_DONE
  const resaDone = discountDone || pushStatus === PropositionPushStatusEnum.PUSH_RESERVATION_DONE

  const mustConfirm = pushPms === PropositionPushPmsEnum.PROPOSITION_PUSH_POST_CONFIRMED

  const hasOptionsToPush = propositionRow?.roomList?.reduce((acc, rowRoom) => {
    return acc || rowRoom?.optionList?.length > 0
  }, false)

  const canPush = true

  return <div>
    <h1>Push Proposition {proposition?.publicId}</h1>
    {!!error && <div className='alert alert-danger'>
      {error}
    </div>}
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-center" onClick={() => updateFocus("RESA")}>
        {resaPending && <span className="icon-pending mr-3"></span>}
        {resaDone && <span className="icon-check mr-3"></span>}
        Push Reservation(s)
      </div>
      <Collapse collapsed={focus !== "RESA"}>
        <div className="card-body">
          <ul className="list-group">
            {propositionRow?.roomList?.map((room, index) => {
              const sameConditions = room.sameConditions

              if (sameConditions) {

                const isPending = !room.pmsId
                const isPushed = !!room.pmsId

                return <li key={`room_${index}`} className='list-group-item'>
                  {isPushed && <span className='icon-check'></span>}
                  {isPending && <span className='icon-pending'></span>}
                  <span className=''>Reservation #{room?.pmsId}</span>
                </li>

              } else {

                return room?.itemList?.map((item, idx) => {

                  const isPending = !item.pmsId
                  const isPushed = !!item.pmsId

                  return <li key={`room_${index}_${idx}`} className='list-group-item d-flex justify-content-center'>
                    {isPushed && <span className='icon-check'></span>}
                    {isPending && <span className='icon-pending'></span>}
                    <span className='ml-2'>{idx + 1}.</span>
                    <span className="ml-2">Reservation {item?.pmsId ? `1${item?.pmsId}` : ``}</span>
                  </li>
                })
              }
            })}
          </ul>

        </div>
      </Collapse>
    </div>
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-center" onClick={() => updateFocus("DISCOUNT")}>
        {discountPending && <span className="icon-pending mr-3"></span>}
        {discountDone && <span className="icon-check mr-3"></span>}
        Push Reductions(s)
      </div>
      <Collapse collapsed={focus !== "DISCOUNT"}>
        <div className="card-body">
          <ul className="list-group">
            {propositionRow?.roomList?.map((room, index) => {
              const sameConditions = room.sameConditions

              if (sameConditions) {

                const isPending = !room.discountPushed
                const isPushed = !!room.discountPushed

                return <li key={`room_${index}`} className='list-group-item'>
                  {isPushed && <span className='icon-check'></span>}
                  {isPending && <span className='icon-pending'></span>}
                  <span className=''>Reservation #{room?.pmsId}</span>
                </li>

              } else {

                return room?.itemList?.map((item, idx) => {

                  const isPending = !item.discountPushed
                  const isPushed = !!item.discountPushed

                  return <li key={`room_${index}_${idx}`} className='list-group-item d-flex justify-content-center'>
                    {isPushed && <span className='icon-check'></span>}
                    {isPending && <span className='icon-pending'></span>}
                    <span className='ml-2'>{idx + 1}.</span>
                    <span className="ml-2">Reservation {item?.pmsId ? `1${item?.pmsId}` : ``}</span>
                  </li>
                })
              }
            })}
          </ul>
        </div>
      </Collapse>
    </div>
    {hasOptionsToPush && <div className="card">
      <div className="card-header d-flex align-items-center justify-content-center" onClick={() => updateFocus("OPTIONS")}>
        {optionsPending && <span className="icon-pending mr-3"></span>}
        {optionsDone && <span className="icon-check mr-3"></span>}
        Push Options(s)
      </div>
      <Collapse collapsed={focus !== "OPTIONS"}>
        <div className="card-body">
          <div>
            {propositionRow?.roomList?.map((room, index) => {

              return <ul className="list-group">
                {
                  room?.optionList?.map((option: PropositionRowRoomOption, idx) => {
                    const isPending = !option.optionPushed
                    const isPushed = !!option.optionPushed
                    return <li key={`option_${index}_${idx}`} className='list-group-item d-flex justify-content-center'>
                      {isPushed && <span className='icon-check'></span>}
                      {isPending && <span className='icon-pending'></span>}
                      <span className='ml-2'>{idx + 1}.</span>
                      <span className="ml-2">Option {option?.Option?.labelFr}</span>
                    </li>
                  })
                }
              </ul>
            })}
          </div>
        </div>
      </Collapse>
    </div>}
    {mustConfirm && <div className="card">
      <div className="card-header d-flex align-items-center justify-content-center" onClick={() => updateFocus("STATUS")}>
        {statusPending && <span className="icon-pending mr-3"></span>}
        {statusDone && <span className="icon-check mr-3"></span>}
        Push Statut Confirmé
      </div>
      <Collapse collapsed={focus !== "STATUS"}>
        <div className="card-body">
          <ul className="list-group">
            {propositionRow?.roomList?.map((room, index) => {

              // const roomPushState = pushState.roomList[index]
              const sameConditions = room.sameConditions

              if (sameConditions) {

                const isPending = !room.confirmedPush
                const isPushed = !!room.confirmedPush

                return <li key={`room_${index}_push`} className='list-group-item'>
                  {isPushed && <span className='icon-check'></span>}
                  {isPending && <span className='icon-pending'></span>}
                  <span className=''>Reservation #{room?.pmsId}</span>
                </li>

              } else {

                return room?.itemList?.map((item, idx) => {

                  // const itemPushState = roomPushState.itemList[idx]
                  const isPending = !item.confirmedPush
                  const isPushed = !!item.confirmedPush

                  return <li key={`room_${index}_push_${idx}`} className='list-group-item d-flex justify-content-center'>
                    {isPushed && <span className='icon-check'></span>}
                    {isPending && <span className='icon-pending'></span>}
                    <span className="ml-3">Reservation #{item?.pmsId}</span>
                  </li>
                })
              }
            })}
          </ul>
        </div>
      </Collapse>
    </div>}
    <div className='mt-2'>
      {canPush && <button disabled={!!loading} className='btn btn-sm btn-dark' onClick={() => onPush(proposition?.id)}>
        {loading && <span className='icon-loading mr-2'></span>} Pusher
      </button>}
    </div>
  </div>

}