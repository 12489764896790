import { FlagSelector } from "components/common/Navigation/I18n/FlagSelector/FlagSelector"
import { Modal } from "components/common/Navigation/Modal/Modal"
import { Hotel } from "model"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import './style.scss'
import { hotelContext } from "context/hotelContext"
import { CgvDisplay } from "components/front/PMS/Hotel/CGV/CgvDisplay"

type HotelContainerProps = {
  hotel: Hotel,
  children: any,
  loading?: boolean,
}
export const HotelContainer = (props: HotelContainerProps) => {
  const location = useLocation();

  useEffect(() => {
    const lang = new URLSearchParams(location?.search)?.get("lang")
    lang && i18n.changeLanguage(lang || "fr");
  }, [])


  const { i18n } = useTranslation('translation', { keyPrefix: 'hotel' });

  const loading = props.loading

  const hotel = props?.hotel
  const hasCgv = (hotel?.CGVFrFile && hotel?.CGVEnFile)
  const { children } = props

  // const hotelCtx = useContext(hotelContext)

  const backgroundStyle = hotel?.backgroundThemeColor ?  { backgroundColor: `#${hotel?.backgroundThemeColor}` }  : { backgroundImage: `url(${hotel?.backgroundUrl})` }  as React.CSSProperties

  return <div className="hotel_container" style={{
    ...backgroundStyle,
    "--theme-color": `#${hotel?.themeColor}`
  } as React.CSSProperties} >
    <Helmet>
      <title>{hotel?.name || "App"}</title>
      <meta name="description" content={hotel?.name || "App"} />
      <link rel="icon" type="image/png" href="favicon.png" />
    </Helmet>
    {loading && <Modal display={true}>
      <span className="icon-loading"></span>
    </Modal>}
    {!loading && <div>
      <FlagSelector />
      <div className='filter'>
        <div className="container">
          <div className="hotel_container_children pt-5">
            <div>
              <hotelContext.Provider value={hotel}>
                <div className="" style={{ backgroundColor: "white", paddingTop: 5, paddingLeft: 15, paddingRight: 15 }}>
                  <div className="d-flex justify-content-center align-items-center pt-3">
                    <img src={hotel?.logoMonoUrl || hotel?.logoUrl} style={{ maxWidth: 120 }} alt="logo" className="img-fluid" />
                  </div>
                  <div>
                    {children}
                  </div>
                </div>
              </hotelContext.Provider>
            </div>
          </div>
        </div>
        {hasCgv && <div className="footer" style={{ position: "absolute", bottom: 15, width: "100%", height: 30 }}>
          <div className="container" style={{ backgroundColor: "rgba(255,255,255,0.3)", paddingBottom: 0, display: "flex", justifyContent: "center" }}>
            <CgvDisplay hotel={hotel} />
          </div>
        </div>}

      </div>
    </div>}
  </div>
}