import React, { useState } from "react"

import { useQuery } from "@apollo/client"
import reservationGetByFilters from "graphql/PMS/Reservation/query/reservationGetByFilters"
import { Pagination } from "components/common/Navigation/Pagination/Pagination"
import hotelListQuery from "graphql/PMS/Hotel/query/hotelList"
import { DateFormat } from "components/common/Format/DateFormat"
import { ReservationStatus } from "../Status/ReservationStatus"
import { Modal } from "components/common/Navigation/Modal/Modal"
import { ReservationFullDetails } from "../Details/ReservationFullDetails"
import { TextInput } from "components/common/Form/TextInput"
import { HotelBadge } from "../../Hotel/Badge/HotelBadge"
import { ContactSearchField } from "../../Contact/Field/ContactSearchField"
import { DateInput } from "@zipou/front_tools"
import { CustomerSearchField } from "components/front/CRM/Customer/Field/CustomerSearchField"

export const ReservationSearch = () => {

  const [reservationFocus, updateReservationFocus] = useState(null)

  const [loading, updateLoading] = useState<any>(false)
  const [result, updateResults] = useState<any>(null)
  const [filters, updateFilters] = useState<any>({
    input: {

    },
    order: {
      field: "arrivalDate",
      direction: "DESC",
    },
    cursor: {
      page: 1,
      limit: 20
    }
  })

  const hotelListQueryResults = useQuery(hotelListQuery)
  const { refetch } = useQuery(reservationGetByFilters, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })

  // const {reservationList, cursor} = data || {}

  const onSubmit = (e: any) => {
    updateLoading(true)
    e.preventDefault();
    handleSearch(filters)
  }



  const handleSearch = async (filters: any) => {
    try {
      const response = await refetch({
        ...filters,
      })
      updateResults(response?.data)
      updateLoading(false)

    } catch (e) {
      updateLoading(false)
      console.log("ERROR", e)
    }

  }

  const hotelList: [any] = hotelListQueryResults?.data?.list
  const reservationList = result?.reservationGetByFilters?.reservationList
  const hasResults = !!reservationList
  const cursor = hasResults && result?.reservationGetByFilters?.cursor


  return <div className="reservation-search-container">
    <Modal display={!!reservationFocus} onClose={() => updateReservationFocus(null)}>
      <ReservationFullDetails reservationId={reservationFocus} />
    </Modal>
    <div className="reservation-search-inputs">
      <div className="card">
        <div className="card-header bg-dark text-white">
          Voir les réservations
        </div>
        <div className="card-body">
          <form onSubmit={onSubmit}>
            <TextInput value={filters?.input?.pmsId} id="pmsId" errors={null} label="Numéro de résa" onChange={(e, v) => {
              updateFilters({
                ...filters,
                input: {
                  ...filters.input,
                  pmsId: v,
                }
              })
            }} />
            <CustomerSearchField label="Customer" value={filters?.input?.customerId} onChange={customerId => {
              updateFilters({
                ...filters,
                input: {
                  ...filters.input,
                  customerId,
                }
              })
            }} />
            <div className="input-group">
              <span className="input-group-text">Hotel</span>
              <select className="form-control" value={filters?.input?.hotelId || ""} onChange={(e: any) => updateFilters({
                ...filters,
                input: {
                  ...filters.input,
                  hotelId: e.target.value,
                }
              })}>
                <option value="">Peu importe</option>
                {hotelList?.map((hotel: any) => {
                  return <option value={hotel?.id} key={`hotel_${hotel?.id}`}>
                    {hotel?.name}
                  </option>
                })}
              </select>
            </div>
            <ContactSearchField label="Contact" hotelId={filters?.input?.hotelId || ""} disabled={!filters?.input?.hotelId} value={filters?.input?.contactId} onChange={contactId => {
              updateFilters({
                ...filters,
                input: {
                  ...filters.input,
                  contactId,
                }
              })
            }} />
            <div className="input-group">
              <span className="input-group-text">Status</span>
              <select className="form-control" value={filters?.input?.status || ""} onChange={(e: any) => {
                const { status, ...rest } = filters.input
                updateFilters({
                  ...filters,
                  input: {
                    ...rest,
                    ...(e.target.value !== "" && { status: e.target.value }),
                  }
                })
              }}>
                <option value="">Peu Importe</option>
                <option value="BLOCKED">BLOCKED</option>
                <option value="CONFIRMED">CONFIRMED</option>
                <option value="CANCELLED">CANCELLED</option>
                <option value="DEPARTED">DEPARTED</option>
                <option value="PROVISIONAL">PROVISIONAL</option>
              </select>
            </div>
            <div className="input-group">
              <span className="input-group-text">Date d'arrivée</span>
              <div className="form-control" style={{ height: "100%" }}>
                <DateInput value={filters?.input?.arrivalFrom} errors={{}} label="Du" onChange={(stamp, date) => {
                  updateFilters({
                    ...filters,
                    input: {
                      ...filters.input,
                      arrivalFrom: date,
                    }
                  })
                }} />
                <DateInput value={filters?.input?.arrivalTo} errors={{}} label="au" onChange={(stamp, date) => {
                  updateFilters({
                    ...filters,
                    input: {
                      ...filters.input,
                      arrivalTo: date,
                    }
                  })
                }} />
              </div>
            </div>
            <div className="input-group">
              <span className="input-group-text">Date de départ</span>
              <div className="form-control" style={{ height: "100%" }}>
                <DateInput value={filters?.input?.departureFrom} errors={{}} label="Du" onChange={(stamp, date) => {
                  updateFilters({
                    ...filters,
                    input: {
                      ...filters.input,
                      departureFrom: date,
                    }
                  })
                }} />
                <DateInput value={filters?.input?.departureTo} errors={{}} label="au" onChange={(stamp, date) => {
                  updateFilters({
                    ...filters,
                    input: {
                      ...filters.input,
                      departureTo: date,
                    }
                  })
                }} />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="input-group">
                  <span className="input-group-text">Tier par</span>
                  <select className="form-control" value={filters?.order?.field || ""} onChange={(e: any) => updateFilters({
                    ...filters,
                    order: {
                      field: e.target.value,
                      direction: "DESC"
                    }
                  })}>
                    <option value="creationDate">Date de création</option>
                    <option value="updateDate">Date de mise à jour</option>
                    <option value="arrivalDate">Date d'arrivée</option>
                    <option value="departureDate">Date de départ</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="input-group">
                  <span className="input-group-text">Ordre</span>
                  <select className="form-control" value={filters?.order?.direction || ""} onChange={(e: any) => updateFilters({
                    ...filters,
                    order: {
                      ...filters.order,
                      direction: e.target.value,
                    }
                  })}>
                    <option value="ASC">Croissant</option>
                    <option value="DESC">Decroissant</option>
                  </select>
                </div>
              </div>
            </div>
            <button className="btn btn-blue-h8" type="submit" disabled={loading}>
              {loading && <span className="icon-loading" />} Rechercher
            </button>
          </form>
        </div>
      </div>
    </div>
    {hasResults && <div className="reservation-search-results">
      <div className="card">
        <div className="card-body">
          <table className="table table-bordered table-stripped">
            <thead>
              <tr className="bg-dark text-white">
                <th className="bg-dark text-white">Hotel</th>
                <th className="bg-dark text-white">ID</th>
                <th className="bg-dark text-white">Arrivée</th>
                <th className="bg-dark text-white">Départ</th>
                <th className="bg-dark text-white">Status</th>
              </tr>
            </thead>
            <tbody>
              {hasResults && reservationList?.map((reservation: any) => {
                return <tr key={`reservation_${reservation?.id}`}>
                  <td><HotelBadge hotel={reservation?.Hotel} /></td>
                  <td><button type="button" className="btn btn-dark btn-sm" onClick={() => updateReservationFocus(reservation?.id)}>{reservation?.pmsId}</button></td>
                  <td><DateFormat value={new Date(reservation?.arrivalDate)} /></td>
                  <td><DateFormat value={new Date(reservation?.departureDate)} /></td>
                  <td><ReservationStatus value={reservation?.status} /></td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>}
    <div className="">
      <Pagination cursor={cursor} onChange={(cursor2: any) => handleSearch({
        ...filters,
        cursor: {
          ...filters?.cursor,
          page: cursor2,
        },
      })} />
    </div>
  </div>

}
