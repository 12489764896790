import { Element } from "react-scroll"
import { useMutation } from "@apollo/react-hooks";
import { StarInput } from "components/common/Form/StarInput";
import postStayAnswerByIdAndPublicIdGql from "graphql/PMS/Stay/Post/mutation/postStayAnswerByIdAndPublicId.gql";
import { formatErrorResponseForJoi } from "helpers/Apollo";
import { Reservation, Hotel, PostStay, PostStayQuestion, PostStayQuestionTypeEnum, PostStayGroupSeparator, PostStayGroupItemTypeEnum } from "model"
import React, { useState } from "react"
import { useTranslation } from "react-i18next";

import "./style.scss"
import { TextInput } from "components/common/Form/TextInput";
import { SelectInput } from "@zipou/front_tools";
import { Modal } from "components/common/Navigation/Modal/Modal";

type PostStayAnswerProps = {
  postStay: PostStay,
  reservation: Reservation,
  hotel: Hotel,
}


export const PostStayAnswer = (props: PostStayAnswerProps) => {

  const debug = true


  const { t, i18n } = useTranslation('translation', { keyPrefix: 'poststay' });
  const isFrench = i18n.language === "fr"

  const [postStay, updatePostStay] = useState(props?.postStay)

  const [displayTripModal, updateDisplayTripModal] = useState(false)
  // const [displayGoogleModal, updateDisplayGoogleModal] = useState(false)
  const [errors, updateErrors] = useState<any>()
  const [message, updateMessage] = useState<any>()

  const [mutate] = useMutation(postStayAnswerByIdAndPublicIdGql)


  const renderQuestionForm = (question: PostStayQuestion) => {

    const value = postStay?.AnswerList?.find((el: any) => el?.questionId === question?.id)?.value || null
    const alreadyAnswered = postStay?.AnswerList?.find((el: any) => el?.questionId === question?.id);

    switch (question?.type) {
      case PostStayQuestionTypeEnum.TYPE_STAR: {
        return <div className="pt-3 row d-flex">
          {debug && <div className="col-sm-1" />}
          <div className="col-12 col-sm-5 d-flex align-items-center text-left justify-content-sm-start">
            <h2>{isFrench ? question?.labelFr : question?.labelEn}</h2>
          </div>
          <div className="col-12 col-sm-6 d-flex align-items-center text-left justify-content-center">
            <StarInput value={value} onChange={(value: any) => {
              // const alreadyAnswered = postStay?.AnswerList?.find((el: any) => el?.questionId === question?.id)

              updatePostStay({
                ...postStay,
                AnswerList: alreadyAnswered
                  ? postStay?.AnswerList?.map((el: any) => el.questionId === question?.id ? { questionId: question?.id, value } : el)
                  : [
                    ...(postStay?.AnswerList || []),
                    { questionId: question?.id, value }
                  ]
              })
            }} />
          </div>
        </div>
      }

      case PostStayQuestionTypeEnum.TYPE_TEXT: {
        return <div className="pt-3 row">
          <div className="col-12 col-sm-6">
            <h2>{isFrench ? question?.labelFr : question?.labelEn}</h2>
          </div>
          <div className="col-12 col-sm-6">
            <TextInput value={value} onChange={(value: any) => {
              // const alreadyAnswered = postStay?.AnswerList?.find((el: any) => el?.questionId === question?.id);

              updatePostStay({
                ...postStay,
                AnswerList: alreadyAnswered
                  ? postStay?.AnswerList?.map((el: any) => el.questionId === question?.id ? { questionId: question?.id, value } : el)
                  : [
                    ...(postStay?.AnswerList || []),
                    { questionId: question?.id, value }
                  ]
              });
            }} />
          </div>
        </div>

      }

      case PostStayQuestionTypeEnum.TYPE_TEXTAREA: {

        return <div className="pt-3 row">
          <div className="col-12">
            <h2>{isFrench ? question?.labelFr : question?.labelEn}</h2>
          </div>
          <div className="col-12">
            <textarea className="form-control" value={value || ""} style={{ minHeight: 200 }} onChange={(e: any) => {
              updatePostStay({
                ...postStay,
                AnswerList: alreadyAnswered
                  ? postStay?.AnswerList?.map((el: any) => el.questionId === question?.id ? { questionId: question?.id, value: e.target.value } : el)
                  : [
                    ...(postStay?.AnswerList || []),
                    { questionId: question?.id, value: e.target.value }
                  ]
              });
            }} />
          </div>
        </div>
      }

      case PostStayQuestionTypeEnum.TYPE_CHOICE: {

        return <div className="pt-3 row">
          <div className="col-12 col-sm-6 d-flex align-items-center">
            <h2>{isFrench ? question?.labelFr : question?.labelEn}</h2>
          </div>
          <div className="col-12 col-sm-6">
            <SelectInput label={""} choiceList={question?.choiceList?.map((el) => ({ id: el.id, label: t(el.label) }))} errors={{}} value={value} onChange={(value) => {
              updatePostStay({
                ...postStay,
                AnswerList: alreadyAnswered
                  ? postStay?.AnswerList?.map((el: any) => el.questionId === question?.id ? { questionId: question?.id, value } : el)
                  : [
                    ...(postStay?.AnswerList || []),
                    { questionId: question?.id, value }
                  ]
              });
            }} />
          </div>
        </div>
      }

      case PostStayQuestionTypeEnum.TYPE_CHOICE_RADIO: {

        const answer = postStay?.AnswerList?.find((el: any) => el.questionId === question?.id)
        const nbChoice = question?.choiceList?.length
        const breakLine = nbChoice < 3

        return <div className="pt-3 row">
          {debug && <div className="col-sm-1" />}
          <div className={breakLine ? "col-12 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start text-left" : "pl-4 pt-3 pb-2 align-items-center "}>
            <h2>{isFrench ? question?.labelFr : question?.labelEn}</h2>
          </div>
          {question?.descFr && <h4>{isFrench ? question?.descFr : question?.descEn}</h4>}
          <div className={breakLine ? "col-12 col-sm-6 d-flex justify-content-center" : "row d-flex justify-content-center col-12"}>
            {question?.choiceList?.map(choice => {
              return <div className="col-6 col-sm-2" key={`div_question_${question.id}_${choice?.id}`}>
                <div className="d-flex flex-row justify-content-center">
                  <label htmlFor={`question_${question.id}_${choice?.id}`} className={answer?.value === choice?.id ? "checked" : ""}>
                    <div style={{ minHeight: 50, alignItems: "center", display: "flex" }}>
                      <h3>{isFrench ? choice?.labelFr : choice?.labelEn}</h3>
                    </div>
                  </label>
                  <input type="radio" id={`question_${question.id}_${choice?.id}`} name={question?.id} checked={answer?.value === choice?.id} value={choice?.id} onChange={(e) => {
                    updatePostStay({
                      ...postStay,
                      AnswerList: alreadyAnswered
                        ? postStay?.AnswerList?.map((el: any) => el.questionId === question?.id ? { questionId: question?.id, value: choice?.id } : el)
                        : [
                          ...(postStay?.AnswerList || []),
                          { questionId: question?.id, value: choice?.id }
                        ]
                    });
                  }} />
                </div>
              </div>
            })}
          </div>
        </div>
      }
    }
  }

  const onSave = () => {
    mutate({
      variables: {
        id: postStay?.id,
        publicId: postStay?.publicId,
        input: {
          answerList: postStay?.AnswerList?.map((answer: any) => ({
            questionId: answer?.questionId,
            value: `${answer?.value}`,
          })),
        }
      }
    })
      .then(() => {
        updateMessage(true)
      })
      .catch((e: any) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }

  const postStayConfig = postStay?.PostStayConfig
  const postStayGroup = postStayConfig?.PostStayGroup
  const themeColor = `#${props?.hotel?.themeColor}`

  const googlePlaceId = props?.hotel?.GoogleConfig?.placeId
  const tripAdvisorReviewUrl = props?.hotel?.TripAdvisorConfig?.reviewUrl

  return <div className="post-stay-container pl-sm-5 pr-sm-5" style={{ "--theme-color": themeColor } as React.CSSProperties}>
    <Modal title="Laisser un avis Trip Advisor" display={displayTripModal} onClose={() => updateDisplayTripModal(false)}>
      <iframe
        title="Avis"
        width={"100%"}
        style={{ height: "80vh", width: "100%" }}
        src={tripAdvisorReviewUrl}
      />
    </Modal>
    <Modal display={!!message} themeColor={themeColor} contentClassName={"content-modal-test"} >
      <div className='alert alert-message'>
        <h1>{t("saved")}</h1>
        <p className="mt-2">

          {tripAdvisorReviewUrl && <button className="btn btn-dark ml-4 mt-4" style={{ textTransform: "uppercase", backgroundColor: themeColor }} onClick={() => updateDisplayTripModal(true)}>
            {t("publishTripAdvisor")}
          </button>}
          {googlePlaceId && <a href={`https://search.google.com/local/writereview?placeid=${googlePlaceId}`} target="_blank" rel="noreferrer">
            <button className="btn btn-dark external ml-4 mt-4" style={{ textTransform: "uppercase" }}>
              {t("publishGoogle")}
            </button>
          </a>}
        </p>
      </div>
    </Modal>
    <div className="row bg-white">
      <div className="col-12 col-md-12 bg-white pl-0 pl-sm-3">
        <h1>{t("title")}</h1>
        <h2 style={{ textTransform: "uppercase" }}>{t("subtitle")}</h2>

        {postStayGroup?.map((group, index) => {

          const { labelEn, labelFr, descEn, descFr, PostStayGroupItem } = group
          const titleNumber = index + 1

          return <Element key={`title_${titleNumber}`} id={`title_${titleNumber}`} name={`title_${titleNumber}`}>
            <div className="pt-3 pb-4 bg-white">
              <div className="bg-white">
                <div className="">
                  <h1>{isFrench ? labelFr : labelEn}</h1>
                  {descFr && <h2>{isFrench ? descFr : descEn}</h2>}
                  <div>
                    {PostStayGroupItem?.map((item, idx) => {

                      const renderQuestion = (question: PostStayQuestion) => {

                        const conditionItem = PostStayGroupItem?.find(q => {
                          return q?.Question?.name === question?.conditionKey 
                        })

                        const answer = postStay?.AnswerList?.find((el: any) => el.questionId === conditionItem?.Question?.id)
                        const mustDisplay = conditionItem ? answer?.value === question?.conditionValue : true

                        return <>
                          {mustDisplay && <div key={`title_${titleNumber}_question_${idx}`}>
                            {renderQuestionForm(question)}
                          </div>}
                        </>
                      }

                      const renderSeparator = (separator: PostStayGroupSeparator) => {
                        return <span className="separator">
                          <h1 style={{ marginLeft: 80 }}>{isFrench ? separator?.labelFr : separator?.labelEn}</h1>
                        </span>
                      }

                      const isQuestion = item?.type === PostStayGroupItemTypeEnum.TYPE_QUESTION
                      const isSeparator = item?.type === PostStayGroupItemTypeEnum.TYPE_SEPARATOR

                      return <div key={`item_${item.id}_${index}`}>
                        {isSeparator && renderSeparator(item?.Separator)}
                        {isQuestion && renderQuestion(item?.Question)}
                      </div>

                    })}
                  </div >
                </div>
              </div>
            </div>
          </Element>
        })}


        <div className="" style={{ marginTop: 30 }}></div>


        <div className="" style={{ padding: 15, marginTop: 15, bottom: 10 }}>
          <button className="btn btn-lg btn-primary" onClick={() => onSave()}>{t("envoyer")}</button>
        </div>

      </div>
    </div>

  </div >
}