import { Collapse, DateFormat, IconEdit } from "@zipou/front_tools";
import { Modal } from "components/common/Navigation/Modal/Modal";
import { PaymentListByContact } from "components/front/BILLING/Payment/List/PaymentListByContact";
import { TokenListDetails } from "components/front/BILLING/Token/List/TokenListDetails";
import { CustomerDetails } from "components/front/CRM/Customer/Details/CustomerDetails";
import { CustomerDisplay } from "components/front/CRM/Customer/Display/CustomerDisplay";
import { PhoneCallButton } from "components/front/PHONE/Call/Button/PhoneCallButton";
import { URL_BACKOFFICE_BILLING_TOKEN_EDIT } from "constant/url";
import { userContext } from "context/User";
import { Contact, UserRoleEnum } from "model";
import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom";

type ContactDetailsProps = {
  contact: Contact,
}

export const ContactDetails = (props: ContactDetailsProps) => {
  const { contact } = props;


  const [focus, updateFocus] = useState(false)
  const [displayToken, updateDisplayToken] = useState(false)
  const [displayPayment, updateDisplayPayment] = useState(false)
  const history = useHistory()

  const customer = contact?.Customer

  const user = useContext(userContext)
  const hasDebugRole = user?.roles?.includes(UserRoleEnum.ROLE_DEBUG)

  const tokenList = contact?.Token
  const hasToken = tokenList?.length > 0
  const paymentList = contact?.Payment
  const hasPayment = paymentList?.length > 0

  return <div className="card">
    <Modal display={focus} onClose={() => updateFocus(false)}>
      <CustomerDetails customerId={customer?.id} showProposition={false} showContacts={true} showReservation={true} />
    </Modal>
    {contact?.Hotel?.name && <div className="card-header text-white" style={{ backgroundColor: `#${contact?.Hotel?.themeColor}` }}>
      {contact?.Hotel?.name}
    </div>}
    <table className="table">
      <tbody>
        {hasDebugRole && <tr>
          <th>ID</th>
          <td>{contact?.id}</td>
        </tr>}
        <tr>
          <th>Nom / Prénom / #</th>
          <td>{contact?.lastName} / {contact?.firstName} / {contact?.pmsId}</td>
        </tr>
        {customer && <tr>
          <th>Customer</th>
          <td>
            <button className="btn btn-sm btn-dark" onClick={() => updateFocus(true)}>
              <CustomerDisplay customer={customer} />
            </button>
          </td>
        </tr>}
        {(contact?.phone1 && contact?.isPhone1Valid) && <tr>
          <th>Téléphone</th>
          <td>
            <PhoneCallButton phoneNumber={contact?.phone1} countryCode={contact?.countryCode} />
          </td>
        </tr>}
        <tr>
          <th>Email</th>
          <td>{contact?.email}</td>
        </tr>
        <tr>
          <th>Type</th>
          <td>{contact?.type}</td>
        </tr>
      </tbody>
    </table>
    {/* <div className="card">
      <div className="card-header bg-dark text-white text-left" onClick={() => updateDisplayToken(!displayToken)}>Empreintes Bancaires</div>
      <Collapse maxHeight={300} collapsed={!displayToken}>
        <div className="card-body">
          <table>
            <tbody>
              <tr>
                <td>
                  {hasToken && <TokenListDetails tokenList={tokenList} />}
                  {!hasToken && <div className="alert alert-secondary">Aucune empreinte bancaire enregistrée</div>}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>
    </div>
    <div className="card">
      <div className="card-header bg-dark text-white text-left" onClick={() => updateDisplayPayment(!displayPayment)}>Paiements</div>
      <Collapse maxHeight={300} collapsed={!displayPayment}>
        <div className="card-body">
          <table>
            <tbody>
              <tr>
                <td>
                  {hasPayment && <PaymentListByContact contact={contact} />}
                  {!hasPayment && <div className="alert alert-secondary">Aucun paiement enregistré</div>}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>
    </div> */}
  </div>
}
