import { CameraView } from "components/front/VIDEO/Camera/View/CameraView"
import React from "react"

export const CameraDisplayRoute = (props: any) => {

  const { match } = props
  const { params } = match
  const { id } = params

  return <div>
    <CameraView cameraId={id} />
  </div>
}