import React, { useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import userListGql from "graphql/APP/User/query/userList.gql"
import userImpersonateGql from "graphql/APP/User/mutation/userImpersonate"
import userProfileGql from "graphql/APP/User/query/userProfile"
import { User } from "model"
import { Modal } from "@zipou/front_tools"


type ImpersonateFormProps = {
  onDone: () => void,
}

export const ImpersonateForm = (props: ImpersonateFormProps) => {

  const { onDone } = props

  const { data } = useQuery<{ userList: User[] }>(userListGql)
  const userList = data?.userList

  const [impersonate] = useMutation(userImpersonateGql, {
    refetchQueries: [
      {
        query: userProfileGql,
      }
    ]
  })

  const startImpersonation = async (userId: string) => {
    return impersonate({
      variables: {
        id: userId,
      }
    })
      .then(() => {
        onDone && onDone()
      })
  }

  return <div style={{ display: "flex", flexWrap: "wrap" }}>
    {userList && userList.map(user => {
      return user && <div style={{ padding: 10 }}><button className="btn btn-sm btn-dark" onClick={() => startImpersonation(user.id)}>{user.email}</button></div>
    })}
  </div>
}