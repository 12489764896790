import { Modal } from "@zipou/front_tools"
import { CustomerEmail, EmailStatusEnum } from "model"
import React, { useState } from "react"
import { CustomerEmailDetails } from "../Details/CustomerEmailDetails"
import { CustomerEmailStatusDisplay } from "../Status/Display/CustomerEmailStatusDisplay"

type CustomerEmailDisplayProps = {
  customerEmail: CustomerEmail
}

export const CustomerEmailDisplay = ({ customerEmail }: CustomerEmailDisplayProps) => {


  const [displayDetails, updateDisplayDetails] = useState(false)


  return <>
    <Modal display={displayDetails} onClose={() => updateDisplayDetails(false)}>
      <CustomerEmailDetails id={customerEmail?.id} />
    </Modal>
    <button className="btn btn-sm btn-dark" onClick={() => updateDisplayDetails(true)}><span className="icon-enveloppe"></span></button> {customerEmail?.email} <CustomerEmailStatusDisplay status={customerEmail?.status as EmailStatusEnum} />
  </>

}