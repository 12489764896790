import { Contact as ContactComponent } from "components/public/PHONE/Contact/Contact"

import { useQuery } from "@apollo/react-hooks"
import { HotelContainer } from "components/public/PMS/Hotel/HotelContainer"
import React from "react"
import { RouteComponentProps } from "react-router-dom"
import postStayGetByIdAndPublicId from "graphql/PMS/Stay/Post/query/postStayGetByIdAndPublicId"
import { PostStayAnswer } from "components/front/PMS/Stay/Post/Answer/PostStayAnswer"
import { PostStay } from "model"


export const PostStayAnswerRoute = (props: RouteComponentProps<{ id: string, publicId: string }>) => {

  const { id, publicId } = props?.match?.params

  const { data, loading } = useQuery(postStayGetByIdAndPublicId, {
    variables: {
      id,
      publicId,
    }
  })

  const postStay: PostStay = data?.postStay

  const hotel = postStay?.Hotel
  const reservation = postStay?.Reservation
  const hasReservation = !!reservation
  const contact = postStay?.Contact
  const contactDefaultPhone = (contact?.phoneNumberValid?.length > 0 && contact?.phoneNumberValid[0]) || ""


  return <div>
    <HotelContainer hotel={hotel} loading={loading}>
      {/* <ReservationPublicDetails reservation={reservation} /> */}
      <PostStayAnswer postStay={postStay} reservation={reservation} hotel={hotel} />
      {(hasReservation) && <ContactComponent
        hotelId={hotel?.id}
        phoneNumber={contactDefaultPhone}
        countryCode={reservation?.Contact?.countryCode}
        extraVars={{ reservationId: reservation?.id }}
      />}
    </HotelContainer>
  </div>

}