import React from "react"
import { PostStaySendStatusEnum } from "model"
import { PostStaySendStatus } from "./PostStaySendStatus"

export type PostStaySendStatusFilterProps = {
  value: string[],
  onChange: (datas: PostStaySendStatusEnum[]) => void
}


export const PostStaySendStatusFilter = (props: PostStaySendStatusFilterProps) => {

  const statusList = Object.keys(PostStaySendStatusEnum)

  const { value, onChange } = props;

  return <div className="card">
    <div className="card-body">
      <button className="btn btn-sm btn-warning" onClick={() => onChange([])}>Clear All</button>
      <button className="btn btn-sm btn-primary" onClick={() => onChange(statusList as PostStaySendStatusEnum[])} style={{ marginLeft: 5 }}>Select All</button>
      <ul className="list-group" >
        {statusList?.map((item) => {
          return <li className="list-group-item d-flex justify-content-start align-items-center" >
            <input type="checkbox" id={item} checked={!!value?.includes(item)} onChange={(e: any) => {
              const newValue = e.target.checked ? [...(value || []), item] : value?.filter((el: string) => el !== item)
              onChange(newValue as PostStaySendStatusEnum[])
            }} />
            <label htmlFor={item} style={{ padding: "0 10px", margin: 0 }}>
              <PostStaySendStatus status={item as PostStaySendStatusEnum} />
            </label>
          </li>

        })}
      </ul>
    </div>
  </div>
}