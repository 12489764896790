import React, {useState} from "react"
import {Event} from "model"
import {EventForm} from "../Form/EventForm"
import {useMutation, useQuery} from "@apollo/react-hooks"
import eventGetGql from "graphql/PLANNING/Event/query/eventGet.gql"
import eventDeleteGql from "graphql/PLANNING/Event/mutation/eventDelete.gql"
import eventEditGql from "graphql/PLANNING/Event/mutation/eventEdit.gql"
import {formatErrorResponseForJoi} from "helpers/Apollo"
// import confirmationListEventGql from "graphql/PMS/Confirmation/query/confirmationListEvent.gql"

type EventEditProps = {
  eventId: string,
  onDone?: () => void,
}

export const EventEdit = (props: EventEditProps) => {

  const [event, updateEvent] = useState<Event>()
  const [errors, updateErrors] = useState<any>()

  const [deleteMutation] = useMutation(eventDeleteGql, {
    variables: {
      id: props?.eventId
    },
  })

  const [editMutation] = useMutation(eventEditGql)

  useQuery(eventGetGql, {
    variables: {
      id: props?.eventId,
    },
    skip: !props?.eventId,
    onCompleted: (data) => {
      updateEvent(data?.event)
    }
  })


  const onSave = () => {
    const {id, eventType, label, sourceId, hotelId, Hotel, sourceType, Service, ...input} = event;

    editMutation({
      variables: {
        id: event?.id,
        input: {
          ...input,
          serviceId: Service?.id,
        },
      }
    })
      .then(() => {
        props?.onDone && props?.onDone()
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })

  }

  const onDelete = () => {
    deleteMutation()
      .then(() => {
        props?.onDone && props?.onDone()
      })
  }

  return <div>
    <h1>Modifier une réservation</h1>
    <EventForm event={event} errors={errors} serviceId={event?.serviceId} hotelId={event?.hotelId} onChange={event => {
      updateEvent(event)
    }} />
    <div className="mt-4">
      <button className="btn btn-sm btn-primary ml-1" onClick={() => onSave()}>Enregistrer</button>
      <button className="btn btn-sm btn-danger ml-1" onClick={() => onDelete()}>Supprimer</button>
    </div>
  </div>

}