import { BooleanInput } from "components/common/Form/BooleanInput"
import { PriceInput } from "components/common/Form/PriceInput"
import { TextInput } from "components/common/Form/TextInput"
import { Confirmation, ConfirmationDataInput, ConfirmationDataParkingEnum, ConfirmationDataTypeEnum } from "model"
import React from "react"
import { ConfirmationAnswerService } from "../../Answer/Service/ConfirmationAnswerService"
import { SelectInput } from "components/common/Form/SelectInput"

type ConfirmationDataFormProps = {
  confirmationDataTypeActive: (ConfirmationDataTypeEnum | string)[],
  confirmationData: ConfirmationDataInput,
  confirmationId: string,
  hotelId: string,
  onChange: (input: ConfirmationDataInput) => void,
  errors?: any,
}

/*

  TYPE_PARKING = 'TYPE_PARKING',
  TYPE_COMMENT = 'TYPE_COMMENT',
  TYPE_ARRHES = 'TYPE_ARRHES',
  TYPE_SERVICES = 'TYPE_SERVICES'

*/


export const ConfirmationDataForm = (props: ConfirmationDataFormProps) => {

  const confirmationDataTypeActive = props?.confirmationDataTypeActive
  // const confirmation = props?.confirmation
  const confirmationData = props?.confirmationData
  const errors = props?.errors
  const confirmationId = props?.confirmationId
  const hotelId = props?.hotelId

  const onChange = (input: ConfirmationDataInput) => {
    props?.onChange(input)
  }

  const hasParking = confirmationDataTypeActive?.includes(ConfirmationDataTypeEnum.TYPE_PARKING)
  const hasArrhes = confirmationDataTypeActive?.includes(ConfirmationDataTypeEnum.TYPE_ARRHES)
  const hasComment = confirmationDataTypeActive?.includes(ConfirmationDataTypeEnum.TYPE_COMMENT)
  const hasService = confirmationDataTypeActive?.includes(ConfirmationDataTypeEnum.TYPE_SERVICES)

  return <>
    {hasParking && <SelectInput choiceList={[
      { id: ConfirmationDataParkingEnum.HAS_PARKING, label: "Parking Inclus" },
      { id: ConfirmationDataParkingEnum.NO_PARKING, label: "Pas de Parking" },
      { id: ConfirmationDataParkingEnum.FREE_PARKING, label: "Parking Offert" },
    ]} label="Parking ?" errors={errors} value={confirmationData?.parking} onChange={(_, v) => {
      onChange({
        ...confirmationData,
        parking: v as ConfirmationDataParkingEnum,
      })
    }}
    />}
    {/* {hasParking && <BooleanInput label="Parking Inclus ?" errors={errors} value={confirmationData?.hasParking} onChange={(v) => {
      onChange({
        ...confirmationData,
        hasParking: v,
      })
    }}
    />} */}
    {hasArrhes && <PriceInput label="Montant des Arrhes" isError={errors?.arrhesAmountInCents} value={confirmationData?.arrhesAmountInCents} onChange={(v) => {
      onChange({
        ...confirmationData,
        arrhesAmountInCents: v,
      })
    }} />}
    {hasComment && <TextInput maxLength={350} errors={errors} label="Commentaire" value={confirmationData?.comment || ""} onChange={(_, value) => {
      onChange({
        ...confirmationData,
        comment: value,
      })
    }} />}
    {hasService && <div className="input-group">
      <span className="input-group-text">Services associés</span>
      <div className="form-control" style={{ height: "100%" }}>
        <ConfirmationAnswerService confirmationId={confirmationId} hotelId={hotelId} />
      </div>
    </div>}
  </>

}