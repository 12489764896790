import { SelectInput } from "@zipou/front_tools"
import { PriceInput } from "components/common/Form/PriceInput"
import { ChargeInput, ChargeTypeEnum } from "model"
import React from "react"

export type ChargeFormProps = {
  charge: ChargeInput,
  onChange: (chargeInput: ChargeInput) => void,
  errors: any,
}

export const ChargeForm = ({ charge, onChange, errors }: ChargeFormProps) => {

  return <div>
    <PriceInput label="Montant" value={charge?.amount} onChange={(v) => onChange({
      ...charge,
      amount: v,
    })} />

    {/* <SelectInput label="Type" errors={errors?.type} value={charge?.type} choiceList={[{ id: ChargeTypeEnum.CHARGE_STATUS_CHARGING, label: 'Charge' }]} onChange={(v) => onChange({
      ...charge,
      type: v as ChargeTypeEnum,
    })} /> */}
  </div>

}