import gql from "graphql-tag";
import RunnerFragmentGql from "../fragment/RunnerFragment.gql";


export default gql`

${RunnerFragmentGql}

  mutation runnerStart($id: String!) {
    runner: runnerStart(id: $id) {
      ...RunnerFragment
    }
  }

`