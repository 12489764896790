import gql from "graphql-tag";
import CameraFragmentGql from "../fragment/CameraFragment.gql";

export default gql`

  ${CameraFragmentGql}

  query cameraGet($id: String!) {
    camera: cameraGet(id: $id) {
      ...CameraFragment
    }
  }

`