import gql from "graphql-tag";

export default gql`
  fragment PhoneAgentConfigFragment on PhoneAgentConfig {
    id
    phoneConfigId
    token
    accountName
  }

`