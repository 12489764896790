import { IconComment } from "@zipou/front_tools"
import React, { useState } from "react"


type ChatControlProps = {
  target: any
  onTargetChange: (value: string) => void,
}


export const ChatControl = (props: ChatControlProps) => {

  const [isOpen, updateIsOpen] = useState(false)

  const target = props?.target
  const isAvailable = target?.status === "TARGET_AVAILABLE"

  const onChange = (value: any) => {
    updateIsOpen(false)
    props?.onTargetChange && props?.onTargetChange(value)
  }


  return <div className="chat-control" style={{ marginRight: 30 }}>
    <div className="chat-control">
      <div style={{ height: 40, width: 40, borderRadius: 15, display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => updateIsOpen(!isOpen)} >
        <span className="">
          <IconComment style={{ color: isAvailable ? "#a3d7a3" : "#afb2b5", fontSize: 22 }} />
        </span>
      </div>
    </div>
    {isOpen && <div style={{ zIndex: 2000, position: "absolute" }}>
      <div className="card">
        <div className="card-header bg-blue-h8 text-white ">
          Chat
        </div>
        <div className="card-body">
          <div className="input-group">
            <span className="input-group-text">Status</span>
            <select className="form-control" value={target?.status} onChange={(e: any) => onChange({
              ...target,
              status: e.target.value,
            })}>
              <option value="TARGET_AVAILABLE">Disponible</option>
              <option value="TARGET_UNAVAILABLE">Non disponible</option>
            </select>
          </div>
        </div>
      </div>
    </div>}
  </div>
}