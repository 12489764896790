import { useQuery } from "@apollo/react-hooks"
import { userContext } from "context/User"
import postStayGetGql from "graphql/PMS/Stay/Post/query/postStayGet.gql"

import { findToken } from "helpers/User"
import { getRootUrl } from "helpers/Utils"
import { PostStay, PostStaySendStatusEnum, UserRoleEnum } from "model"
import React, { useContext } from "react"

export type PostStayPreviewProps = {
  postStayId: string,
  // onSend?: () => void,
}


export const PostStayPreview = ({ postStayId }: PostStayPreviewProps) => {

  const { data } = useQuery<{ postStay: PostStay }>(postStayGetGql, {
    variables: {
      id: postStayId,
    },
    skip: !postStayId,
  })


  const postStay = data?.postStay

  const user = useContext(userContext)
  const hasDebugRole = user.roles?.includes(UserRoleEnum.ROLE_DEBUG)

  const isOnHold = postStay?.sendStatus === PostStaySendStatusEnum.POST_STAY_SEND_STATUS_HOLD
  return <div className="card">
    {hasDebugRole && <div className="card-header">Identifiant: {postStay?.id}</div>}
    {isOnHold && <div className="alert alert-warning">{postStay?.reason}</div>}
    {postStay && <div>
      <div className="card-body">
        <iframe style={{ height: '80vh' }} width='100%' title='poststay' src={`${getRootUrl()}stay/post/${postStay?.id}?access_token=${findToken()}`}></iframe>
      </div>
      <div className="card-footer">
        {/* {isConfirmationSendable(confirmation) && <button className="btn btn-success" onClick={() => props?.onSend()}>Envoyer</button>}
        {isConfirmationReSendable(confirmation) && <button className="btn btn-warning" onClick={() => props?.onSend()}>Renvoyer à nouveau</button>} */}
      </div>
    </div>}
  </div>

}