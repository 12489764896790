import React, { useState } from "react"

import { useQuery } from "@apollo/client"
import { Modal } from "components/common/Navigation/Modal/Modal"
import propositionGetGql from "graphql/PMS/Proposition/query/propositionGet.gql"
import { CustomerDetails } from "components/front/CRM/Customer/Details/CustomerDetails"


type PropositionCTIDetailsProps = {
  propositionId: string,
  isRinging: boolean,
}

export const PropositionCTIDetails = (props: PropositionCTIDetailsProps) => {

  const [showDetails, updateShowDetails] = useState<boolean>(false)

  const { data } = useQuery(propositionGetGql, {
    variables: {
      id: props?.propositionId,
    },
    skip: !props?.propositionId
  })

  const proposition = data?.proposition

  return <div className="">
    <Modal display={showDetails} onClose={() => updateShowDetails(false)}>
      {/* <PropositionEdit id={proposition?.id} /> */}
      <CustomerDetails customerId={proposition?.Contact?.Customer?.id} showReservation={true} showProposition={true} showContacts={false} />
      {/* <CustomerDetails customerId={"03d48cac-b41e-4f91-a63c-48f1a8742ee5"} /> */}
    </Modal>
    {props?.isRinging && <div className="card border-danger">
      <div className="card-header bg-danger text-white"><span className="icon-phone"></span> Appel en cours...</div>
      <div className="card-body" style={{ display: "flex", flexDirection: "column" }}>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th>Id Proposition</th>
              <td>#{proposition?.publicId}</td>
            </tr>
            <tr>
              <th>Contact</th>
              <td>{proposition?.Contact?.firstName} {proposition?.Contact?.lastName?.toUpperCase()}</td>
            </tr>
            {proposition?.Contact?.Customer?.id && <tr>
              <td colSpan={2}><button className="btn btn-warning" onClick={() => updateShowDetails(true)}>Plus de détails</button></td>
            </tr>}
          </tbody>
        </table>
      </div>
    </div>}
  </div>
}