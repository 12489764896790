import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks"
import { IconPause, IconPlay, IconStop } from "@zipou/front_tools"
import { userContext } from "context/User"
import runnerStartGql from "graphql/VIDEO/Runner/mutation/runnerStart.gql"
import runnerStopGql from "graphql/VIDEO/Runner/mutation/runnerStop.gql"
import runnerGetGql from "graphql/VIDEO/Runner/query/runnerGet.gql"
import runnerUpdateGql from "graphql/VIDEO/Runner/subscription/runnerUpdate.gql"
import { Runner, RunnerStatusEnum } from "model"
import React, { useContext } from "react"

type RunnerActionsProps = {
  runnerId: string,
}

export const RunnerActions = ({ runnerId }: RunnerActionsProps) => {

  const runnerStartMutation = useMutation(runnerStartGql)
  const runnerStopMutation = useMutation(runnerStopGql)


  const onRunnerStart = (id: string) => {
    runnerStartMutation[0]({
      variables: {
        id
      }
    })
      .then(() => console.log('OK'))
      .catch((e) => console.log('EEEEE', e.message))
  }

  const onRunnerStop = (id: string) => {
    runnerStopMutation[0]({
      variables: {
        id
      }
    })
      .then(() => console.log('OK'))
      .catch((e) => console.log('EEEEE', e.message))
  }

  const { data } = useQuery<{ runner: Runner }>(runnerGetGql, {
    variables: {
      id: runnerId,
    }
  })

  const user = useContext(userContext)
  const isAdmin = user?.isAdmin

  const runner = data?.runner
  const isRunning = runner?.status === RunnerStatusEnum.STATUS_RUNNING
  const isError = runner?.status === RunnerStatusEnum.STATUS_ERROR


  useSubscription(runnerUpdateGql, {
    variables: {
      id: runner?.id,
    },
    skip: !runner?.id
  })



  return <>
    {(isRunning && isAdmin) && <button className="btn btn-secondary btn-sm ml-1" onClick={() => onRunnerStop(runner?.id)}>
      <IconPause />
    </button>}
    {(!isRunning && isAdmin && !isError) && <button className="btn btn-success btn-sm ml-1" onClick={() => onRunnerStart(runner?.id)}>
      <IconPlay />
    </button>}
    {(isAdmin && isError) && <button className="btn btn-danger btn-sm ml-1" onClick={() => onRunnerStop(runner?.id)}>
      <IconStop />
    </button>}
  </>
}