import gql from "graphql-tag";
import CameraEditFragmentGql from "graphql/VIDEO/Camera/fragment/CameraEditFragment.gql";

export default gql` 

${CameraEditFragmentGql}

fragment VideoConfigFragment on VideoConfig {
  id
  storageDir
  CameraList { 
    ...CameraEditFragment
  }
}

`