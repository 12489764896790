import gql from "graphql-tag";

export default gql`

fragment PostStayQuestionEditFragment on PostStayQuestion {
  id
  type
  name
  choiceList
  conditionKey
  conditionValue
  labelFr
  descFr
  labelEn
  descEn
}
`