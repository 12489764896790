import {useQuery} from "@apollo/react-hooks"
import {Modal} from "components/common/Navigation/Modal/Modal"
import priceRateListByCategoryGql from "graphql/PMS/PriceRate/query/priceRateListByCategory.gql"
import {PriceRate} from "model"
import React, {useState} from "react"

export type PriceRateDisplayProps = {
  roomTypeId: string,
  nbSpecial: number,
  nbChildren: number,
  nbAdults: number,
  date: any,
  hotelId: string,
}

export const PriceRateDisplay = (props: PriceRateDisplayProps) => {

  const {roomTypeId, nbAdults, nbChildren, nbSpecial, date, hotelId} = props
  const [displayPriceRate, updateDisplayPriceRate] = useState(false)


  const {data, loading} = useQuery(priceRateListByCategoryGql, {
    variables: {
      roomTypeId,
      nbAdults,
      nbChildren,
      nbSpecial,
      date,
      hotelId,
    }
  })


  const list: PriceRate[] = data?.list
  return <span>
    <Modal display={displayPriceRate} onClose={() => updateDisplayPriceRate(false)}>
      <ul className="list-group">
        {list?.map((priceRate) => {
          return <li className="list-group-item" key={`priceRate_select_${priceRate?.id}`}>
            {priceRate?.code} - {priceRate?.valueIncTax} €
          </li>
        })}
      </ul>
    </Modal>
    <button disabled={loading} className="btn btn-sm btn-secondary" onClick={() => updateDisplayPriceRate(true)}>
      {loading && <span className="icon-loading"></span>}
      <span><span className="icon-info"></span> Tarifs</span>
    </button>
  </span>

}