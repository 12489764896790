import { useSubscription } from "@apollo/react-hooks"
import { StripeContainer } from "components/front/BILLING/Stripe/Container/StripeContainer"
import paymentUpdateGql from "graphql/BILLING/Payment/subscription/paymentUpdate.gql"
import { Payment } from "model"
import React from "react"
import { PaymentConfirmPaytweak } from "./Paytweak/PaymentConfirmPaytweak"
import { PaymentConfirmStripe } from "./Stripe/PaymentConfirmStripe"

export type PaymentConfirmProps = {
  payment: Payment,
  newWindow?: boolean,
  onCancel?: () => void,
}

export const PaymentConfirm = (props: PaymentConfirmProps) => {

  const payment = props?.payment

  useSubscription(paymentUpdateGql, {
    variables: {
      id: payment?.id,
    },
    skip: !payment?.id,
    // onSubscriptionData: (data) => {
    //   console.log("data", data?.subscriptionData)
    // }
  })

  switch (payment?.provider) {
    case "STRIPE": {

      return <StripeContainer stripePk={payment?.Hotel?.BillingConfig?.StripeConfig?.publishableKey}>
        <PaymentConfirmStripe payment={payment} />
      </StripeContainer>


    }

    case "PAYTWEAK": {
      return <PaymentConfirmPaytweak payment={payment} newWindow={props?.newWindow} />
    }

    default: {
      return <h1>DEFAULT</h1>
    }
  }


}