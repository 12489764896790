import { Modal } from "components/common/Navigation/Modal/Modal"
import { FileContentDisplay } from "components/front/APP/File/Display/FileContentDisplay"
import { Hotel } from "model"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

type CgvDisplayProps = {
  hotel: Hotel,
}

export const CgvDisplay = (props: CgvDisplayProps) => {

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'hotel' });
  const isFrench = i18n.language === "fr"
  const [displayModal, updateDisplayModal] = useState(false)

  const hotel = props?.hotel
  const fileId = isFrench ? hotel?.cgvUrlFrFileId : hotel?.cgvUrlEnFileId

  return <>
    <Modal display={displayModal} onClose={() => updateDisplayModal(false)}>
      <FileContentDisplay fileId={fileId} />
    </Modal>
    {fileId && <span style={{ fontWeight: "bold" }}><a href={"#"} onClick={() => updateDisplayModal(true)} rel="noreferrer">{t("cgv")}</a></span>}
  </>
}