import { PhoneCallButtonModule } from "@zipou/phone_front";
import { userContext } from "context/User";
import React from "react";
import { useContext } from "react";


type PhoneCallButtonProps = {
  phoneNumber: string,
  countryCode?: string,
}

export const PhoneCallButton = ({ phoneNumber, countryCode }: PhoneCallButtonProps) => {

  const user = useContext(userContext)
  const phoneAgentConfig = user?.PhoneAgentConfig
  const peerName = phoneAgentConfig?.accountName?.replace("PJSIP/", "")?.replace("SIP/", "")
  const hasPeerName = !!peerName

  return <>
    {hasPeerName && <PhoneCallButtonModule
      moduleConfig={{
        url: "https://www.box4b.fr",
        wsUrl: "wss://www.box4b.fr/subscriptions",
      }}
      peerName={peerName}
      phoneNumber={phoneNumber}
      countryCode={countryCode}
      phoneConfigId={phoneAgentConfig?.phoneConfigId}
      token={phoneAgentConfig?.token}
    />}
    {!hasPeerName && <span>{phoneNumber}</span>}
  </>
}