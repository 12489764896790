import React from "react"
import { Event, ServiceTypeEnum } from "model"
import { DateTimeFormat } from "components/common/Format/DateTimeFormat"
import { EventStatus } from "components/front/PLANNING/Event/Status/EventStatus"
import { useTranslation } from "react-i18next"

type ConfirmationEventDisplayProps = {
  serviceEventList: Event[],
}

export const ConfirmationEventDisplay = (props: ConfirmationEventDisplayProps) => {

  const serviceEventList = props?.serviceEventList

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'confirmation' });

  return <>
    <ul className="list-group">
      {serviceEventList?.map((e: Event) => {

        const service = e.Service
        const serviceType = service?.type

        switch(serviceType) {
          case ServiceTypeEnum.TYPE_RESTAURANT: {
            return <li className="list-group-item">{e?.Service?.name} - <DateTimeFormat value={e?.source?.utcDate} showUTC /> - {t("nbPeople", {count: Number(e?.source?.nbPeople)})} - <EventStatus status={e.status} /> </li>
          }

          default: {
            return <li className="list-group-item">{e?.Service?.name} - <DateTimeFormat value={e?.source?.utcDate} showUTC /> - <EventStatus status={e.status} /></li>
          }
        }

      })}
    </ul>
  </>
}