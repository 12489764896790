import { useMutation, useQuery } from "@apollo/react-hooks"
import { userContext } from "context/User"
import paymentSendGql from "graphql/BILLING/Payment/mutation/paymentSend.gql"
import paymentGetById from "graphql/BILLING/Payment/query/paymentGetById"
import tokenSetDeletedGql from "graphql/BILLING/Token/mutation/tokenSetDeleted.gql"
import { findToken } from "helpers/User"
import { getRootUrl } from "helpers/Utils"
import { PaymentSendStatusEnum, Token, TokenSendStatusEnum, UserRoleEnum } from "model"
import React, { useContext } from "react"

type TokenDeleteProps = {
  token: Token,
  onDone: () => void,
}


export const TokenDelete = ({ token, onDone }: TokenDeleteProps) => {

  const [mutate] = useMutation(tokenSetDeletedGql)

  const onDelete = () => {
    mutate({
      variables: {
        id: token?.id
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch(e => {
        console.log("e", e)
      })
  }


  const user = useContext(userContext)
  const hasDebugRole = user.roles.includes(UserRoleEnum.ROLE_DEBUG)

  return <div className="card">
    <div className="card-body">
      <h1>Supprimer l'empreinte bancaire ?</h1>
      <div className="alert alert-info">
        Plus aucun paiment ne sera possible.<br />
        Les paiements déjà effectués seront conservés
      </div>
    </div>
    {token && <div>
      <div className="card-footer">
        <button className="btn btn-sm btn-danger ml-1" onClick={() => onDelete()}>Supprimer</button>
        <button className="btn btn-sm btn-dark ml-1" onClick={() => onDone()}>Annuler</button>
      </div>
    </div>}
  </div>


}